import { Message } from '@alifd/next';


import post from './../services/request'

export default function http(url, data) {

  return post(url, data)
    .then((response) => {
      if(response){
        if (response === undefined) {
          Message.error('服务器错误，请稍后再试');
        } else if (response.executeResult === false) {
          Message.error(response.resultMessage);
          return response;
        } else {
          return response;
        }
      }else{
        return response;
      }
      
    });
}