const languageType = [
        {
            code: 'TABLE-HYSY',
            zh: '欢迎使用！',
            en: 'Welcome to use!',
            French: 'jjj',
        },
        {
           
            code: 'TABLE-MC',
            zh: '名称',
            en: 'Name',
            French: 'jjj',
        
        },
        {
           
            code: 'JC',
            zh: '简称',
            en: 'Abbreviation',
            French: 'jjj',
        },
        {
            en: 'Confirm Password',
            code: 'TABLE-QRMM',
            French: 'jjj',
            zh: '确认密码'
        },
        {
            en: 'Name Of Organization',
            code: 'TABLE-JGMC',
            French: 'jjj',
            zh: '机构名称'
        },
        {
            en: 'Organization Code',
            code: 'TABLE-JGBM',
            French: 'jjj',
            zh: '机构编码'
        },
        {
            en: 'Normal',
            code: 'TABLE-ZC',
            French: 'jjj',
            zh: '正常'
        },
        {
            en: 'Out Of Service',
            code: 'TABLE-TY',
            French: 'jjj',
            zh: '停用'
        },
        {
            en: 'Enable',
            code: 'TABLE-QY',
            French: 'jjj',
            zh: '启用'
        },
        {
            en: 'See',
            code: 'TABLE-CK',
            French: 'jjj',
            zh: '查看'
        },
        {
            en: 'Title',
            code: 'TABLE-BT',
            French: 'jjj',
            zh: '标题'
        },
        {
            en: 'Request Address',
            code: 'TABLE-QQDZ',
            French: 'jjj',
            zh: '请求地址'
        },
        {
            en: 'Request Status',
            code: 'TABLE-QQZT',
            French: 'jjj',
            zh: '请求状态'
        },
        {
            en: 'Operation Account',
            code: 'TABLE-CZZH',
            French: 'jjj',
            zh: '操作账号'
        },
        {
            en: 'Business Type',
            code: 'TABLE-YWLX',
            French: 'jjj',
            zh: '业务类型'
        },
        {
            en: 'Menu Application Type',
            code: 'TABLE-CDYYLX',
            French: 'jjj',
            zh: '菜单应用类型'
        },
        {
            en: 'Serial Number',
            code: 'XH',
            French: 'jjj',
            zh: '序号'
        },
        {
            en: 'Operation',
            code: 'TABLE-CZ',
            French: 'jjj',
            zh: '操作'
        },
        {
            en: 'State',
            code: 'TABLE-ZT',
            French: 'jjj',
            zh: '状态'
        },
        {
            en: 'Sort',
            code: 'TABLE-PX',
            French: 'jjj',
            zh: '排序'
        },
        {
            en: 'Wubi Code',
            code: 'WBM',
            French: 'jjj',
            zh: '五笔码'
        },
        {
            en: 'Pinyin Code',
            code: 'PYM',
            French: 'jjj',
            zh: '拼音码'
        },
        {
            en: 'Laboratory Name',
            code: 'TABLE-SYSMC',
            French: 'jjj',
            zh: '实验室名称'
        },
        {
            en: 'Selected Inspection Department',
            code: 'TABLE-YXYYKS',
            French: 'jjj',
            zh: '已选检验科室'
        },
        {
            en: 'No Inspection Department Selected',
            code: 'TABLE-WXYYKS',
            French: 'jjj',
            zh: '未选检验科室'
        },
        {
            en: 'Name Of Inspection Department',
            code: 'TABLE-JYKSMC',
            French: 'jjj',
            zh: '检验科室名称'
        },
        {
            en: 'Laboratory Department',
            code: 'TABLE-JYKS',
            French: 'jjj',
            zh: '检验科室'
        },
        {
            en: 'Combination Name',
            code: 'TABLE-ZHMC',
            French: 'jjj',
            zh: '组合名称'
        },
        {
            en: 'Newly Added',
            code: 'TABLE-XZ',
            French: 'jjj',
            zh: '新增'
        },
        {
            en: 'Modify',
            code: 'TABLE-XG',
            French: 'jjj',
            zh: '修改'
        },
        {
            en: 'Import',
            code: 'TABLE-DR',
            French: 'jjj',
            zh: '导入'
        },
        {
            en: 'Please Select',
            code: 'Table-QXZ',
            French: 'jjj',
            zh: '请选择'
        },
        {
            en: 'Project Code / Name',
            code: 'TABLE-Project',
            French: 'jjj',
            zh: '项目编码/名称'
        },
        {
            en: 'Primary Classification',
            code: 'TABLE-YJFL',
            French: 'jjj',
            zh: '一级分类'
        },
        {
            en: 'Secondary Classification',
            code: 'TABLE-EJFL',
            French: 'jjj',
            zh: '二级分类'
        },
        {
            en: 'Item Code',
            code: 'XMBM',
            French: 'jjj',
            zh: '项目编码'
        },
        {
            en: 'Entry Name',
            code: 'XMMC',
            French: 'jjj',
            zh: '项目名称'
        },
        {
            en: 'Be Careful',
            code: 'Table-ZY',
            French: 'jjj',
            zh: '注意'
        },
        {
            en: 'Project',
            code: 'Table-XM',
            French: 'jjj',
            zh: '项目'
        },
        {
            en: 'Combination Coding',
            code: 'ZHBM',
            French: 'jjj',
            zh: '组合编码'
        },
        {
            en: 'en Name',
            code: 'YWMC',
            French: 'jjj',
            zh: '英文名称'
        },
        {
            en: 'Cannot Be Empty',
            code: 'TABLE-BNWK',
            French: 'jjj',
            zh: '不能为空'
        },
        {
            en: 'Automatically Generated By The System',
            code: 'XTZDSC',
            French: 'jjj',
            zh: '系统自动生成'
        },
        {
            en: 'Process Coding',
            code: 'LCBM',
            French: 'jjj',
            zh: '流程编码'
        },
        {
            en: 'Process Type',
            code: 'LCLX',
            French: 'jjj',
            zh: '流程类型'
        },
        {
            en: 'Process Name',
            code: 'LCMC',
            French: 'jjj',
            zh: '流程名称'
        },
        {
            en: 'Remarks',
            code: 'BZ',
            French: 'jjj',
            zh: '备注'
        },
        {
            en: 'Screening Criteria',
            code: 'SXTJ',
            French: 'jjj',
            zh: '筛选条件'
        },
        {
            en: 'Start Time',
            code: 'KSSJ',
            French: 'jjj',
            zh: '开始时间'
        },
        {
            en: 'Start Time Cannot Be Empty',
            code: 'Table-KSSJBNWK',
            French: 'jjj',
            zh: '开始时间不能为空'
        },
        {
            en: 'End Time',
            code: 'JSSJ',
            French: 'jjj',
            zh: '结束时间'
        },
        {
            en: 'End Time Cannot Be Empty',
            code: 'Table-JSSJBNWK',
            French: 'jjj',
            zh: '结束时间不能为空'
        },
        {
            en: 'Data Cubreport',
            code: 'SJZBB',
            French: 'jjj',
            zh: '数据子报表'
        },
        {
            en: 'Multi Column Sub Report',
            code: 'DLZBB',
            French: 'jjj',
            zh: '多栏子报表'
        },
        {
            en: 'Picture Subreport',
            code: 'TPZBB',
            French: 'jjj',
            zh: '图片子报表'
        },
        {
            en: 'Number Of Columns',
            code: 'FLTS',
            French: 'jjj',
            zh: '分栏条数'
        },
        {
            en: 'Paper',
            code: 'ZZ',
            French: 'jjj',
            zh: '纸张'
        },
        {
            en: 'Paper Rotation',
            code: 'ZZFX',
            French: 'jjj',
            zh: '纸张方向'
        },
        {
            en: 'Paper Width',
            code: 'ZZK',
            French: 'jjj',
            zh: '纸张宽'
        },
        {
            en: 'Paper Height',
            code: 'ZZG',
            French: 'jjj',
            zh: '纸张高'
        },
        {
            en: 'Enable Status',
            code: 'Table-QYZT',
            French: 'jjj',
            zh: '启用状态'
        },
        {
            en: 'Show Picture Area',
            code: 'XXTPQ',
            French: 'jjj',
            zh: '显示图片区'
        },
        {
            en: 'Instrument Classification',
            code: 'Table-YQFL',
            French: 'jjj',
            zh: '仪器分类'
        },
        {
            en: 'Instrument Code',
            code: 'Table-YQBM',
            French: 'jjj',
            zh: '仪器编码'
        },
        {
            en: 'Instrument Name',
            code: 'Table-YQMC',
            French: 'jjj',
            zh: '仪器名称'
        },
        {
            en: 'Abbreviation Of Instrument',
            code: 'Table-YQJC',
            French: 'jjj',
            zh: '仪器简称'
        },
        {
            en: 'Instrument Model',
            code: 'Table-YQXH',
            French: 'jjj',
            zh: '仪器型号'
        },
        {
            en: 'Company',
            code: 'Table-DW',
            French: 'jjj',
            zh: '单位'
        },
        {
            en: 'Project Type',
            code: 'Table-XMLX',
            French: 'jjj',
            zh: '项目类型'
        },
        {
            en: 'Result Formula',
            code: 'Table-JGJSS',
            French: 'jjj',
            zh: '结果计算式'
        },
        {
            en: 'Result Type',
            code: 'Table-JGLX',
            French: 'jjj',
            zh: '结果类型'
        },
        {
            en: 'Accuracy',
            code: 'Table-JD',
            French: 'jjj',
            zh: '精度'
        },
        {
            en: 'Default Results',
            code: 'Table-MRJG',
            French: 'jjj',
            zh: '默认结果'
        },
        {
            en: 'First Level Project Classification',
            code: 'Table-XMYJFL',
            French: 'jjj',
            zh: '项目一级分类'
        },
        {
            en: 'Project Secondary Classification',
            code: 'Table-XMEJFL',
            French: 'jjj',
            zh: '项目二级分类'
        },
        {
            en: 'System Automatic Production',
            code: 'Table-XTZDSC',
            French: 'jjj',
            zh: '系统自动生产'
        },
        {
            en: 'Laboratory Methods',
            code: 'Table-SYSFF',
            French: 'jjj',
            zh: '实验室方法'
        },
        {
            en: 'Instrument Items',
            code: 'Table-YQXM',
            French: 'jjj',
            zh: '仪器项目'
        },
        {
            en: 'Usage Status',
            code: 'Table-SYZT',
            French: 'jjj',
            zh: '使用状态'
        },
        {
            en: 'Specimen Type',
            code: 'Table-BBLX',
            French: 'jjj',
            zh: '标本类型'
        },
        {
            en: 'Gender',
            code: 'Table-XB',
            French: 'jjj',
            zh: '性别'
        },
        {
            en: 'Age Unit',
            code: 'Table-NLDW',
            French: 'jjj',
            zh: '年龄单位'
        },
        {
            en: 'Critical Value Expression',
            code: 'Table-WJZBDS',
            French: 'jjj',
            zh: '危急值表达式'
        },
        {
            en: 'Critical Value Level',
            code: 'Table-WJZDJ',
            French: 'jjj',
            zh: '危急值等级'
        },
        {
            en: 'Result Coding',
            code: 'Table-JGBM',
            French: 'jjj',
            zh: '结果编码'
        },
        {
            en: 'Result Value',
            code: 'Table-JGZ',
            French: 'jjj',
            zh: '结果值'
        },
        {
            en: 'The Result Value Cannot Be Empty',
            code: 'Table-JGZBNWK',
            French: 'jjj',
            zh: '结果值不能为空'
        },
        {
            en: 'Replace Conditional Expression',
            code: 'Table-THTJBDS',
            French: 'jjj',
            zh: '替换条件表达式'
        },
        {
            en: 'Replacement Value',
            code: 'Table-THZ',
            French: 'jjj',
            zh: '替换值'
        },
        {
            en: 'Result Tag',
            code: 'Table-JGBJ',
            French: 'jjj',
            zh: '结果标记'
        },
        {
            en: 'Sign',
            code: 'TABLE-BJ',
            French: 'jjj',
            zh: '标记'
        },
        {
            en: 'Rule Type',
            code: 'Table-GZLX',
            French: 'jjj',
            zh: '规则类型'
        },
        {
            en: 'Rule Name',
            code: 'Table-GZMC',
            French: 'jjj',
            zh: '规则名称'
        },
        {
            en: 'Minimum Control Value',
            code: 'Table-ZXKZZ',
            French: 'jjj',
            zh: '最小控制值'
        },
        {
            en: 'Maximum Control Value',
            code: 'Table-ZDKZZ',
            French: 'jjj',
            zh: '最大控制值'
        },
        {
            en: 'Operation Steps',
            code: 'Table-CZBZ',
            French: 'jjj',
            zh: '操作步骤'
        },
        {
            en: 'Operation Type',
            code: 'Table-CZLX',
            French: 'jjj',
            zh: '操作类型'
        },
        {
            en: 'Instrument',
            code: 'Table-YQ',
            French: 'jjj',
            zh: '仪器'
        },
        {
            en: 'Lower Age Limit',
            code: 'Table-NLXX',
            French: 'jjj',
            zh: '年龄下限'
        },
        {
            en: 'Upper Age Limit',
            code: 'Table-NLSX',
            French: 'jjj',
            zh: '年龄上限'
        },
        {
            en: 'Lower Limit Of Reference Value',
            code: 'Table-CKZXX',
            French: 'jjj',
            zh: '参考值下限'
        },
        {
            en: 'Upper Limit Of Reference Value',
            code: 'Table-CKZSX',
            French: 'jjj',
            zh: '参考值上限'
        },
        {
            en: 'Reference Value',
            code: 'Table-CKZ',
            French: 'jjj',
            zh: '参考值'
        },
        {
            en: 'Inspection Items',
            code: 'Table-JYXM',
            French: 'jjj',
            zh: '检验项目'
        },
        {
            en: 'Contents',
            code: 'Table-TSNR',
            French: 'jjj',
            zh: '提示内容'
        },
        {
            en: 'Project Code / Name / Abbreviation / en Name',
            code: 'Table-XMBM/MC/JC/YW',
            French: 'jjj',
            zh: '项目编码/名称/简称/英文名'
        },
        {
            en: 'Application Purpose Code',
            code: 'Table-SQMDBM',
            French: 'jjj',
            zh: '申请目的编码'
        },
        {
            en: 'Name Of Application Purpose',
            code: 'Table-SQMDMC',
            French: 'jjj',
            zh: '申请目的名称'
        },
        {
            en: 'Application Purpose Abbreviation',
            code: 'Table-SQMDJC',
            French: 'jjj',
            zh: '申请目的简称'
        },
        {
            en: 'Standard Quantity',
            code: 'Table-BZL',
            French: 'jjj',
            zh: '标准量'
        },
        {
            en: 'Rule Classification Code',
            code: 'Table-GZFLDM',
            French: 'jjj',
            zh: '规则分类代码'
        },
        {
            en: 'Rule Classification Code Cannot Be Empty',
            code: 'Table-GZFLDMBNWK',
            French: 'jjj',
            zh: '规则分类代码不能为空'
        },
        {
            en: 'Rule Classification Description',
            code: 'Table-GZFLMS',
            French: 'jjj',
            zh: '规则分类描述'
        },
        {
            en: 'Rule Classification Description Cannot Be Empty',
            code: 'Table-GZFLMSBNWK',
            French: 'jjj',
            zh: '规则分类描述不能为空'
        },
        {
            en: 'Rule Classification',
            code: 'Table-GZFL',
            French: 'jjj',
            zh: '规则分类'
        },
        {
            en: 'Rule Code',
            code: 'Table-GZDM',
            French: 'jjj',
            zh: '规则代码'
        },
        {
            en: 'Rule Description',
            code: 'Table-GZMS',
            French: 'jjj',
            zh: '规则描述'
        },
        {
            en: 'N Value',
            code: 'Table-NZHI',
            French: 'jjj',
            zh: 'N值'
        },
        {
            en: 'M Value',
            code: 'Table-MZHI',
            French: 'jjj',
            zh: 'M值'
        },
        {
            en: 'X Value',
            code: 'Table-XZHI',
            French: 'jjj',
            zh: 'X值'
        },
        {
            en: 'Y Value',
            code: 'Table-YZHI',
            French: 'jjj',
            zh: 'Y值'
        },
        {
            en: 'Error Type',
            code: 'Table-WCLX',
            French: 'jjj',
            zh: '误差类型'
        },
        {
            en: 'Application Time',
            code: 'Table-SQSJ',
            French: 'jjj',
            zh: '申请时间'
        },
        {
            en: 'Automatic Printing',
            code: 'Table-ZDDY',
            French: 'jjj',
            zh: '自动打印'
        },
        {
            en: 'Exception List',
            code: 'Table-YCLB',
            French: 'jjj',
            zh: '异常列表'
        },
        {
            en: 'Application List',
            code: 'Table-SSLB',
            French: 'jjj',
            zh: '申请列表'
        },
        {
            en: 'Printed',
            code: 'Table-YDY',
            French: 'jjj',
            zh: '已打印'
        },
        {
            en: 'Full Name',
            code: 'Table-XM',
            French: 'jjj',
            zh: '姓名'
        },
        {
            en: 'Age',
            code: 'Table-NL',
            French: 'jjj',
            zh: '年龄'
        },
        {
            en: 'Medical Card Number',
            code: 'Table-JZKH',
            French: 'jjj',
            zh: '就诊卡号'
        },
        {
            en: 'Outpatient / Physical Examination Number',
            code: 'Table-MZ/TYH',
            French: 'jjj',
            zh: '门诊/体检号'
        },
        {
            en: 'ID Number',
            code: 'Table-SFZH',
            French: 'jjj',
            zh: '身份证号'
        },
        {
            en: 'Emergency Treatment',
            code: 'Table-JZT',
            French: 'jjj',
            zh: '急诊'
        },
        {
            en: 'Bar Code',
            code: 'Table-TM',
            French: 'jjj',
            zh: '条码'
        },
        {
            en: 'Executive Department',
            code: 'Table-ZXKS',
            French: 'jjj',
            zh: '执行科室'
        },
        {
            en: 'Container Type',
            code: 'Table-RQLX',
            French: 'jjj',
            zh: '容器类型'
        },
        {
            en: 'Specimen Status',
            code: 'Table-BBZT',
            French: 'jjj',
            zh: '标本状态'
        },
        {
            en: 'Outpatient Number',
            code: 'Table-MZH',
            French: 'jjj',
            zh: '门诊号'
        },
        {
            en: 'Admission Number',
            code: 'Table-ZYH',
            French: 'jjj',
            zh: '住院号'
        },
        {
            en: 'Judging The State',
            code: 'Table-PDZT',
            French: 'jjj',
            zh: '判断状态'
        },
        {
            en: 'Classification Rule Cannot Be Empty',
            code: 'Table-GZFLBNWK',
            French: 'jjj',
            zh: '规则分类不能为空'
        },
        {
            en: 'Rule code Cannot Be Empty',
            code: 'Table-GZDMBNWK',
            French: 'jjj',
            zh: '规则代码不能为空'
        },
        {
            en: 'Rule Description Cannot Be Empty',
            code: 'Table-GZMSBNWK',
            French: 'jjj',
            zh: '规则描述不能为空'
        },
        {
            en: 'Error Type Cannot Be Empty',
            code: 'Table-WCLXBNWK',
            French: 'jjj',
            zh: '误差类型不能为空'
        },
        {
            en: 'Judgment Status Cannot Be Empty',
            code: 'Table-PDZTBNWK',
            French: 'jjj',
            zh: '判断状态不能为空'
        },
        {
            en: 'Rule Combination Code',
            code: 'Table-GZZHDM',
            French: 'jjj',
            zh: '规则组合代码'
        },
        {
            en: 'Rule Combination',
            code: 'Table-GZZH',
            French: 'jjj',
            zh: '规则组合'
        },
        {
            en: 'Rule Combination Cannot Be Empty',
            code: 'Table-GZZHBNWK',
            French: 'jjj',
            zh: '规则组合不能为空'
        },
        {
            en: 'Rule Combination code Cannot Be Empty',
            code: 'Table-GZZHDMBNWK',
            French: 'jjj',
            zh: '规则组合代码不能为空'
        },
        {
            en: 'Rule Combination Name',
            code: 'Table-GZZHMC',
            French: 'jjj',
            zh: '规则组合名称'
        },
        {
            en: 'Rule Combination Name Cannot Be Empty',
            code: 'Table-GZZHMCBNWK',
            French: 'jjj',
            zh: '规则组合名称不能为空'
        },
        {
            en: 'Combination code / Name Of Quality Control Rules',
            code: 'Table-ZKGZZHDM/MC',
            French: 'jjj',
            zh: '质控规则组合代码/名称'
        },
        {
            en: 'Printer',
            code: 'Table-DYR',
            French: 'jjj',
            zh: '打印人'
        },
        {
            en: 'Delivery Batch Number',
            code: 'Table-SCPCH',
            French: 'jjj',
            zh: '送出批次号'
        },
        {
            en: 'Delivery Time',
            code: 'Table-SCSJ',
            French: 'jjj',
            zh: '送出时间'
        },
        {
            en: 'Sender',
            code: 'Table-SCR',
            French: 'jjj',
            zh: '送出人'
        },
        {
            en: 'Receiving Time',
            code: 'Table-JSSJ',
            French: 'jjj',
            zh: '接收时间'
        },
        {
            en: 'Receiver',
            code: 'Table-JSR',
            French: 'jjj',
            zh: '接收人'
        },
        {
            en: 'Charge Amount',
            code: 'Table-SFJE',
            French: 'jjj',
            zh: '收费金额'
        },
        {
            en: 'Date Of Birth',
            code: 'Table-CSRQ',
            French: 'jjj',
            zh: '出生日期'
        },
        {
            en: 'API Project Code',
            code: 'Table-APIXMBM',
            French: 'jjj',
            zh: 'API项目编码'
        },
        {
            en: 'API Project Name',
            code: 'Table-APIXMMC',
            French: 'jjj',
            zh: 'API项目名称'
        },
        {
            en: 'Apply For A Doctor',
            code: 'Table-SQYS',
            French: 'jjj',
            zh: '申请医生'
        },
        {
            en: 'Verification Tips',
            code: 'Table-JYTS',
            French: 'jjj',
            zh: '校验提示'
        },
        {
            en: 'Sampling Confirmation Batch Number',
            code: 'Table-CYQRPCH',
            French: 'jjj',
            zh: '采样确认批次号'
        },
        {
            en: 'Sampling Time',
            code: 'Table-CYSJ',
            French: 'jjj',
            zh: '采样时间'
        },
        {
            en: 'Sampler',
            code: 'Table-CYR',
            French: 'jjj',
            zh: '采样人'
        },
        {
            en: 'Printing Time',
            code: 'Table-DYSJ',
            French: 'jjj',
            zh: '打印时间'
        },
        {
            en: 'Barcode Printing Batch Number',
            code: 'Table-TMDYPCH',
            French: 'jjj',
            zh: '条码打印批次号'
        },
        {
            en: 'Clinical Diagnosis',
            code: 'Table-LCCD',
            French: 'jjj',
            zh: '临床诊断'
        },
        {
            en: 'Estimated Reporting Time',
            code: 'Table-YJBGSJ',
            French: 'jjj',
            zh: '预计报告时间'
        },
        {
            en: 'Estimated Testing Time',
            code: 'Table-YJJCSJ',
            French: 'jjj',
            zh: '预计检测时间'
        },
        {
            en: 'Sampling Site',
            code: 'Table-CYBW',
            French: 'jjj',
            zh: '采样部位'
        },
        {
            en: 'Application Project',
            code: 'Table-SQXM',
            French: 'jjj',
            zh: '申请项目'
        },
        {
            en: 'Application Date',
            code: 'Table-SQRQ',
            French: 'jjj',
            zh: '申请日期'
        },
        {
            en: 'Sampling Date',
            code: 'Table-CYRQ',
            French: 'jjj',
            zh: '采样日期'
        },
        {
            en: 'Date Of Collection',
            code: 'Table-HSRQ',
            French: 'jjj',
            zh: '核收日期'
        },
        {
            en: 'Inspection Date',
            code: 'Table-JYRQ',
            French: 'jjj',
            zh: '检验日期'
        },
        {
            en: 'Date Of First Trial',
            code: 'Table-ChuShenRQ',
            French: 'jjj',
            zh: '初审日期'
        },
        {
            en: 'Audit Date',
            code: 'Table-SHRQ',
            French: 'jjj',
            zh: '审核日期'
        },
        {
            en: 'Bed Number',
            code: 'Table-ZH',
            French: 'jjj',
            zh: '床号'
        },
        {
            en: 'Admission Number',
            code: 'Table-ZYH',
            French: 'jjj',
            zh: '住院号'
        },
        {
            en: 'Department',
            code: 'Table-KS',
            French: 'jjj',
            zh: '科室'
        },
        {
            en: 'Sample Size',
            code: 'Table-BML',
            French: 'jjj',
            zh: '标本量'
        },
        {
            en: 'Executive Laboratory',
            code: 'Table-ZXKS',
            French: 'jjj',
            zh: '执行实验室'
        },
        {
            en: 'Application Item Code',
            code: 'Table-SQXMBM',
            French: 'jjj',
            zh: '申请项目编码'
        },
        {
            en: 'Telephone',
            code: 'Table-DH',
            French: 'jjj',
            zh: '电话'
        },
        {
            en: 'Number Of Containers',
            code: 'Table-RQSL',
            French: 'jjj',
            zh: '容器数量'
        },
        {
            en: 'Choice',
            code: 'Table-XZ1',
            French: 'jjj',
            zh: '选择'
        },
        {
            en: 'Specimen List',
            code: 'TITLE-BBLB',
            French: 'jjj',
            zh: '标本列表'
        },
        {
            en: 'The Specimen Was Sent Out',
            code: 'TITLE-BBSC',
            French: 'jjj',
            zh: '标本送出'
        },
        {
            en: 'Display Only Current User Data',
            code: 'TITLE-ZXSDQYHSJ',
            French: 'jjj',
            zh: '只显示当前用户数据'
        },
        {
            en: 'Barcode Scanner ',
            code: 'TITLE-TMSM',
            French: 'jjj',
            zh: '条码扫描'
        },
        {
            en: 'Specimens To Be Sent',
            code: 'TITLE-DSCBB',
            French: 'jjj',
            zh: '待送出标本'
        },
        {
            en: 'Printer Settings',
            code: 'Table-DYJSZ',
            French: 'jjj',
            zh: '打印机设置'
        },
        {
            en: 'Select Printer',
            code: 'Table-XDYJ',
            French: 'jjj',
            zh: '选打印机'
        },
        {
            en: 'Printer',
            code: 'Table-DYJ',
            French: 'jjj',
            zh: '打印机'
        },
        {
            en: 'Print Mode',
            code: 'Table-DYMS',
            French: 'jjj',
            zh: '打印模式'
        },
        {
            en: 'Direct Printing',
            code: 'Table-ZJDY',
            French: 'jjj',
            zh: '直接打印'
        },
        {
            en: 'Print Preview',
            code: 'Table-DYYL',
            French: 'jjj',
            zh: '打印预览'
        },
        {
            en: 'Please Select The Specimen To Be Sent',
            code: 'Table-QZZXYSCDBB',
            French: 'jjj',
            zh: '请选择需要送出的标本'
        },
        {
            en: 'Please Check The Batch Confirmed Data First',
            code: 'Table-QGXPLQRDSJ',
            French: 'jjj',
            zh: '请先勾选批量确认的数据'
        },
        {
            en: 'Please Fill In The Print Settings First',
            code: 'Table-QXTXDYSZ',
            French: 'jjj',
            zh: '请先填写打印设置'
        },
        {
            en: 'Please Check The Specimen To Be Operated First',
            code: 'Table-QXGXXYCZDBB',
            French: 'jjj',
            zh: '请先勾选需要操作的标本'
        },
        {
            en: 'Clodop Is Not Installed And Started. Please Install And Start It First',
            code: 'Table-CLODOPWAZ',
            French: 'jjj',
            zh: 'CLodop未安装启动，请先安装并启动'
        },
        {
            en: 'Please Check At Least Two Specimens To Be Combined',
            code: 'Table-QXGXZSLTBBBB',
            French: 'jjj',
            zh: '请先勾选至少两条需要合并的标本'
        },
        {
            en: 'At Present, No Specimens Have Been Sent Out For Printing',
            code: 'Table-MQMYSCBBKYDY',
            French: 'jjj',
            zh: '目前没有送出标本可以打印'
        },
        {
            en: 'Only The Specimens That Have Been Collected Or Handed Over Can Be Refunded',
            code: 'Table-ZYYHSHZYJJDBBCNGTF',
            French: 'jjj',
            zh: '只有已核收或者已交接的标本才能够退费'
        },
        {
            en: 'His Item Code',
            code: 'Table-HISXMBM',
            French: 'jjj',
            zh: 'HIS项目编码'
        },
        {
            en: 'His Project Name',
            code: 'Table-HISXMMC',
            French: 'jjj',
            zh: 'HIS项目名称'
        },
        {
            en: 'Please Check The Data In The Application Form First',
            code: 'Table-QGXSQBZDSJ',
            French: 'jjj',
            zh: '请先勾选申请表中的数据'
        },
        {
            en: 'Sent To The Specified Printer',
            code: 'Table-YFSZDDYJ',
            French: 'jjj',
            zh: '已发送至指定打印机'
        },
        {
            en: 'Please Check The Report To Be Printed',
            code: 'Table-QGXXYDYDBG',
            French: 'jjj',
            zh: '请勾选需要打印的报告'
        },
        {
            en: 'Verification Platform',
            code: 'Table-JYPT',
            French: 'jjj',
            zh: '校验平台'
        },
        {
            en: 'Program Name',
            code: 'Table-JHMC',
            French: 'jjj',
            zh: '计划名称'
        },
        {
            en: 'Plan Name Cannot Be Empty',
            code: 'Table-JHMCBNWK',
            French: 'jjj',
            zh: '计划名称不能为空'
        },
        {
            en: 'Quantity Of Quality Control Products',
            code: 'Table-ZKPSL',
            French: 'jjj',
            zh: '质控品数量'
        },
        {
            en: 'Quality Control Product Quantity Cannot Be Empty',
            code: 'Table-ZKPSLBNWK',
            French: 'jjj',
            zh: '质控品数量不能为空'
        },
        {
            en: 'Quality Control Products',
            code: 'Table-ZKP',
            French: 'jjj',
            zh: '质控品'
        },
        {
            en: 'Quality Control Material Cannot Be Empty',
            code: 'Table-ZKPBNWK',
            French: 'jjj',
            zh: '质控品不能为空'
        },
        {
            en: 'Instrument Code',
            code: 'Table-YQDM',
            French: 'jjj',
            zh: '仪器代码'
        },
        {
            en: 'Inspection Item Code',
            code: 'Table-JYXMDM',
            French: 'jjj',
            zh: '检验项目代码'
        },
        {
            en: 'Name Of Inspection Item',
            code: 'Table-JYXMMC',
            French: 'jjj',
            zh: '检验项目名称'
        },
        {
            en: 'Type',
            code: 'Table-LX',
            French: 'jjj',
            zh: '类型'
        },
        {
            en: 'Calibrator',
            code: 'Table-JZP',
            French: 'jjj',
            zh: '校准品'
        },
        {
            en: 'Reagent',
            code: 'Table-SJ',
            French: 'jjj',
            zh: '试剂'
        },
        {
            en: 'Batch Number Of Quality Control Products',
            code: 'Table-ZKPPH',
            French: 'jjj',
            zh: '质控品批号'
        },
        {
            en: 'Quality Control Batch Number Cannot Be Empty',
            code: 'Table-ZKPPHBNWK',
            French: 'jjj',
            zh: '质控品批号不能为空'
        },
        {
            en: 'Name Of Quality Control Product',
            code: 'Table-ZKPMC',
            French: 'jjj',
            zh: '质控品名称'
        },
        {
            en: 'Quality Control Product Name Cannot Be Empty',
            code: 'Table-ZKPMCBNWK',
            French: 'jjj',
            zh: '质控品名称不能为空'
        },
        {
            en: 'Horizontal Type',
            code: 'Table-SPLX',
            French: 'jjj',
            zh: '水平类型'
        },
        {
            en: 'Horizontal Type Cannot Be Empty',
            code: 'Table-SPLXBNWK',
            French: 'jjj',
            zh: '水平类型不能为空'
        },
        {
            en: 'Quality Control Point Shape',
            code: 'Table-ZKDXZ',
            French: 'jjj',
            zh: '质控点形状'
        },
        {
            en: 'Quality Control Point Shape Cannot Be Empty',
            code: 'Table-ZKDXZBNWK',
            French: 'jjj',
            zh: '质控点形状不能为空'
        },
        {
            en: 'Colour',
            code: 'Table-YS',
            French: 'jjj',
            zh: '颜色'
        },
        {
            en: 'Color Cannot Be Empty',
            code: 'Table-YSBNWK',
            French: 'jjj',
            zh: '颜色不能为空'
        },
        {
            en: 'Out Of Control Color',
            code: 'Table-SKDYS',
            French: 'jjj',
            zh: '失控点颜色'
        },
        {
            en: 'Out Of Control Point Color Cannot Be Empty',
            code: 'Table-SKDYSBNWK',
            French: 'jjj',
            zh: '失控点颜色不能为空'
        },
        {
            en: 'Term Of Validity',
            code: 'Table-YXQ',
            French: 'jjj',
            zh: '有效期'
        },
        {
            en: 'Validity Period Cannot Be Empty',
            code: 'Table-YXQBNWK',
            French: 'jjj',
            zh: '有效期不能为空'
        },
        {
            en: 'Manufacturer',
            code: 'Table-SCCJ',
            French: 'jjj',
            zh: '生产厂家'
        },
        {
            en: 'Mean Value',
            code: 'Table-JZ',
            French: 'jjj',
            zh: '均值'
        },
        {
            en: 'Mean Value Cannot Be Empty',
            code: 'Table-JZBNWK',
            French: 'jjj',
            zh: '均值不能为空'
        },
        {
            en: 'Standard Deviation',
            code: 'Table-BZC',
            French: 'jjj',
            zh: '标准差'
        },
        {
            en: 'Standard Deviation Cannot Be Empty',
            code: 'Table-BZCBNWK',
            French: 'jjj',
            zh: '标准差不能为空'
        },
        {
            en: 'CV Value%',
            code: 'Table-CVV',
            French: 'jjj',
            zh: 'CV值%'
        },
        {
            en: 'Sample Number',
            code: 'Table-YPH',
            French: 'jjj',
            zh: '样品号'
        },
        {
            en: 'Sample Number Cannot Be Empty',
            code: 'Table-YPHBNWK',
            French: 'jjj',
            zh: '样品号不能为空'
        },
        {
            en: 'Order',
            code: 'Table-CX',
            French: 'jjj',
            zh: '次序'
        },
        {
            en: 'Order Cannot Be Empty',
            code: 'Table-CXBNWK',
            French: 'jjj',
            zh: '次序不能为空'
        },
        {
            en: 'Lab Cannot Be Empty',
            code: 'Table-SYSBNWK',
            French: 'jjj',
            zh: '实验室不能为空'
        },
        {
            en: 'Inspection Platform',
            code: 'Table-JYPT',
            French: 'jjj',
            zh: '检验平台'
        },
        {
            en: 'Inspection Platform Cannot Be Empty',
            code: 'Table-JYPTBNWK',
            French: 'jjj',
            zh: '检验平台不能为空'
        },
        {
            en: 'Please Select',
            code: 'Table-QXZ',
            French: 'jjj',
            zh: '请选择'
        },
        {
            en: 'Please Select A Date And Time',
            code: 'Table-QXZRQHSJ',
            French: 'jjj',
            zh: '请选择日期和时间'
        },
        {
            en: 'Type Name',
            code: 'Table-LXMC',
            French: 'jjj',
            zh: '类型名称'
        },
        {
            en: 'Please Select The Date',
            code: 'Table-QXZRQ',
            French: 'jjj',
            zh: '请选择日期'
        },
        {
            en: 'Please Select The Time',
            code: 'Table-QXZSJ',
            French: 'jjj',
            zh: '请选择时间'
        },
        {
            en: 'Are You Sure You Want To Stop?',
            code: 'Table-NQDTYM',
            French: 'jjj',
            zh: '你确定停用吗？'
        },
        {
            en: 'Are You Sure You Want To Enable It?',
            code: 'Table-NQDQYM',
            French: 'jjj',
            zh: '你确定启用吗？'
        },
        {
            en: 'The Start Time Cannot Be Greater Than The End Time',
            code: 'Table-KSSJBNDYJSSJ',
            French: 'jjj',
            zh: '开始时间不能大于结束时间'
        },
        {
            en: 'Please Select The Data On The Left First',
            code: 'Table-QXXZZCSJ',
            French: 'jjj',
            zh: '请先选择左侧数据'
        },
        {
            en: 'Please Select The Item On The Right First',
            code: 'Table-QXXZYCDXM',
            French: 'jjj',
            zh: '请先选择右侧的项目'
        },
        {
            en: 'View / Modify Quality Control Plan',
            code: 'Table-ZKJHCK/XG',
            French: 'jjj',
            zh: '质控计划查看/修改'
        },
        {
            en: 'Mean And Standard Deviation',
            code: 'Table-JZYBZC',
            French: 'jjj',
            zh: '均值与标准差'
        },
        {
            en: 'Quality Control Products And Samples',
            code: 'Table-ZKPYYP',
            French: 'jjj',
            zh: '质控品与样品'
        },
        {
            en: 'No Data To Import',
            code: 'Table-ZWSJKDR',
            French: 'jjj',
            zh: '暂无数据可导入'
        },
        {
            en: 'Import Group Details',
            code: 'Table-DRFZMX',
            French: 'jjj',
            zh: '导入分组明细'
        },
        {
            en: 'The Sample Status Before Handover Can Be Refunded',
            code: 'Table-JJZQDZTCKJXTF',
            French: 'jjj',
            zh: '交接之前的样本状态才可进行退费'
        },
        {
            en: 'Are You Sure You Want To Refund The Check Sample?',
            code: 'Table-QDYGXBBJXTFM',
            French: 'jjj',
            zh: '确定要对勾选标本进行退费吗？'
        },
        {
            en: 'Invalid Barcode',
            code: 'Table-ZFTM',
            French: 'jjj',
            zh: '作废条码'
        },
        {
            en: 'Operation Return',
            code: 'Table-CZTH',
            French: 'jjj',
            zh: '操作退回'
        },
        {
            en: 'Please Fill In The Reason',
            code: 'Table-QTXYY',
            French: 'jjj',
            zh: '请填写原因...'
        },
        {
            en: 'Refund Items',
            code: 'Table-TFXM',
            French: 'jjj',
            zh: '退费项目'
        },
        {
            en: 'Inspection Refund',
            code: 'Table-JYTF',
            French: 'jjj',
            zh: '检验退费'
        },
        {
            en: 'Sampling Confirmation',
            code: 'Table-CYQZ',
            French: 'jjj',
            zh: '采样确认'
        },
        {
            en: 'Specimen To Be Checked',
            code: 'TITLE-DHDBB',
            French: 'jjj',
            zh: '待核对标本'
        },
        {
            en: 'Abnormal Prompt',
            code: 'TITLE-YCTS',
            French: 'jjj',
            zh: '异常提示'
        },
        {
            en: 'Patient Selection',
            code: 'TITLE-HZXZ',
            French: 'jjj',
            zh: '患者选择'
        },
        {
            en: 'Exception Data In Exception List',
            code: 'TITLE-YCLBZCXYCSJ',
            French: 'jjj',
            zh: '异常列表中存在异常数据'
        },
        {
            en: 'Report Form Template',
            code: 'Table-BGDMB',
            French: 'jjj',
            zh: '报告单模板'
        },
        {
            en: 'No Data Can Be Printed At This Time',
            code: 'Table-MQMYKYDYSJ',
            French: 'jjj',
            zh: '目前没有可以打印数据'
        },
        {
            en: 'This Bar Code Does Not Meet The Check Conditions, Please Check',
            code: 'Table-CTMBFHHSTJ',
            French: 'jjj',
            zh: '此条码不符合核收条件,请检查'
        },
        {
            en: 'Print Selected Data',
            code: 'TITLE-DYXZDSJ',
            French: 'jjj',
            zh: '打印选中的数据'
        },
        {
            en: 'Experimental Methods',
            code: 'Table-SYFF',
            French: 'jjj',
            zh: '实验方法'
        },
        {
            en: 'Inspection Item View',
            code: 'Table-JYXMCK',
            French: 'jjj',
            zh: '检验项目查看'
        },
        {
            en: 'Test Item Instrument',
            code: 'Table-JYXMYQ',
            French: 'jjj',
            zh: '检验项目仪器'
        },
        {
            en: 'Critical Value',
            code: 'Table-WJZ',
            French: 'jjj',
            zh: '危急值'
        },
        {
            en: 'Dangerous',
            code: 'Table-W',
            French: 'jjj',
            zh: '危'
        },
        {
            en: 'Emergency',
            code:'Table-JJ',
            French: 'jjj',
            zh: '急'
        },
        {
            en: 'Project Drop-down Result Value',
            code: 'Table-XMXLJGZ',
            French: 'jjj',
            zh: '项目下拉结果值'
        },
        {
            en: 'Item Result Substitution',
            code: 'Table-XMJGTH',
            French: 'jjj',
            zh: '项目结果替换'
        },
        {
            en: 'Result Range Control',
            code: 'Table-JGFWKZ',
            French: 'jjj',
            zh: '结果范围控制'
        },
        {
            en: 'Name Of Application Project',
            code: 'Table-SQXMMC',
            French: 'jjj',
            zh: '申请项目名称'
        },
        {
            en: 'Abbreviation Of Application Project',
            code: 'Table-SQXMJC',
            French: 'jjj',
            zh: '申请项目简称'
        },
        {
            en: 'Inspection Process',
            code: 'Table-JYLC',
            French: 'jjj',
            zh: '检验流程'
        },
        {
            en: 'Container Type',
            code: 'Table-RQLX',
            French: 'jjj',
            zh: '容器类型'
        },
        {
            en: 'Sending Organization Code',
            code: 'Table-WSJGBM',
            French: 'jjj',
            zh: '外送机构编码'
        },
        {
            en: 'Name Of Delivery Organization',
            code: 'Table-WSJGMC',
            French: 'jjj',
            zh: '外送机构名称'
        },
        {
            en: 'Laboratory Code',
            code: 'Table-SYSBM',
            French: 'jjj',
            zh: '实验室编码'
        },
        {
            en: 'Laboratory Name',
            code: 'Table-SYSMC',
            French: 'jjj',
            zh: '实验室名称'
        },
        {
            en: 'Default Specimen Type',
            code: 'Table-MRBBLX',
            French: 'jjj',
            zh: '默认标本类型'
        },
        {
            en: 'Sample Size',
            code: 'Table-BBL',
            French: 'jjj',
            zh: '标本量'
        },
        {
            en: 'Project Charge',
            code: 'Table-XMSF',
            French: 'jjj',
            zh: '项目收费'
        },
        {
            en: 'Sampling Location',
            code: 'Table-CYDD',
            French: 'jjj',
            zh: '采样地点'
        },
        {
            en: 'Sampling Conditions',
            code: 'Table-CYTJ',
            French: 'jjj',
            zh: '采样条件'
        },
        {
            en: 'Delivery Mechanism',
            code: 'Table-WSJG',
            French: 'jjj',
            zh: '外送机构'
        },
        {
            en: 'Assigned Lab',
            code: 'Table-YFPSYS',
            French: 'jjj',
            zh: '已分配实验室'
        },
        {
            en: 'No Lab Assigned',
            code: 'Table-WFPSYS',
            French: 'jjj',
            zh: '未分配实验室'
        },
        {
            en: 'Application Project View',
            code: 'Table-SQXMCK',
            French: 'jjj',
            zh: '申请项目查看'
        },
        {
            en: 'Application Project Details',
            code: 'Table-SQXMMX',
            French: 'jjj',
            zh: '申请项目明细'
        },
        {
            en: 'Single Check',
            code: 'TITLE-DGHS',
            French: 'jjj',
            zh: '单个核收'
        },
        {
            en: 'Check And Accept By Lot',
            code: 'TITLE-APHS',
            French: 'jjj',
            zh: '按批核收'
        },
        {
            en: 'Approved List',
            code: 'TITLE-YHSLB',
            French: 'jjj',
            zh: '已核收列表'
        },
        {
            en: 'Scan Code List',
            code: 'TITLE-SMLB',
            French: 'jjj',
            zh: '扫码列表'
        },
        {
            en: 'Scan Code',
            code: 'TITLE-SM',
            French: 'jjj',
            zh: '扫码'
        },
        {
            en: 'Scan Code Reception',
            code: 'TITLE-SYJS',
            French: 'jjj',
            zh: '扫码接收'
        },
        {
            en: 'Laboratory',
            code: 'TITLE-SYS',
            French: 'jjj',
            zh: '实验室'
        },
        {
            en: 'Import Project',
            code: 'Table-DRXM',
            French: 'jjj',
            zh: '导入项目'
        },
        {
            en: 'Are You Sure You Want To Delete?',
            code: 'Table-QDYSCM',
            French: 'jjj',
            zh: '确认要删除么？'
        },
        {
            en: 'Delete',
            code: 'Table-SC',
            French: 'jjj',
            zh: '删除'
        },
        {
            en: "Are You Sure To Delete It? It Can't Be Recovered After Deletion!",
            code: 'Table-QDSCBKHF',
            French: 'jjj',
            zh: '你确定删除吗，删除后不可恢复！'
        },
        {
            en: 'Are You Sure You Want To Stop?',
            code: 'Table-QDTY',
            French: 'jjj',
            zh: '你确定停用吗？'
        },
        {
            en: 'Are You Sure You Want To Enable It?',
            code: 'Table-QDQY',
            French: 'jjj',
            zh: '你确定启用吗？'
        },
        {
            en: 'Please Select The Data To Be Operated!',
            code: 'Table-XZXYCZDSJ',
            French: 'jjj',
            zh: '请选择需要操作的数据！'
        },
        {
            en: 'Please Select The Left List Data First',
            code: 'Table-XZZCLBSJ',
            French: 'jjj',
            zh: '请先选择左侧列表数据'
        },
        {
            en: 'There Is No Data To Save',
            code: 'Table-MYXYBCDSJ',
            French: 'jjj',
            zh: '没有需要保存的数据'
        },
        {
            en: 'Please Select The Item To Be Deleted First!',
            code: 'Table-XZXYSCDXM',
            French: 'jjj',
            zh: '请先选择需要删除的项目！'
        },
        {
            en: 'Please Check The Data To Be Imported',
            code: 'Table-GXXYDRDSJ',
            French: 'jjj',
            zh: '请勾选需要导入的数据'
        },
        {
            en: 'The Start Time Cannot Be Greater Than The End Time',
            code: 'Table-KSSJBNDYJSSJ',
            French: 'jjj',
            zh: '开始时间不能大于结束时间'
        },
        {
            en: 'Please Check The Data First',
            code: 'Table-QXGXSJ',
            French: 'jjj',
            zh: '请先勾选数据'
        },
        {
            en: 'Block Coding',
            code: 'Table-FZBM',
            French: 'jjj',
            zh: '分组编码'
        },
        {
            en: 'Group Name',
            code: 'Table-FZMC',
            French: 'jjj',
            zh: '分组名称'
        },
        {
            en: 'Sending Department',
            code: 'Table-SJKS',
            French: 'jjj',
            zh: '送检科室'
        },
        {
            en: 'Inspection Report Time',
            code: 'Table-JYBGSJ',
            French: 'jjj',
            zh: '检验报告时间'
        },
        {
            en: 'Reporting Location',
            code: 'Table-BGDD',
            French: 'jjj',
            zh: '报告地点'
        },
        {
            en: 'Detection Time Description',
            code: 'Table-JCSJMS',
            French: 'jjj',
            zh: '检测时间描述'
        },
        {
            en: 'Test Category',
            code: 'Table-JCLB',
            French: 'jjj',
            zh: '检测类别'
        },
        {
            en: 'Inspection Time',
            code: 'Table-JCSJ',
            French: 'jjj',
            zh: '检查时间'
        },
        {
            en: 'Group Number',
            code: 'Table-FZBH',
            French: 'jjj',
            zh: '分组编号'
        },
        {
            en: 'Deadline For Detection',
            code: 'Table-JZJCSJD',
            French: 'jjj',
            zh: '截止检测时间点'
        },
        {
            en: 'Days Between Reports',
            code: 'Table-BGJGTS',
            French: 'jjj',
            zh: '报告间隔天数'
        },
        {
            en: 'Reporting Time',
            code: 'Table-BGSJ',
            French: 'jjj',
            zh: '报告时间'
        },
        {
            en: 'Reporting Time Unit',
            code: 'Table-BGSJDW',
            French: 'jjj',
            zh: '报告时间单位'
        },
        {
            en: 'Detection Time',
            code: 'Table-JCSJ',
            French: 'jjj',
            zh: '检测时间'
        },
        {
            en: 'Test Rules',
            code: 'Table-CSGZ',
            French: 'jjj',
            zh: '测试规则'
        },
        {
            en: 'Inspection Rules',
            code: 'Table-JYGZ',
            French: 'jjj',
            zh: '检验规则'
        },
        {
            en: 'Collection Time',
            code: 'Table-CJSJ',
            French: 'jjj',
            zh: '采集时间'
        },
        {
            en: 'Estimated Inspection Time',
            code: 'Table-YJJYSJ',
            French: 'jjj',
            zh: '预计检验时间'
        },
        {
            en: 'Inspection Time',
            code: 'Table-YJSJ',
            French: 'jjj',
            zh: '检验时间'
        },
        {
            en: 'Estimated Reporting Time',
            code: 'Table-YJBGSJ',
            French: 'jjj',
            zh: '预计报告时间'
        },
        {
            en: 'External Coding',
            code: 'Table-WBBM',
            French: 'jjj',
            zh: '外部编码'
        },
        {
            en: 'Department Code',
            code: 'Table-KSBM',
            French: 'jjj',
            zh: '科室编码'
        },
        {
            en: 'Department Name',
            code: 'Table-KSMC',
            French: 'jjj',
            zh: '科室名称'
        },
        {
            en: 'Specimen Handover',
            code: 'Table-BBJJ',
            French: 'jjj',
            zh: '标本交接'
        },
        {
            en: 'Laboratory Department',
            code: 'Table-JYKS',
            French: 'jjj',
            zh: '检验科室'
        },
        {
            en: 'Platform Coding',
            code: 'Table-PTBM',
            French: 'jjj',
            zh: '平台编码'
        },
        {
            en: 'Platform Name',
            code: 'Table-PTMC',
            French: 'jjj',
            zh: '平台名称'
        },
        {
            en: 'Platform Classification',
            code: 'Table-PTFL',
            French: 'jjj',
            zh: '平台分类'
        },
        {
            en: 'Title Of Report',
            code: 'Table-BGDTT',
            French: 'jjj',
            zh: '报告单抬头'
        },
        {
            en: 'Double Trial Or Not',
            code: 'Table-SFSS',
            French: 'jjj',
            zh: '是否双审'
        },
        {
            en: 'Examiner',
            code: 'Table-JYZ',
            French: 'jjj',
            zh: '检验者'
        },
        {
            en: 'First Judge',
            code: 'Table-CSZ',
            French: 'jjj',
            zh: '初审者'
        },
        {
            en: 'Reviewer',
            code: 'Table-SHZ',
            French: 'jjj',
            zh: '审核者'
        },
        {
            en: 'Is The Inspector / Preliminary Examiner Allowed To Be The Same',
            code: 'Table-SFYXJYZHCSZ',
            French: 'jjj',
            zh: '是否允许检验者/初审者相同'
        },
        {
            en: 'Are Inspectors / Auditors Allowed To Be The Same',
            code: 'Table-SFYXJYZHSHZ',
            French: 'jjj',
            zh: '是否允许检验者/审核者相同'
        },
        {
            en: 'Are The First Reviewers / Auditors Allowed To Be The Same',
            code: 'Table-SFYXCSZHSHZ',
            French: 'jjj',
            zh: '是否允许初审者/审核相同'
        },
        {
            en: 'Import Instrument',
            code: 'Table-DRYQ',
            French: 'jjj',
            zh: '导入仪器'
        },
        {
            en: 'Bacterial Coding',
            code: 'Table-XJBM',
            French: 'jjj',
            zh: '细菌编码'
        },
        {
            en: 'Name Of Bacteria',
            code: 'Table-XJMC',
            French: 'jjj',
            zh: '细菌名称'
        },
        {
            en: 'Bacterial Type (Gram)',
            code: 'Table-XJLXGL',
            French: 'jjj',
            zh: '细菌类型(革兰)'
        },
        {
            en: 'Fungi',
            code: 'Table-JS',
            French: 'jjj',
            zh: '菌属'
        },
        {
            en: 'Classification Of Bacteria',
            code: 'Table-XJFL',
            French: 'jjj',
            zh: '细菌分类'
        },
        {
            en: 'WHONET Code',
            code: 'Table-WHONETBM',
            French: 'jjj',
            zh: 'WHONET编码'
        },
        {
            en: 'Instrument Information',
            code: 'Table-YQXX',
            French: 'jjj',
            zh: '仪器信息'
        },
        {
            en: 'Instrument Channel',
            code: 'Table-YQTD',
            French: 'jjj',
            zh: '仪器通道'
        },
        {
            en: 'Bacterial Channel',
            code: 'Table-XJTD',
            French: 'jjj',
            zh: '细菌通道'
        },
        {
            en: 'Code',
            code: 'Table-BM',
            French: 'jjj',
            zh: '编码'
        },
        {
            en: 'Transverse',
            code: 'Table-HX',
            French: 'jjj',
            zh: '横向'
        },
        {
            en: 'Portrait',
            code: 'Table-ZX',
            French: 'jjj',
            zh: '纵向'
        },
        {
            en: 'Test Tube Type',
            code: 'TITLE-SGLX',
            French: 'jjj',
            zh: '试管类型'
        },
        {
            en: 'There Is No Data To Confirm, Please Check',
            code: 'TITLE-MYXYQRDSJ',
            French: 'jjj',
            zh: '没有需要确认的数据，请检查'
        },
        {
            en: 'Please Scan The Barcode List Again',
            code: 'TITLE-QSXLLZJXSMTACZ',
            French: 'jjj',
            zh: '请刷新列表再进行扫描条码操作'
        },
        {
            en: 'Please Check The Batch Check Data First',
            code: 'TITLE-QXGXPLHDDSJ',
            French: 'jjj',
            zh: '请先勾选批量核对的数据'
        },
        {
            en: 'Please Select The Data To Be Sampled For Confirmation',
            code: 'TITLE-QXZXYCYQRDSJ',
            French: 'jjj',
            zh: '请选择需要采样确认的数据'
        },
        {
            en: 'Please Check A Piece Of Data That Needs To Be Refunded',
            code: 'TITLE-QXGXXYTFDYTSJ',
            French: 'jjj',
            zh: '请先勾选需要退费的一条数据'
        },
        {
            en: 'Operation Time',
            code: 'TITLE-CZSJ',
            French: 'jjj',
            zh: '操作时间'
        },
        {
            en: 'List Printing',
            code: 'TITLE-QDDY',
            French: 'jjj',
            zh: '清单打印'
        },
        {
            en: 'Print Condition Cannot Be Empty',
            code: 'TITLE-DYDTBKYWK',
            French: 'jjj',
            zh: '打印条件不可以为空'
        },
        {
            en: 'Print Selected Data',
            code: 'TITLE-DYXZDSJ',
            French: 'jjj',
            zh: '打印选中的数据'
        },
        {
            en: 'Print All Unselected Data',
            code: 'TITLE-DYWXZDSJ',
            French: 'jjj',
            zh: '打印所有未选中的数据'
        },
        {
            en: 'Print All The Data',
            code: 'TITLE-DYSYSJ',
            French: 'jjj',
            zh: '打印所有的数据'
        },
        {
            en: 'Diagnosis',
            code: 'Table-ZD',
            French: 'jjj',
            zh: '诊断'
        },
        {
            en: 'Start Date',
            code: 'Table-KSRQ',
            French: 'jjj',
            zh: '开始日期'
        },
        {
            en: 'Name Of Antibiotics',
            code: 'Table-KSSMC',
            French: 'jjj',
            zh: '抗生素名称'
        },
        {
            en: 'Associated Bacteria',
            code: 'Table-GLJS',
            French: 'jjj',
            zh: '关联菌属'
        },
        {
            en: 'Associated Antibiotics',
            code: 'Table-GLKSS',
            French: 'jjj',
            zh: '关联抗生素'
        },
        {
            en: 'Antibiotic Group View',
            code: 'Table-KSSZCK',
            French: 'jjj',
            zh: '抗生素组查看'
        },
        {
            en: 'Name Of Antibiotic Group',
            code: 'Table-KSSZMC',
            French: 'jjj',
            zh: '抗生素组名称'
        },
        {
            en: 'Default Method',
            code: 'Table-MRFF',
            French: 'jjj',
            zh: '默认方法'
        },
        {
            en: 'Default Qualitative Results',
            code: 'Table-MRDXJG',
            French: 'jjj',
            zh: '默认定性结果'
        },
        {
            en: 'Default quantitative Results',
            code: 'Table-MRDLJG',
            French: 'jjj',
            zh: '默认定量结果'
        },
        {
            en: 'Antibiotic Coding',
            code: 'Table-KSSBM',
            French: 'jjj',
            zh: '抗生素编码'
        },
        {
            en: 'Antibiotic Group Coding',
            code: 'Table-KSSZBM',
            French: 'jjj',
            zh: '抗生素组编码'
        },
        {
            en: 'Antibiotic Abbreviation',
            code: 'Table-SSSJC',
            French: 'jjj',
            zh: '抗生素简称'
        },
        {
            en: 'Break Point Setting',
            code: 'Table-ZDSZ',
            French: 'jjj',
            zh: '折点设置'
        },
        {
            en: 'Default Lab Method',
            code: 'Table-MRSYSFF',
            French: 'jjj',
            zh: '默认实验室方法'
        },
        {
            en: 'Bacterial Code',
            code: 'Table-JSBM',
            French: 'jjj',
            zh: '菌属编码'
        },
        {
            en: 'Name Of Genus',
            code: 'Table-JSMC',
            French: 'jjj',
            zh: '菌属名称'
        },
        {
            en: 'User Name',
            code: 'Table-YHMC',
            French: 'jjj',
            zh: '用户名称'
        },
        {
            en: 'Customer Type',
            code: 'Table-YHLX',
            French: 'jjj',
            zh: '用户类型'
        },
        {
            en: 'Check, Receive, Save permissions',
            code: 'Table-JYJSBCQX',
            French: 'jjj',
            zh: '检验接收，保存权限'
        },
        {
            en: 'Inspection Return authority',
            code: 'Table-JYTHQX',
            French: 'jjj',
            zh: '检验退回权限'
        },
        {
            en: 'Jurisdiction Of Preliminary Trial',
            code: 'Table-CSQX',
            French: 'jjj',
            zh: '初审权限'
        },
        {
            en: 'Audit authority',
            code: 'Table-SHQQX',
            French: 'jjj',
            zh: '审核权限'
        },
        {
            en: 'Anti approval authority',
            code: 'Table-FSHQX',
            French: 'jjj',
            zh: '反审核权限'
        },
        {
            en: 'Printed de approval permission',
            code: 'Table-YDYFSHQX',
            French: 'jjj',
            zh: '已打印反审核权限'
        },
        {
            en: 'User code',
            code: 'Table-YHBM',
            French: 'jjj',
            zh: '用户编码'
        },
        {
            en: 'Name Of Organization',
            code: 'Table-ZZMC',
            French: 'jjj',
            zh: '组织名称'
        },
        {
            en: 'Electronic signature ',
            code: 'Table-DZQM',
            French: 'jjj',
            zh: '电子签名'
        },
        {
            en: 'Edit',
            code: 'Table-BJ',
            French: 'jjj',
            zh: '编辑'
        },
        {
            en: 'Authority Of Inspection department',
            code: 'Table-JYKSQX',
            French: 'jjj',
            zh: '检验科室权限'
        },
        {
            en: 'Rule Details',
            code: 'Table-GZMX',
            French: 'jjj',
            zh: '规则明细'
        },
        {
            en: 'Test',
            code: 'Table-CS',
            French: 'jjj',
            zh: '测试'
        },
        {
            en: 'Verification',
            code: 'Table-YZ',
            French: 'jjj',
            zh: '验证'
        },
        {
            en: 'Validation History',
            code: 'Table-YZLS',
            French: 'jjj',
            zh: '验证历史'
        },
        {
            en: 'Rule Detail Type',
            code: 'Table-GZMXLX',
            French: 'jjj',
            zh: '规则明细类型'
        },
        {
            en: 'Rule Detail Name',
            code: 'Table-GZMXMC',
            French: 'jjj',
            zh: '规则明细名称'
        },
        {
            en: 'Front Bracket',
            code: 'Table-QKH',
            French: 'jjj',
            zh: '前括号'
        },
        {
            en: 'Back Bracket',
            code: 'Table-HKH',
            French: 'jjj',
            zh: '后括号'
        },
        {
            en: 'Connector',
            code: 'Table-LJF',
            French: 'jjj',
            zh: '连接符'
        },
        {
            en: 'Start Time And End Time',
            code: 'Table-KSSJHJSSJ',
            French: 'jjj',
            zh: '开始时间和结束时间'
        },
        {
            en: 'Automatic Audit Rules',
            code: 'Table-ZDSHGZ',
            French: 'jjj',
            zh: '自动审核规则'
        },
        {
            en: 'Verification Results',
            code: 'Table-YZJG',
            French: 'jjj',
            zh: '验证结果'
        },
        {
            en: 'Sample Information',
            code: 'Table-YBXX',
            French: 'jjj',
            zh: '样本信息'
        },
        {
            en: 'Total',
            code: 'Table-ZS',
            French: 'jjj',
            zh: '总数'
        },
        {
            en: 'Verified',
            code: 'Table-YYZ',
            French: 'jjj',
            zh: '已验证'
        },
        {
            en: 'Adopt',
            code: 'Table-TG',
            French: 'jjj',
            zh: '通过'
        },
        {
            en: 'No',
            code: 'Table-BTG',
            French: 'jjj',
            zh: '不通过'
        },
        {
            en: 'Inspection Time',
            code: 'Table-JYSJ',
            French: 'jjj',
            zh: '检验时间'
        },
        {
            en: 'Sample ID',
            code: 'Table-YBID',
            French: 'jjj',
            zh: '样本ID'
        },
        {
            en: 'Different',
            code: 'Table-Y',
            French: 'jjj',
            zh: '异'
        },
        {
            en: 'Dangerous',
            code: 'Table-W',
            French: 'jjj',
            zh: '危'
        },
        {
            en: 'Date Range',
            code: 'Table-RQFW',
            French: 'jjj',
            zh: '日期范围'
        },
        {
            en: 'Expression',
            code: 'Table-BDS',
            French: 'jjj',
            zh: '表达式'
        },
        {
            en: 'Display Value',
            code: 'Table-XSZ',
            French: 'jjj',
            zh: '显示值'
        },
        {
            en: 'Judgment Value',
            code: 'Table-PDZ',
            French: 'jjj',
            zh: '判断值'
        },
        {
            en: 'Logical Symbol',
            code: 'Table-LJF',
            French: 'jjj',
            zh: '逻辑符'
        },
        {
            en: 'Value Type',
            code: 'Table-ZLX',
            French: 'jjj',
            zh: '值类型'
        },
        {
            en: 'Type',
            code: 'Table-LX',
            French: 'jjj',
            zh: '类型'
        },
        {
            en: 'Rule Level',
            code: 'Table-GZDJ',
            French: 'jjj',
            zh: '规则等级'
        },
        {
            en: 'Rule Description',
            code: 'Table-GZSM',
            French: 'jjj',
            zh: '规则说明'
        },
        {
            en: 'Rule Judgment Description Statement',
            code: 'Table-GZPDMSYJ',
            French: 'jjj',
            zh: '规则判断描述语句'
        },
        {
            en: 'Rule Result Orientation',
            code: 'Table-GZJGCX',
            French: 'jjj',
            zh: '规则结果朝向'
        },
        {
            en: 'Source Of Rules',
            code: 'Table-GZLY',
            French: 'jjj',
            zh: '规则来源'
        },
        {
            en: 'Rule Tips',
            code: 'Table-GZTS',
            French: 'jjj',
            zh: '规则提示'
        },
        {
            en: 'Replacement Statement',
            code: 'Table-THYJ',
            French: 'jjj',
            zh: '替换语句'
        },
        {
            en: 'Statement Description',
            code: 'Table-YJMS',
            French: 'jjj',
            zh: '语句描述'
        },
        {
            en: 'Test Result',
            code: 'Table-CSJG',
            French: 'jjj',
            zh: '测试结果'
        },
        {
            en: 'Inspection Platform',
            code: 'Table-JYPT',
            French: 'jjj',
            zh: '检验平台'
        },
        {
            en: 'Validation Status',
            code: 'Table-YZZT',
            French: 'jjj',
            zh: '验证状态'
        },
        {
            en: 'Rule Expression Description',
            code: 'Table-GZBDSMS',
            French: 'jjj',
            zh: '规则表达式描述'
        },
        {
            en: 'Regular Expression',
            code: 'Table-GZBDS',
            French: 'jjj',
            zh: '规则表达式'
        },
        {
            en: 'Item Value',
            code: 'Table-XMZ',
            French: 'jjj',
            zh: '项目值'
        },
        {
            en: 'Verification Results',
            code: 'Table-YZJG',
            French: 'jjj',
            zh: '验证结果'
        },
        {
            en: 'Yes',
            code: 'Table-S',
            French: 'jjj',
            zh: '是'
        },
        {
            en: 'No',
            code: 'Table-F',
            French: 'jjj',
            zh: '否'
        },
        {
            en: 'Is The Same Initial Reviewer / Reviewer Allowed',
            code: 'Table-SFYXCSYSHZXT',
            French: 'jjj',
            zh: '是否允许初审/审核者相同'
        },
        {
            en: 'Platform Icon',
            code: 'Table-PTTB',
            French: 'jjj',
            zh: '平台图标'
        },
        {
            en: 'Start Stop Rules',
            code: 'Table-QTGZ',
            French: 'jjj',
            zh: '启停规则'
        },
        {
            en: 'Project Not Participating In Automatic Audit',
            code: 'Table-BCYZDSHXMSZ',
            French: 'jjj',
            zh: '不参与自动审核项目设置'
        },
        {
            en: 'Program Link',
            code: 'Table-FAHJ',
            French: 'jjj',
            zh: '方案环节'
        },
        {
            en: 'Scheme Name',
            code: 'Table-FAMC',
            French: 'jjj',
            zh: '方案名称'
        },
        {
            en: 'Scheme Coding',
            code: 'Table-FABM',
            French: 'jjj',
            zh: '方案编码'
        },
        {
            en: 'Start Node',
            code: 'Table-KSJD',
            French: 'jjj',
            zh: '开始节点'
        },
        {
            en: 'End Node',
            code: 'Table-JSJD',
            French: 'jjj',
            zh: '结束节点'
        },
        {
            en: 'Link Reminder',
            code: 'Table-HJTX',
            French: 'jjj',
            zh: '环节提醒'
        },
        {
            en: 'Start Node Sample Status',
            code: 'Table-KSJDYBZT',
            French: 'jjj',
            zh: '开始节点样本状态'
        },
        {
            en: 'End Node Sample Status',
            code: 'Table-JSJDYBZT',
            French: 'jjj',
            zh: '结束节点样本状态'
        },
        {
            en: 'Time Interval (Min)',
            code: 'Table-SJJG',
            French: 'jjj',
            zh: '时间间隔(分)'
        },
        {
            en: 'Advance Notice Time (Min)',
            code: 'Table-TQTZSJ',
            French: 'jjj',
            zh: '提前通知时间(分)'
        },
        {
            en: 'Inform Department',
            code: 'Table-TZKS',
            French: 'jjj',
            zh: '通知科室'
        },
        {
            en: 'Inform Personnel',
            code: 'Table-TZRY',
            French: 'jjj',
            zh: '通知人员'
        },
        {
            en: 'Please Input Integer Or en Code',
            code: 'Table-QSRZSHYWBM',
            French: 'jjj',
            zh: '请输入整数或英文编码'
        },
        {
            en: 'Tat Scheme Name',
            code: 'Table-TATFAMC',
            French: 'jjj',
            zh: 'TAT方案名称'
        },
        {
            en: 'Tat Link',
            code: 'Table-TATHJ',
            French: 'jjj',
            zh: 'TAT环节'
        },
        {
            en: 'Tat Scheme',
            code: 'Table-TATFA',
            French: 'jjj',
            zh: 'TAT方案'
        },
        {
            en: 'Inspection Item Code',
            code: 'Table-JYXMBM',
            French: 'jjj',
            zh: '检验项目编码'
        },
        {
            en: 'Name Of Inspection Item',
            code: 'Table-JYXMMC',
            French: 'jjj',
            zh: '检验项目名称'
        },
        {
            en: 'Abbreviation Of Inspection Items',
            code: 'Table-JYXMJC',
            French: 'jjj',
            zh: '检验项目简称'
        },
        {
            en: 'End Date',
            code: 'Table-JSRQ',
            French: 'jjj',
            zh: '结束日期'
        },
        {
            en: 'Quality Control Items',
            code: 'TITLE-ZKXM',
            French: 'jjj',
            zh: '质控项目'
        },
        {
            en: 'Graphic Type',
            code: 'Table-TXLX',
            French: 'jjj',
            zh: '图形类型'
        },
        {
            en: 'Z-fractional Graph',
            code: 'Table-ZFST',
            French: 'jjj',
            zh: 'Z分数图'
        },
        {
            en: 'Z-Score',
            code: 'Table-ZFS',
            French: 'jjj',
            zh: 'Z分数'
        },
        {
            en: 'Quality Control Time',
            code: 'Table-ZKSJ',
            French: 'jjj',
            zh: '质控时间'
        },
        {
            en: 'Quality Control Time Cannot Be Empty',
            code: 'Table-ZKSJBNWK',
            French: 'jjj',
            zh: '质控时间不能为空'
        },
        {
            en: 'Breaking The Rules',
            code: 'Table-WFGZ',
            French: 'jjj',
            zh: '违反规则'
        },
        {
            en: 'Out Of Control',
            code: 'Table-SKZT',
            French: 'jjj',
            zh: '失控状态'
        },
        {
            en: 'Set Up',
            code: 'Table-SD',
            French: 'jjj',
            zh: '设定'
        },
        {
            en: 'Current',
            code: 'Table-DQ',
            French: 'jjj',
            zh: '当前'
        },
        {
            en: 'Last Month',
            code: 'Table-SY',
            French: 'jjj',
            zh: '上月'
        },
        {
            en: 'Accumulated In The Same Batch',
            code: 'Table-TPCLJ',
            French: 'jjj',
            zh: '同批次累积'
        },
        {
            en: 'Quality Control Results',
            code: 'Table-ZKJG',
            French: 'jjj',
            zh: '质控结果'
        },
        {
            en: 'Quality Control Chart',
            code: 'Table-ZKT',
            French: 'jjj',
            zh: '质控图'
        },
        {
            en: 'A5 Printing Direction',
            code: 'Table-A5DYFX',
            French: 'jjj',
            zh: 'A5打印方向'
        },
        {
            en: 'Report Size',
            code: 'Table-BGCC',
            French: 'jjj',
            zh: '报告尺寸'
        },
        {
            en: 'Sample Status',
            code: 'Table-YBZT',
            French: 'jjj',
            zh: '样本状态'
        },
        {
            en: 'Not On The Computer',
            code: 'Table-WSJ',
            French: 'jjj',
            zh: '未上机'
        },
        {
            en: 'Operate A Computer',
            code: 'Table-SJ',
            French: 'jjj',
            zh: '上机'
        },
        {
            en: 'Partial Operation',
            code: 'Table-BFSJ',
            French: 'jjj',
            zh: '部份上机'
        },
        {
            en: 'All On The Computer',
            code: 'Table-QSJ',
            French: 'jjj',
            zh: '全上机'
        },
        {
            en: 'Report Query Printing',
            code: 'Table-BGCXDY',
            French: 'jjj',
            zh: '报告查询打印'
        },
        {
            en: 'Detection Result ',
            code: 'Table-JCJG',
            French: 'jjj',
            zh: '检测结果'
        },
        {
            en: 'Reference Range',
            code: 'Table-CKFW',
            French: 'jjj',
            zh: '参考范围'
        },
        {
            en: 'Inspection Instrument',
            code: 'Table-JYYQ',
            French: 'jjj',
            zh: '检验仪器'
        },
        {
            en: 'Patient Type',
            code: 'Table-BRLX',
            French: 'jjj',
            zh: '病人类型'
        },
        {
            en: 'Sample Number',
            code: 'Table-YBH',
            French: 'jjj',
            zh: '样本号'
        },
        {
            en: 'Reasons For Failure',
            code: 'Table-SBYY',
            French: 'jjj',
            zh: '失败原因'
        },
        {
            en: 'Failed To Print Report',
            code: 'Table-DYBGSB',
            French: 'jjj',
            zh: '打印报告失败'
        },
        {
            en: 'Whole',
            code: 'Table-QB',
            French: 'jjj',
            zh: '全部'
        },
        {
            en: 'Reviewed',
            code: 'Table-YSH',
            French: 'jjj',
            zh: '已审核'
        },
        {
            en: 'Partial Printing',
            code: 'Table-BFDY',
            French: 'jjj',
            zh: '部份打印'
        },
        {
            en: 'Bar Code / Inpatient Number / Outpatient / Physical Examination Number / Patient Name',
            code: 'Table-TM/ZYH/MZ/TJH/BRXM',
            French: 'jjj',
            zh: '条码/住院号/门诊/体检号/病人姓名'
        },
        {
            en: 'Print Settings',
            code: 'Table-DYSZ',
            French: 'jjj',
            zh: '打印设置'
        },
        {
            en: 'Time Grouping',
            code: 'Table-SJFZ',
            French: 'jjj',
            zh: '时间分组'
        },
        {
            en: 'Statistical Time',
            code: 'Table-TJSJ',
            French: 'jjj',
            zh: '统计时间'
        },
        {
            en: 'Please Select Start Time',
            code: 'Table-QXZKSSJ',
            French: 'jjj',
            zh: '请选择开始时间'
        },
        {
            en: 'Please Select The End Time',
            code: 'Table-QXZJSSJ',
            French: 'jjj',
            zh: '请选择结束时间'
        },
        {
            en: 'Please Select The Starting Month',
            code: 'Table-QXZKSYF',
            French: 'jjj',
            zh: '请选择开始月份'
        },
        {
            en: 'Please Select The Ending Month',
            code: 'Table-QXZJSYF',
            French: 'jjj',
            zh: '请选择结束月份'
        },
        {
            en: 'Please Select The Start year',
            code: 'Table-QXZKSNF',
            French: 'jjj',
            zh: '请选择开始年份'
        },
        {
            en: 'Please Select End year',
            code: 'Table-QXZJSNF',
            French: 'jjj',
            zh: '请选择结束年份'
        },
        {
            en: 'Start Time And End Time Cannot Be Empty',
            code: 'Table-KSSJHJSSJBKYWK',
            French: 'jjj',
            zh: '开始时间和结束时间不可以为空'
        },
        {
            en: 'Please Select Both Start Time And End Time',
            code: 'Table-QTSXZKSSJHJSSJ',
            French: 'jjj',
            zh: '请同时选择开始时间和结束时间'
        },
        {
            en: 'No Printer Specified, Please Go To The Operation Settings To Specify A Printer First',
            code: 'Table-WZDDYJ,QXQCZSJZZDDYJ',
            French: 'jjj',
            zh: '未指定打印机，请先去操作设置中指定打印机'
        },
        {
            en: 'Day',
            code: 'Table-R',
            French: 'jjj',
            zh: '日'
        },
        {
            en: 'Month',
            code: 'Table-Y',
            French: 'jjj',
            zh: '月'
        },
        {
            en: 'Year',
            code: 'Table-N',
            French: 'jjj',
            zh: '年'
        },
        {
            en: 'Statistics Of Sample Size Submitted By Departments',
            code: 'Table-KSSJYBLTJ',
            French: 'jjj',
            zh: '科室送检样本量统计'
        },
        {
            en: 'Inspection Type',
            code: 'Table-SJLX',
            French: 'jjj',
            zh: '送检类型'
        },
        {
            en: 'Record The Time',
            code: 'Table-JLSJ',
            French: 'jjj',
            zh: '记录时间'
        },
        {
            en: 'Statistics Of Working Sample Size Of Examiners',
            code: 'Table-JYZGZYBLTJ',
            French: 'jjj',
            zh: '检验者工作样本量统计'
        },
        {
            en: 'Abnormal Specimen Processing Type',
            code: 'Table-YCBBCLLX',
            French: 'jjj',
            zh: '异常标本处理类型'
        },
        {
            en: 'Number Of Unqualified Samples',
            code: 'Table-BHGBBS',
            French: 'jjj',
            zh: '不合格标本数'
        },
        {
            en: 'Statistics Of Applied Projects',
            code: 'Table-SQXMTJ',
            French: 'jjj',
            zh: '申请项目统计'
        },
        {
            en: 'Statistics Of Inspection Items',
            code: 'Table-JYXMLTJ',
            French: 'jjj',
            zh: '检验项目量统计'
        },
        {
            en: 'Please Select',
            code: 'Table-QYZ',
            French: 'jjj',
            zh: '请选择'
        },
        {
            en: 'Batch Number',
            code: 'Table-PCH',
            French: 'jjj',
            zh: '批次号'
        },
        {
            en: 'Report Size',
            code: 'Table-CCBG',
            French: 'jjj',
            zh: '报告尺寸'
        },
        {
            en: 'Batch Number Cannot Be Empty, Please Check',
            code: 'Table-PC',
            French: 'jjj',
            zh: '批次号不能为空，请检查'
        },
        {
            en: 'Estimated Report Time Cannot Be Empty',
            code: 'Table-YJBGSJBNWK',
            French: 'jjj',
            zh: '预计报告时间不能为空'
        },
        {
            en: 'Specimen Bar Code Cannot Be Empty',
            code: 'Table-BBTMBNWK',
            French: 'jjj',
            zh: '标本条码不能为空'
        },
        {
            en: 'Barcode Cannot Be Empty',
            code: 'Table-TMBNWK',
            French: 'jjj',
            zh: '条码不能为空'
        },
        {
            en: 'This Sample Is Not From This Batch, Please Check',
            code: 'Table-GYBBSZYPCD',
            French: 'jjj',
            zh: '该样本不是这一批次的，请检查'
        },
        {
            en: 'No Specimen To Be Confirmed, Please Check',
            code: 'Table-MYXQRDBB',
            French: 'jjj',
            zh: '没有需确认的标本，请检查'
        },
        {
            en: 'Please Check The Specimen To Be Operated First',
            code: 'Table-QXGXXYCZDBB',
            French: 'jjj',
            zh: '请先勾选需要操作的标本'
        },
        {
            en: 'Specimen Handover',
            code: 'TITLE-BBJJ',
            French: 'jjj',
            zh: '标本交接'
        },
        {
            en: 'Transferred Query',
            code: 'TITLE-YJJCX',
            French: 'jjj',
            zh: '已交接查询'
        },
        {
            en: 'Inspection Platform',
            code: 'TABLE-JYPT',
            French: 'jjj',
            zh: '检验平台'
        },
        {
            en: 'Handover Mode',
            code: 'TABLE-JJMS',
            French: 'jjj',
            zh: '交接模式'
        },
        {
            en: 'Scan Mode',
            code: 'TABLE-SMMS',
            French: 'jjj',
            zh: '扫码模式'
        },
        {
            en: 'Query Mode',
            code: 'TABLE-CXMS',
            French: 'jjj',
            zh: '查询模式'
        },
        {
            en: 'Collection Time',
            code: 'TABLE-HSSJ',
            French: 'jjj',
            zh: '核收时间'
        },
        {
            en: 'Handover Time',
            code: 'TABLE-JJSJ',
            French: 'jjj',
            zh: '交接时间'
        },
        {
            en: 'Handover person',
            code: 'TABLE-JJR',
            French: 'jjj',
            zh: '交接人'
        },
        {
            en: 'Handover Batch Number',
            code: 'TABLE-JJPCH',
            French: 'jjj',
            zh: '交接批次号'
        },
        {
            en: 'Barcode Cannot Be Empty In Code Scanning Mode',
            code: 'TABLE-SMMSSBK',
            French: 'jjj',
            zh: '扫码模式下条码不能为空'
        },
        {
            en: 'Statistics Of Working Sample Size Of Inspection Platform',
            code: 'Table-JYPTGZYBLTJ',
            French: 'jjj',
            zh: '检验平台工作样本量统计'
        },
        {
            en: 'Statistics Of Application Items Submitted By Departments',
            code: 'Table-KSSJSQXMLTJ',
            French: 'jjj',
            zh: '科室送检申请项目量统计'
        },
        {
            en: 'Statistics Of Inspection Items Submitted By Departments',
            code: 'Table-KSSJJYXMLTJ',
            French: 'jjj',
            zh: '科室送检检验项目量统计'
        },
        {
            en: 'Auditor Work Sample Size Statistics',
            code: 'Table-SHZGZYBLTJ',
            French: 'jjj',
            zh: '审核者工作样本量统计'
        },
        {
            en: 'Please Select Tat Scheme First',
            code: 'Table-QXXZTATFA',
            French: 'jjj',
            zh: '请先选择TAT方案'
        },
        {
            en: 'In Query Mode, The Check Time Cannot Be blank',
            code: 'TABLE-CXMSX',
            French: 'jjj',
            zh: '查询模式下，核收时间不能为空'
        },
        {
            en: 'Please Check The Data To Be Handed Over',
            code: 'TABLE-QGXXYJJSJ',
            French: 'jjj',
            zh: '请勾选需要交接的数据'
        },
        {
            en: 'Operation Settings',
            code: 'TABLE-CZSZ',
            French: 'jjj',
            zh: '操作设置'
        },
        {
            en: 'Memory Settings',
            code: 'TABLE-JYSZ',
            French: 'jjj',
            zh: '记忆设置'
        },
        {
            en: 'Other Operations',
            code: 'TABLE-QTSZ',
            French: 'jjj',
            zh: '其他操作'
        },
        {
            en: 'Other',
            code: 'TABLE-QT',
            French: 'jjj',
            zh: '其他'
        },
        {
            en: 'Sample To Be Checked / Cancelled',
            code: 'TABLE-YBDC/QX',
            French: 'jjj',
            zh: '样本待查/取消'
        },
        {
            en: 'Sample Void',
            code: 'TABLE-YBZF',
            French: 'jjj',
            zh: '样本作废'
        },
        {
            en: 'Sample Delay',
            code: 'TABLE-YBYC',
            French: 'jjj',
            zh: '样本延迟'
        },
        {
            en: 'Sample Review',
            code: 'TABLE-YBFC',
            French: 'jjj',
            zh: '样本复查'
        },
        {
            en: 'Reexamination Of The Original',
            code: 'TABLE-YYBHFC',
            French: 'jjj',
            zh: '原样本号复查'
        },
        {
            en: 'Set To Positive / Cancel',
            code: 'TABLE-SZWYX/QX',
            French: 'jjj',
            zh: '设置为阳性/取消'
        },
        {
            en: 'Make Up Barcode',
            code: 'TABLE-BDTM',
            French: 'jjj',
            zh: '补打条码'
        },
        {
            en: 'Sample Information Modification',
            code: 'TABLE-YBXXXG',
            French: 'jjj',
            zh: '样本信息修改'
        },
        {
            en: 'Batch Addition',
            code: 'TABLE-PLZX',
            French: 'jjj',
            zh: '批量增项'
        },
        {
            en: 'Batch Modification Results',
            code: 'TABLE-PLXGJG',
            French: 'jjj',
            zh: '批量修改结果'
        },
        {
            en: 'Batch Preliminary Examination',
            code: 'TABLE-PLCS',
            French: 'jjj',
            zh: '批量初审'
        },
        {
            en: 'Batch Audit',
            code: 'TABLE-PLSH',
            French: 'jjj',
            zh: '批量审核'
        },
        {
            en: 'Approval',
            code: 'TABLE-SP',
            French: 'jjj',
            zh: '审批'
        },
        {
            en: 'Save After Jump',
            code: 'TABLE-BCHTZ',
            French: 'jjj',
            zh: '保存后跳转'
        },
        {
            en: 'Jump After Audit',
            code: 'TABLE-SHHDZ',
            French: 'jjj',
            zh: '审核后跳转'
        },
        {
            en: 'Jump After Printing',
            code: 'TABLE-DYHTZ',
            French: 'jjj',
            zh: '打印后跳转'
        },
        {
            en: 'Auto Save',
            code: 'TABLE-ZDBC',
            French: 'jjj',
            zh: '自动保存'
        },
        {
            en: 'Show Extended Information',
            code: 'TABLE-XSKZXX',
            French: 'jjj',
            zh: '显示扩展信息'
        },
        {
            en: 'Audit Pop Up History',
            code: 'TABLE-SHCCLSJL',
            French: 'jjj',
            zh: '审核弹出历史记录'
        },
        {
            en: 'Continuous Receiving Of New Samples',
            code: 'TABLE-SZYBLXJS',
            French: 'jjj',
            zh: '新增样本连续接收'
        },
        {
            en: 'Inspection And Acceptance',
            code: 'TABLE-JYJS',
            French: 'jjj',
            zh: '检验接收'
        },
        {
            en: 'Sample Number Prefix',
            code: 'TABLE-YBHQZ',
            French: 'jjj',
            zh: '样本号前缀'
        },
        {
            en: 'Starting Number',
            code: 'TABLE-QSH',
            French: 'jjj',
            zh: '起始号'
        },
        {
            en: 'Enter The Number',
            code: 'TABLE-SRSZ',
            French: 'jjj',
            zh: '输入数字'
        },
        {
            en: 'Historical Results',
            code: 'TABLE-LSJG',
            French: 'jjj',
            zh: '历史结果'
        },
        {
            en: 'Historical Result 1',
            code: 'TABLE-LSJG1',
            French: 'jjj',
            zh: '历史结果1'
        },
        {
            en: 'Historical Results 2',
            code: 'TABLE-LSJG2',
            French: 'jjj',
            zh: '历史结果2'
        },
        {
            en: 'Historical Result 3',
            code: 'TABLE-LSJG3',
            French: 'jjj',
            zh: '历史结果3'
        },
        {
            en: 'Result Time',
            code: 'TABLE-JGSJ',
            French: 'jjj',
            zh: '结果时间'
        },
        {
            en: 'Reviewed',
            code: 'TABLE-BFC',
            French: 'jjj',
            zh: '被复查'
        },
        {
            en: 'Record Of Results',
            code: 'TABLE-JGJL',
            French: 'jjj',
            zh: '结果记录'
        },
        {
            en: 'Culture Results',
            code: 'TABLE-PYJG',
            French: 'jjj',
            zh: '培养结果'
        },
        {
            en: 'Form',
            code: 'TABLE-XT',
            French: 'jjj',
            zh: '形态'
        },
        {
            en: 'Colony Count',
            code: 'TABLE-JLJS',
            French: 'jjj',
            zh: '菌落计数'
        },
        {
            en: 'Proportion',
            code: 'TABLE-BL',
            French: 'jjj',
            zh: '比例'
        },
        {
            en: 'Training Date',
            code: 'TABLE-PYRQ',
            French: 'jjj',
            zh: '培养日期'
        },
        {
            en: 'Cultivation Days',
            code: 'TABLE-PYTS',
            French: 'jjj',
            zh: '培养天数'
        },
        {
            en: 'Multidrug Resistance',
            code: 'TABLE-DCNY',
            French: 'jjj',
            zh: '多重耐药'
        },
        {
            en: 'Report Type',
            code: 'TABLE-BGLX',
            French: 'jjj',
            zh: '报告类型'
        },
        {
            en: 'Antibiotic Name',
            code: 'TABLE-KSSMC',
            French: 'jjj',
            zh: '抗生素名'
        },
        {
            en: 'Antibiotic Group',
            code: 'TABLE-KSSZ',
            French: 'jjj',
            zh: '抗生素组'
        },
        {
            en: 'Judgment Results',
            code: 'TABLE-PDJG',
            French: 'jjj',
            zh: '判定结果'
        },
        {
            en: 'Diameter / Concentration',
            code: 'TABLE-ZJ/LD',
            French: 'jjj',
            zh: '直径/浓度'
        },
        {
            en: 'Break Point R',
            code: 'TABLE-ZDR',
            French: 'jjj',
            zh: '折点R'
        },
        {
            en: 'Break Point I',
            code: 'TABLE-ZDI',
            French: 'jjj',
            zh: '折点I'
        },
        {
            en: 'Break Point S',
            code: 'TABLE-ZDS',
            French: 'jjj',
            zh: '折点S'
        },
        {
            en: 'Whole',
            code: 'TABLE-Q',
            French: 'jjj',
            zh: '全'
        },
        {
            en: 'Dangerous',
            code: 'TABLE-W',
            French: 'jjj',
            zh: '危'
        },
    
        {
            en: 'Stay',
            code: 'TABLE-D',
            French: 'jjj',
            zh: '待'
        },
        {
            en: 'Different',
            code: 'TABLE-Y',
            French: 'jjj',
            zh: '异'
        },
        {
            en: 'Complex',
            code: 'TABLE-F',
            French: 'jjj',
            zh: '复'
        },
        {
            en: 'Be Recovered',
            code: 'TABLE-BF',
            French: 'jjj',
            zh: '被复'
        },
        {
            en: 'Yang',
            code: 'TABLE-YA',
            French: 'jjj',
            zh: '阳'
        },
        {
            en: 'Result',
            code: 'TABLE-JG',
            French: 'jjj',
            zh: '结果'
        },
        {
            en: 'Under Test',
            code: 'TABLE-JYZ',
            French: 'jjj',
            zh: '检验中'
        },
        {
            en: 'Preliminary Trial',
            code: 'TABLE-YCS',
            French: 'jjj',
            zh: '已初审'
        },
        {
            en: 'Preliminary Trial',
            code: 'TABLE-CS',
            French: 'jjj',
            zh: '初审'
        },
        {
            en: 'To Void',
            code: 'TABLE-ZF',
            French: 'jjj',
            zh: '作废'
        },
        {
            en: 'Urgent',
            code: 'TABLE-JJ',
            French: 'jjj',
            zh: '加急'
        },
        {
            en: 'To Be Investigated',
            code: 'TABLE-DC',
            French: 'jjj',
            zh: '待查'
        },
        {
            en: 'Total Results',
            code: 'TABLE-QJG',
            French: 'jjj',
            zh: '全结果'
        },
        {
            en: 'Original Result',
            code: 'TABLE-YJG',
            French: 'jjj',
            zh: '原结果'
        },
        {
            en: 'New Results',
            code: 'TABLE-XJG',
            French: 'jjj',
            zh: '新结果'
        },
        {
            en: 'Sample Number Range',
            code: 'TABLE-YBHFW',
            French: 'jjj',
            zh: '样本号范围'
        },
        {
            en: 'The Results Were Modified To Read',
            code: 'TABLE-JGXGW',
            French: 'jjj',
            zh: '结果修改为'
        },
        {
            en: 'The Result Is: Original Result*',
            code: 'TABLE-JGJSWYJG',
            French: 'jjj',
            zh: '结果计算为：原结果 *'
        },
        {
            en: 'Department Ward',
            code: 'TABLE-KSBQ',
            French: 'jjj',
            zh: '科室病区'
        },
        {
            en: 'Billing Doctor',
            code: 'TABLE-KDYS',
            French: 'jjj',
            zh: '开单医生'
        },
        {
            en: 'Doctor',
            code: 'TABLE-YS',
            French: 'jjj',
            zh: '医生'
        },
        {
            en: 'Specimen Character',
            code: 'TABLE-BBXZ',
            French: 'jjj',
            zh: '标本性状'
        },
        {
            en: 'Time Of First Trial',
            code: 'TABLE-CSSJ',
            French: 'jjj',
            zh: '初审时间'
        },
        {
            en: 'Audit Time',
            code: 'TABLE-SHSJ',
            French: 'jjj',
            zh: '审核时间'
        },
        {
            en: 'Sample Notes',
            code: 'TABLE-YBBZ',
            French: 'jjj',
            zh: '样本备注'
        },
        {
            en: 'Selection Of Inspection Platform',
            code: 'TABLE-JYPTXZ',
            French: 'jjj',
            zh: '检验平台选择'
        },
        {
            en: 'Results List',
            code: 'TABLE-JGLB',
            French: 'jjj',
            zh: '结果列表'
        },
        {
            en: 'Report Preview',
            code: 'TABLE-BGYL',
            French: 'jjj',
            zh: '报告预览'
        },
        {
            en: 'Sample Flow Record',
            code: 'TABLE-YBLZJL',
            French: 'jjj',
            zh: '样本流转记录'
        },
        {
            en: 'Report Printing Record',
            code: 'TABLE-BGDYJL',
            French: 'jjj',
            zh: '报告打印记录'
        },
        {
            en: 'Suggested Explanation',
            code: 'Table-JYJS',
            French: 'jjj',
            zh: '建议解释'
        },
        {
            en: 'Please Select How To Generate New Results',
            code: 'Table-QXZSCJGDFS',
            French: 'jjj',
            zh: '请选择生成新结果的方式'
        },
        {
            en: 'Please Check The Data That Needs To Generate New Results',
            code: 'Table-QGXXYSCXJGDSJ',
            French: 'jjj',
            zh: '请勾选需要生成新结果的数据'
        },
        {
            en: 'Result Modification Cannot Be Empty',
            code: 'Table-JGXGBWK',
            French: 'jjj',
            zh: '结果修改不能为空'
        },
        {
            en: 'Result Calculation Cannot Be Empty',
            code: 'Table-JGJSBNWk',
            French: 'jjj',
            zh: '结果计算不能为空'
        },
        {
            en: 'Please Check The Data That Needs To Be Updated',
            code: 'Table-QGXXYGXJGDSJ',
            French: 'jjj',
            zh: '请勾选需要更新结果的数据'
        },
        {
            en: 'Please Select The Inspection Platform First',
            code: 'Table-QXSZJYPT',
            French: 'jjj',
            zh: '请先选择检验平台'
        },
        {
            en: 'No Printer Specified, Please Go To The Operation Settings To Specify A Printer First',
            code: 'Table-WZDDYJ',
            French: 'jjj',
            zh: '未指定打印机，请先去操作设置中指定打印机'
        },
        {
            en: 'The Estimated Report Time Cannot Be Less Than The Expected Detection Time',
            code: 'Table-YJBGSJBNXYJ',
            French: 'jjj',
            zh: '预计报告时间不能小于预计检测时间'
        },
        {
            en: 'Please Check The Data To Be Confirmed',
            code: 'Table-QGXXYQZDSJ',
            French: 'jjj',
            zh: '请勾选需要确认核收的数据'
        },
        {
            en: 'Start Number Cannot Be Empty',
            code: 'Table-QSHBNWK',
            French: 'jjj',
            zh: '起始号不能为空'
        },
        {
            en: 'The Starting Number Can Only Be A Number',
            code: 'Table-QSHZNWSZ',
            French: 'jjj',
            zh: '起始号只能为数字'
        },
        {
            en: 'Please Check The Items To Be Reviewed',
            code: 'Table-QGXXYFCDXM',
            French: 'jjj',
            zh: '请勾选需要复查的项目'
        },
        {
            en: 'Please Fill In The Complete Information',
            code: 'Table-QTXWXX',
            French: 'jjj',
            zh: '请填写完整信息'
        },
        {
            en: 'Please Select Current Sample',
            code: 'Table-QXZDQYB',
            French: 'jjj',
            zh: '请选择当前样本'
        },
        {
            en: 'The Current Sample Is In Pending Status',
            code: 'Table-DQYBCYDCZT',
            French: 'jjj',
            zh: '当前样本处于待查状态'
        },
        {
            en: "It's The First Sample",
            code: 'Table-YJSDYGYBL',
            French: 'jjj',
            zh: '已经是第一个样本了'
        },
        {
            en: 'Please Click The Table On The Right To Select The Current Sample',
            code: 'Table-QXDJYYCBGXZDQYB',
            French: 'jjj',
            zh: '请先点击右侧表格选择当前样本'
        },
        {
            en: 'This Is The Last Sample',
            code: 'Table-YJSZHYGYBL',
            French: 'jjj',
            zh: '已经是最后一个样本了'
        },
        {
            en: 'The Inspection Process Is Not Maintained For This Project',
            code: 'Table-GXMWWHYYLC',
            French: 'jjj',
            zh: '该项目未维护检验流程'
        },
        {
            en: 'Inspection Time Cannot Be Empty',
            code: 'Table-YYSJBNWK',
            French: 'jjj',
            zh: '检验时间不能为空'
        },
        {
            en: 'The Current Sample Is To Be Checked, No Operation Is Allowed',
            code: 'Table-DQYBWDCYB',
            French: 'jjj',
            zh: '当前样本为待查样本，不允许任何操作'
        },
        {
            en: 'Only The Sample Under Test Can Be Set To Be Checked / Cancelled. Confirm The Sample Status First',
            code: 'Table-ZYYYZDC/QX',
            French: 'jjj',
            zh: '只有检验中的样本才能设置样本待查/取消,先确认样本状态'
        },
        {
            en: 'Only The Samples In The Test Can Set The Sample Delay, And Confirm The Sample Status First',
            code: 'Table-ZYYYZDC/YC',
            French: 'jjj',
            zh: '只有检验中的样本才能设置样本延迟,先确认样本状态'
        },
        {
            en: 'Only The Samples In The Test Can Be Set As Positive / Cancel. Confirm The Sample Status First',
            code: 'Table-ZYYYZDCYX/QXZT',
            French: 'jjj',
            zh: '只有检验中的样本才能设置样本为阳性/取消,先确认样本状态'
        },
        {
            en: 'Inspection Item Cannot Be Empty',
            code: 'Table-JYXMBNK',
            French: 'jjj',
            zh: '检验项目不能为空'
        },
        {
            en: 'Please Check The Negative Result Data To Be Imported',
            code: 'Table-QGXXYDRYXJGSJ',
            French: 'jjj',
            zh: '请勾选需要导入的阴性结果数据'
        },
        {
            en: 'Please Check The Smear Result Data To Be Imported',
            code: 'Table-QGXXYDRDTP',
            French: 'jjj',
            zh: '请勾选需要导入的涂片结果数据'
        },
        {
            en: 'Please Check The Bacterial Result Data To Be Imported',
            code: 'Table-QGXXYDRDXJSJ',
            French: 'jjj',
            zh: '请勾选需要导入的细菌结果数据'
        },
        {
            en: 'There Is No Antibiotic For This Bacterium',
            code: 'Table-CXJZWKTJDKSS',
            French: 'jjj',
            zh: '此细菌暂无可添加的抗生素'
        },
        {
            en: 'Result Curve',
            code: 'Table-JGQXT',
            French: 'jjj',
            zh: '结果曲线图'
        },
        {
            en: 'Number Of Successful Approval',
            code: 'Table-SPCHTS',
            French: 'jjj',
            zh: '审批成功条数'
        },
        {
            en: 'Number Of Failed Items',
            code: 'Table-SBTS',
            French: 'jjj',
            zh: '条，失败条数'
        },
        {
            en: 'Strip',
            code: 'Table-T',
            French: 'jjj',
            zh: '条'
        },
        {
            en: 'Reasons For Delay',
            code: 'Table-YCYY',
            French: 'jjj',
            zh: '延迟原因'
        },
        {
            en: 'Reason',
            code: 'Table-YY',
            French: 'jjj',
            zh: '原因'
        },
        {
            en: 'Reason Cannot Be Empty',
            code: 'Table-YYBNWK',
            French: 'jjj',
            zh: '原因不能为空'
        },
        {
            en: 'Delay Reason Cannot Be Empty',
            code: 'Table-YCYYBWk',
            French: 'jjj',
            zh: '延迟原因不能为空'
        },
        {
            en: 'Estimated Inspection Time Cannot Be Empty',
            code: 'Table-YJYYSSBK',
            French: 'jjj',
            zh: '预计检验时间不能为空'
        },
        {
            en: 'Continue',
            code: 'Table-JXCZ',
            French: 'jjj',
            zh: '继续操作'
        },
        {
            en: 'Whether To Change The Samples To Be Checked By Other Users',
            code: 'Table-SFBGQTHYYB',
            French: 'jjj',
            zh: '是否变更其他用户待查样本'
        },
        {
            en: 'The Report Has Been Reviewed, Are You Sure To Review',
            code: 'Table-BGYSHSFFC',
            French: 'jjj',
            zh: '报告已审核，是否确认复查'
        },
        {
            en: 'Preservation',
            code: 'Table-BC',
            French: 'jjj',
            zh: '保存'
        },
        {
            en: 'Scan Barcode / Input Barcode',
            code: 'Table-SMTM/SRTM',
            French: 'jjj',
            zh: '扫描条码/输入条码'
        },
        {
            en: 'Sample ID',
            code: 'Table-YBID',
            French: 'jjj',
            zh: '样本ID'
        },
        {
            en: 'Verification Results',
            code: 'Table-YZJG',
            French: 'jjj',
            zh: '验证结果'
        },
        {
            en: 'Processing Type',
            code: 'Table-CCLX',
            French: 'jjj',
            zh: '处理类型'
        },
        {
            en: 'New Registration',
            code: 'Table-XZDJ',
            French: 'jjj',
            zh: '新增登记'
        },
        {
          
            code: 'Table-CLLXBNK',
            French: 'jjj',
            zh: '处理类型不能为空',
            en: 'Processing Type Cannot Be Empty',
        },
        {
            en: 'Print Voucher',
            code: 'Table-DYPZ',
            French: 'jjj',
            zh: '打印凭证'
        },
        {
            en: 'Please Press Enter In The Bar Code Box Input Box To Query The Sample Information',
            code: 'Table-QXZTMKSRK',
            French: 'jjj',
            zh: '请先在条码框输入框中按回车键查询样本信息'
        },

    { 
        code:'Table-XGMM',
        zh:'修改密码',
        en:'Change Password',
        French:'jjj'
    },
    { 
        code:'Table-TCDL',
        zh:'退出登录',
        en:'Exit Login',
        French:'jjj'
    },
    {
        code: 'Table-YLS',
        zh: '云LIS',
        en: 'Cloud LIS',
        French: 'jjj'
    },
    {
        code:'Table-SYS',
        zh:'实验室',
        en:'Laboratory',
        French:'jjj'
    },
    {
        code:'Table-QGXYDYDSJ',
        zh:'请先勾选已打印中的数据',
        en:'Please Tick The Data Already Printed',
        French:'jjj'
    },
    {
        code:'Table-SQLBZWDYSJ',
        zh:'申请列表中暂无打印数据',
        en:'No Print Data At This Time In The Application List',
        French:'jjj'
    },
    // 2020/12/22  翻译更新截止代码
    {
        code:'Table-XZ',
        zh:'选择',
        en:'Select',
        French:'jjj'
    },
    {
        code:'Table-QR',
        zh:'确认',
        en:'confirm',
        French:'jjj'
    },
    {
        code:'Table-QX',
        zh:'取消',
        en:'cancel',
    },
    { 
        code:'Table-XJLX',
        zh:'细菌类型',
        en:'Types Of Bacteria',
        French:'jjj'
    },
    {
        en: 'Validation History Page',
        code: 'Table-YZLSY',
        French: 'jjj',
        zh: '验证历史页'
    },
    {
        en: 'Account Number',
        code: 'TABLE-ZH',
        French: 'jjj',
        zh: '账号'
    },
    {
        en: 'Password',
        code: 'TABLE-MM',
        French: 'jjj',
        zh: '密码'
    },
    {
        en: 'User Authentication',
        code: 'TABLE-YHYZ',
        French: 'jjj',
        zh: '用户验证'
    },
    {
        en: 'Original Password',
        code: 'TABLE-YSMM',
        French: 'jjj',
        zh: '原始密码'
    },
    {
        en: 'New Password',
        code: 'TABLE-XMM',
        French: 'jjj',
        zh: '新密码'
    },
    {
        en: 'Please Enter 6-20 Characters',
        code: 'TABLE-QSRZF',
        French: 'jjj',
        zh: '请输入6-20个字符'
    },
    {
        en: 'Confirm New Password',
        code: 'TABLE-QRXMM',
        French: 'jjj',
        zh: '确认新密码'
    },
    {
        en: 'Please Select API Interface First',
        code: 'TABLE-QXZAPIJK',
        French: 'jjj',
        zh: '请选择api接口'
    },
    {
        en: 'Select Interface',
        code: 'TABLE-XZJK',
        French: 'jjj',
        zh: '选择接口'
    },
    {
        en: 'Select Interface',
        code: 'TABLE-APIXMDY',
        French: 'jjj',
        zh: 'Api项目对应'
    },
    {
        en: 'Corresponding To LIS Project',
        code: 'TABLE-DYLISXM',
        French: 'jjj',
        zh: '对应Lis项目'
    },
    {
        en: 'Project List',
        code: 'TABLE-XMLB',
        French: 'jjj',
        zh: '项目列表'
    },
    {
        en: 'Details',
        code: 'TABLE-XQ',
        French: 'jjj',
        zh: '详情'
    },
    {
        en: 'Please Select At Least One Role Data',
        code: 'TABLE-QZSXZYTJSSJ',
        French: 'jjj',
        zh: '请至少选择一条角色数据'
    },
    {
        en: 'Please Select At Least One Department Data',
        code: 'TABLE-QZSXZYTBMSJ',
        French: 'jjj',
        zh: '请至少选择一条部门数据'
    },
    {
        en: 'Role Name',
        code: 'TABLE-JSMC',
        French: 'jjj',
        zh: '角色名称'
    },
    {
        en: 'Role Coding',
        code: 'TABLE-JSBM',
        French: 'jjj',
        zh: '角色编码'
    },
    {
        en: 'Role Abbreviation',
        code: 'TABLE-JSJC',
        French: 'jjj',
        zh: '角色简称'
    },
    {
        en: 'Login Account',
        code: 'TABLE-DLZH',
        French: 'jjj',
        zh: '登录账号'
    },
    {
        en: 'Real Name',
        code: 'TABLE-ZSXM',
        French: 'jjj',
        zh: '真实姓名'
    },
    {
        en: 'Contact Informatione',
        code: 'TABLE-LXFS',
        French: 'jjj',
        zh: '联系方式'
    },
    {
        en: 'Menu Display Mode',
        code: 'TABLE-CDZSFS',
        French: 'jjj',
        zh: '菜单展示方式'
    },
    {
        en: 'Is Multi Terminal Login Allowed',
        code: 'TABLE-SFYXDDDL',
        French: 'jjj',
        zh: '是否允许多端登录'
    },
    {
        en: 'Please Enter The Correct Contact Information',
        code: 'TABLE-QSRZQDLXFS',
        French: 'jjj',
        zh: '请输入正确的联系方式'
    },
    {
        en: 'Edit User',
        code: 'TABLE-BJYH',
        French: 'jjj',
        zh: '编辑用户'
    },
    {
        en: 'Add Users',
        code: 'TABLE-XZYH',
        French: 'jjj',
        zh: '新增用户'
    },
    {
        en: 'Department',
        code: 'TABLE-SSBM',
        French: 'jjj',
        zh: '所属部门'
    },
    {
        en: 'Please Enter The Correct Format',
        code: 'TABLE-QSRZQDGS',
        French: 'jjj',
        zh: '请输入正确的格式'
    },
    {
        en: 'The Two Password Inputs Are Inconsistent',
        code: 'TABLE-LCMMSRBYZ',
        French: 'jjj',
        zh: '两次密码输入不一致'
    },
    {
        en: 'Role Assignment',
        code: 'TABLE-JSFP',
        French: 'jjj',
        zh: '角色分配'
    },
    {
        en: 'User Role',
        code: 'TABLE-YHJS',
        French: 'jjj',
        zh: '用户角色'
    },
    {
        en: 'Please Check At Least Two Specimens To Be Combined',
        code: 'TABLE-QGXZSLTXYHBDSJ',
        French: 'jjj',
        zh: '请勾选至少两条需要合并的标本'
    },
    {
        en: 'Adding Culture Results',
        code: 'TABLE-TJPYJG',
        French: 'jjj',
        zh: '添加培养结果'
    },
    {
        en: 'Smear Results',
        code: 'TABLE-TPJG',
        French: 'jjj',
        zh: '涂片结果'
    },
    {
        en: 'Bacterial Results',
        code: 'TABLE-XJJG',
        French: 'jjj',
        zh: '细菌结果'
    },
    {
        en: 'Sample Number',
        code: 'TABLE-YBBH',
        French: 'jjj',
        zh: '样本编号'
    },
    {
        en: 'Automatic Printing Succeeded',
        en: 'Negative result',
        code: 'TABLE-YXJG',
        French: 'jjj',
        zh: '阴性结果'
    },
    {
        en: 'Smear results',
        code: 'TABLE-TPJG',
        French: 'jjj',
        zh: '涂片结果'
    },
    {
        en: 'Bacteria results',
        code: 'TABLE-XJJG',
        French: 'jjj',
        zh: '细菌结果'
    },
    {
        en: 'English name',
        code: 'TABLE-YWM',
        French: 'jjj',
        zh: '英文名'
    },
    {
        en: 'Add drug sensitivity results',
        code: 'TABLE-TJPYM',
        French: 'jjj',
        zh: '添加药敏结果'
    },
    {
        en: 'Details of bacterial drug sensitivity results',
        code: 'TABLE-TJPYMMX',
        French: 'jjj',
        zh: '细菌药敏结果明细'
    },
    {
        en: 'Automatic printing successful',
        code: 'TABLE-ZDDYCG',
        French: 'jjj',
        zh: '自动打印成功'
    },
    {
        en: 'Sample status must be received or handed over',
        code: 'TABLE-YBZTBXSY',
        French: 'jjj',
        zh: '样本状态必须是已核收或者已交接'
    },
    {
        en: 'Bacteria',
        code: 'TABLE-XJ',
        French: 'jjj',
        zh: '细菌'
    },
  ];
  
export default {
    languageType
}