import moment from 'moment';
import { value } from '../pages/specimenCollection/HospitalBarcodePrint/services/hospitalBarcodePrint';
import { tableLanguage } from './index'

export const HN = {
  /* 分页pageSize */
  configOpts: () => {
    const opts = [20, 50, 100];
    // const opts = [5, 50, 100];
    return opts;
  },
  configPageSize: () => {
    return 20;
  },
  /* 分页pageSize */
  configOptsMin: () => {
    const opts = [10, 50, 100];
    return opts;
  },
  configPageSizeMin: () => {
    return 10;
  },
  configBtn(menuCode){
    let menuCodes = localStorage.getItem('btn')?JSON.parse(localStorage.getItem('btn')):[]
    let mes = 'none';
    let length = menuCodes.length
    if(menuCodes && length>0){
      for(let i in menuCodes){
        if(menuCodes[i] == menuCode){
          mes = ''
          break;
        }
      }
    }
    return mes;
  },
  // 计算table的高度
  getTableHeight: (n = 0) => {
    const height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - n;
    return height;
  },
  propsConf(value){
    if(value){
      const item = { background: value.backColor, color:value.fontColor  ,  position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '0 10px',
          overflow: 'hidden',
        textOverflow: "ellipsis"};
      return item;
    }
  },
  //递归父级
  getParent(data2, nodeId2) {
    var arrRes = [];
    if (data2.length === 0) {
        if (!!nodeId2) {
            arrRes.unshift(nodeId2);
        }
        return arrRes;
    }
    let rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
            let node = data[i];
            if (node.id === nodeId) {
                arrRes.unshift(nodeId);
                rev(data2, node.parentId);
                break;
            }
            else {
                if (!!node.children) {
                    rev(node.children, nodeId);
                }
            }
        }
        return arrRes;
    };
    arrRes = rev(data2, nodeId2);
    return arrRes;
  },
  deepQuery: (tree, key) => {
    console.log(tree,key)
    let isGet = false;
    let retNode = null;

    function deepSearch(tree, key) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].children && tree[i].children.length > 0) {
          deepSearch(tree[i].children, key);
        }
        console.log(tree[i].uniqueKey)

        if (key == tree[i].uniqueKey || isGet) {
          console.log(tree[i].uniqueKey)
          isGet || (retNode = tree[i]);
          isGet = true;
          break;
        }
      }
    }
    deepSearch(tree, key);
    console.log(retNode)
    return retNode;

  },
 //beseUrl
 configBaseUrl: () => {
  let a = window.location.href
    let cha = '/'
    let num = 2
    function find(a, cha, num) {
      var x = a.indexOf(cha);
      for (var i = 0; i < num; i++) {
        x = a.indexOf(cha, x + 1);
      }
      return x;
    }
    let lnum = find(a, cha, num)
    let b = a.slice(0, lnum)
  return localStorage.getItem('baserURL')
},
 //beseUrl
 configBaseURL: () => {
  let a = localStorage.getItem('baserURL')
    let cha = '/'
    let num = 2
    function find(a, cha, num) {
      var x = a.indexOf(cha);
      for (var i = 0; i < num; i++) {
        x = a.indexOf(cha, x + 1);
      }
      return x;
    }
    let lnum = find(a, cha, num)
    let b = a.slice(0, lnum)
   console.log(b)
  return b
},
getAlldate(type) {
  const now = new Date(); // 当前日期
  const nowMonth = now.getMonth(); // 当前月
  let nowYear = now.getYear(); // 当前年
  nowYear += (nowYear < 2000) ? 1900 : 0; //
  const nowDay = now.getDate(); // 当前日
  const monthStartDate = new Date(nowYear, nowMonth, 1);
  const days = new Date(nowDay, nowMonth + 1, 0).getDate();
  const monthEndDate = new Date(nowYear, nowMonth, days);
  const start_time = moment(monthStartDate).format('YYYY-MM-DD HH:mm:ss');
  const end_time = moment(monthEndDate).format('YYYY-MM-DD HH:mm:ss');
  // console.log(start_time,end_time);

  switch (type) {
    case 1:
      return start_time;
      break;
    case 2:
      return end_time;
      break;
  }
},
//配置前端上下文
configPath: () => {
  return ''
},
//报表获取长宽朝向
getReportMessage:(url)=>{
  let req = new XMLHttpRequest(),Message={};
  req.open('GET', url, false);
  // req.send(null);
  Message.orient = req.getResponseHeader('page-orient');
  Message.width = req.getResponseHeader('page-width');
  Message.height = req.getResponseHeader('page-height');
  return Message
},
//选择语言包
getLanguage:(code)=>{
  
    const { languageType } = tableLanguage
    let language=localStorage.getItem("language")
    for(let i in languageType){
        if(code==languageType[i].code){
          if(language==="English"){
              return languageType[i].en
          }else if(language==="Chinese"){
              return languageType[i].zh
          }
        }
    }

  },
};

