import * as outApp from './services/app';
import { routerRedux } from 'dva/router';
var moment = localStorage.getItem("language")=="Chinese"?require('moment/locale/zh-cn'):require('moment')




const initState = {
    text: "我是全局的state",
    visible:false,
    menuList:[],
}

export default {
    namespace: "app",
    state: initState,
    effects: {
        * logOut({ payload }, { call, put, select }) {
            const dataSource = yield call(outApp.orgType);
            if (dataSource) {
                window.localStorage.removeItem('userMessage');
                window.localStorage.removeItem('token');
                localStorage.removeItem('menu');
                localStorage.removeItem('btn');
                localStorage.removeItem('menu_style');
                localStorage.removeItem('username');
                localStorage.removeItem('baserURL');
                localStorage.removeItem('MenuId');
                localStorage.removeItem('language');
                // localStorage.clear();
                yield put(routerRedux.push('/'));
            }
        },
        *getMenu({ payload }, { call, put, select }) {
            const dataSource = yield call(outApp.getMenu,{id:0});
            if (dataSource) {
                yield put({ type: 'getMenuList', payload: { dataSource } });
            }
        },
        *jump({ payload }, { call, put, select }) {
            const {value} = payload
            if(value.menuRoute){
                localStorage.setItem('MenuId',value.id)
                yield put(routerRedux.push(value.menuRoute))
            }
        }

    },
    reducers: {
        changePasswordClick(state,{payload}){
            return {
                ...state,
                visible:true
            }
        },
        getMenuList(state,{payload}){
            console.log(payload)
            const {dataSource} = payload
            localStorage.setItem('menu',JSON.stringify(dataSource))
            return {
                ...state,
                menuList:dataSource
            }
        },
        onClosePassword(state,{payload}){
            return {
                ...state,
                visible:false
            }
        },
        passwordOnsave(state,{payload}){
            return {
                ...state,
                visible:false
            }
        },
    },
    subscriptions: {
        setup({ dispatch, history }) {
            return history.listen(({ pathname }) => {
                let id = localStorage.getItem('MenuId');
                let title = localStorage.getItem('menuName')
                document.title = title?title:'管理系统'


            });
        },
    },
}
