import React from 'react';
import { Menu, } from "@alifd/next";
import { Link } from 'dva/router';
import * as httpApi from "../../utils/httpApi";

const { SubMenu, Item } = Menu;
const jump =(value) => {
  window.location.href=value.menuRoute;
  document.title = value.menuName
  localStorage.setItem('menuName',value.menuName)
  localStorage.setItem('MenuId',value.id)
  // httpApi.roleButton(value.id).then(res => {
  //   localStorage.setItem('btn',JSON.stringify(res))
  // })
}
const  hozMenu = ((menuData) => {  //创建菜单
  let submenuIndex = 0; //累计的每一项展开菜单索引
  let menu = [];
  if(!menuData){
    return ;
  }

  /*
  * <Item key="1">First</Item>
        <Item key="2">Second</Item>
        <SubMenu label="Sub Nav">
            <Item key="sub-12">Sub option 1</Item>
            <Item key="sub-22">Sub option 2</Item>
            <SubMenu label="Sub Sub Nav">
                <Item key="sub-sub-122">Sub sub option 1</Item>
                <Item key="sub-sub-222">Sub sub option 2</Item>
            </SubMenu>
        </SubMenu>
        *
        * <Nav.SubNav label="Local Nav3">
                            <Item>Local Nav3</Item>
                        </Nav.SubNav>
                        <Item>Local Nav4</Item>
        *
        *
        * */

  const create = (menuData, el) => {
    for (let i = 0; i < menuData.length; i++) {
      if (menuData[i].children && menuData[i].children.length>0 && menuData[i].children[0].menuType == 1) {
        let children = [];
        create(menuData[i].children, children);
        submenuIndex++;
        el.push(
          <SubMenu
            icon={menuData[i].icon}
            key={`sub${submenuIndex}`}
            label={menuData[i].menuName}
          >
            {children}
          </SubMenu>
        )
        // console.log(el)
      } else {
        if(menuData[i].menuType == 1){
          el.push(
            <Item key={menuData[i].menuRoute}>
              <Link onClick={jump.bind(this,menuData[i])} to={menuData[i].menuRoute}>
                <span>{menuData[i].menuName}</span>
              </Link>
            </Item>
          )
        }

      }
    }

  };
  create(menuData, menu);
  return menu;
})
export default hozMenu
