import dva from 'dva';
import './index.css';
import './assets/fonts/iconfont.css'
import {createBrowserHistory} from 'history'
const createHistory = createBrowserHistory();



// 1. Initialize11
const app = dva({
    history:createHistory,
        onError (e, dispatch) {
            console.log('e', e)
        }
});
// 2. Plugins
// app.use({});
// 3. Model
app.model(require('./models/app').default);

// 4. Router
app.router(require('./router').default);

// 5. Start
app.start('#root');

export default app._store;