
import { PubSub } from 'pubsub-js';

let websocket, lockReconnect = false;
let createWebSocket = (url) => {
  console.log(url,'url')
  websocket = new WebSocket(url);
  websocket.onopen = function () {
    heartCheck.reset().start();
  }
  websocket.onerror = function () {
    reconnect(url);
  };
  websocket.onclose = function (e) {
    reconnect(url);
    console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
  }
  websocket.onmessage = function (event) {
    lockReconnect=true;
    //event 为服务端传输的消息，在这里可以处理
    let data=JSON.parse(event.data);//把获取到的消息处理成字典，方便后期使用
    PubSub.publish('message',data);
  }

}
let reconnect = (url) => {
  if (lockReconnect) return;
  //没连接上会一直重连，设置延迟避免请求过多
  setTimeout(function () {
    if(localStorage.getItem("token")){
      createWebSocket(url);
      lockReconnect = false;
    }else{
      websocket && websocket.close();
    }
  }, 4000);
}
let heartCheck = {
  timeout: 30000, //60秒
  timeoutObj: null,
  reset: function () {
    clearInterval(this.timeoutObj);
    return this;
  },
  start: function () {
    this.timeoutObj = setInterval(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      websocket.send("HeartBeat");
    }, this.timeout)
  }
}
//关闭连接
let closeWebSocket=()=> {
  websocket && websocket.close();
}
export {
  websocket,
  createWebSocket,
  closeWebSocket
};

