import React from 'react';
import { Router, Route, Switch } from 'dva/router';
import dynamic from 'dva/dynamic'
import Layout from './pages/Layout/Layout.jsx'
// import {config} from './utils'

const Loading = ({ error }) => {
  if (error) {
    return 'Oh nooess!';
  }
  return <div>Loading...</div>;
};

dynamic.setDefaultLoadingComponent(Loading);

// 登录页面
const logIn = app => dynamic({
  app,
  component: () => import('./pages/Login/Login'),
  models: () => [
    import('./pages/Login/models/login'),
  ],
});

// 首页
const Home = app => dynamic({
  app,
  component: () => import('./pages/Home/Home'),
  models: () => [
    import('./pages/Home/models/home'),
  ],
});

// 菜单管理
const SysMenu = app => dynamic({
  app,
  component: () => import('./pages/plat/SysMenu/SysMenu'),
  models: () => [
    import('./pages/plat/SysMenu/models/sysMenu'),
  ],
});
const TenantManagement=app=> dynamic({
  app,
  component: () => import('./pages/plat/TenantManagement/TenantManagement'),
  models: () => [
    import('./pages/plat/TenantManagement/models/tenantManagement'),
  ],
});
//部门管理
//
const DividionalManage = app => dynamic({
  app,
  component: () => import('./pages/plat/DividionalManage/DividionalManage'),
  models: () => [
    import('./pages/plat/DividionalManage/models/dividionalManage'),
  ],
});

// 角色管理
const SysRole = app => dynamic({
  app,
  component: () => import('./pages/plat/SysRole/SysRole'),
  models: () => [
    import('./pages/plat/SysRole/models/sysRole'),
  ],
});

// 接口权限
const apiPermission = app => dynamic({
  app,
  component: () => import('./pages/plat/apiPermission/apiPermission'),
  models: () => [
    import('./pages/plat/apiPermission/models/apiPermission'),
  ],
});

// 数据权限规则
const dataPermissionRules = app => dynamic({
  app,
  component: () => import('./pages/plat/dataPermissionRules/dataPermissionRules'),
  models: () => [
    import('./pages/plat/dataPermissionRules/models/dataPermissionRules'),
  ],
});
// 功能管理
const fnManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/fnManagement/fnManagement'),
  models: () => [
    import('./pages/plat/fnManagement/models/fnManagement'),
  ],
});

// 系统管理
const ItemLib = app => dynamic({
  app,
  component: () => import('./pages/plat/ItemLib/ItemLib'),
  models: () => [
    import('./pages/plat/ItemLib/models/itemLib'),
  ],
});

// 机构管理
const Organization = app => dynamic({
  app,
  component: () => import('./pages/plat/Organization/Organization'),
  models: () => [
    import('./pages/plat/Organization/models/organization'),
  ],
});
//仪器管理
const Instrument = app => dynamic({
  app,
  component: () => import('./pages/plat/Instrument/Instrument'),
  models: () => [
    import('./pages/plat/Instrument/models/instrument'),
  ],
});
//字典管理
const dicManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/dicManagement/dicManagement'),
  models: () => [
    import('./pages/plat/dicManagement/models/dicManagement'),
  ],
});
//租户菜单
const tenantMenu = app => dynamic({
  app,
  component: () => import('./pages/plat/tenantMenu/tenantMenu'),
  models: () => [
    import('./pages/plat/tenantMenu/models/tenantMenu'),
  ],
});
// 默认菜单
const defaultMenu= app => dynamic({
  app,
  component: () => import('./pages/plat/defaultMenu/defaultMenu'),
  models: () => [
    import('./pages/plat/defaultMenu/models/defaultMenu'),
  ],
});
//平台管理员
const sysUser = app => dynamic({
  app,
  component: () => import('./pages/plat/sysUser/sysUser'),
  models: () => [
    import('./pages/plat/sysUser/models/sysUser'),
  ],
});


//系统参数
const systemParameter = app => dynamic({
  app,
  component: () => import('./pages/plat/SystemParameter/systemParameter'),
  models: () => [
    import('./pages/plat/SystemParameter/models/systemParameter'),
  ],
});

//角色管理
 //
 const RoleManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/RoleManagement/RoleManagement'),
  models: () => [
    import('./pages/plat/RoleManagement/models/roleManagement'),
  ],
});
//操作日志
const OperationLog = app => dynamic({
  app,
  component: () => import('./pages/plat/OperationLog/OperationLog'),
  models: () => [
    import('./pages/plat/OperationLog/models/operationLog'),
  ],
});
//机构管理
const InstitutionsMenu = app => dynamic({
  app,
  component: () => import('./pages/plat/InstitutionsMenu/InstitutionsMenu'),
  models: () => [
    import('./pages/plat/InstitutionsMenu/models/institutionsMenu'),
  ],
});
//机构用户
const InstitutionsUsers = app => dynamic({
  app,
  component: () => import('./pages/plat/InstitutionsUsers/InstitutionsUsers'),
  models: () => [
    import('./pages/plat/InstitutionsUsers/models/institutionsUsers'),
  ],
});
//用户管理
const User = app => dynamic({
  app,
  component: () => import('./pages/plat/Doctors/Doctors'),
  models: () => [
    import('./pages/plat/Doctors/models/doctors'),
  ],
});
//申请项目维护
const applyItem = app => dynamic({
  app,
  component: () => import('./pages/plat/ApplyItem/ApplyItem'),
  models: () => [
    import('./pages/plat/ApplyItem/models/applyItem'),
  ],
});
//申请项目维护(前处理)
const applyItemP = app => dynamic({
  app,
  component: () => import('./pages/plat/ApplyItem/ApplyItemP'),
  models: () => [
    import('./pages/plat/ApplyItem/models/applyItem'),
  ],
});

// 机构检验项目管理
const OrgInspectionItem = app => dynamic({
  app,
  component: () => import('./pages/plat/OrgInspectionItem/OrgInspectionItem'),
  models: () => [
    import('./pages/plat/OrgInspectionItem/models/orgInspectionItem'),
  ],
});
//对接配置
const  DockingConfiguration=app=> dynamic({
  app,
  component: () => import('./pages/plat/DockingConfiguration/DockingConfiguration'),
  models: () => [
    import('./pages/plat/DockingConfiguration/models/dockingConfiguration'),
  ],
});
//对接执行配置
const  DockingExecutionConfiguration=app=> dynamic({
  app,
  component: () => import('./pages/plat/DockingExecutionConfiguration/DockingExecutionConfiguration'),
  models: () => [
    import('./pages/plat/DockingExecutionConfiguration/models/dockingExecutionConfiguration'),
  ],
});
// api项目对应
const APIItem = app => dynamic({
  app,
  component: () => import('./pages/plat/APIItem/APIItem'),
  models: () => [
    import('./pages/plat/APIItem/models/apiItem'),
  ],
});
//机构管理1
 //
 const orgManage = app => dynamic({
  app,
  component: () => import('./pages/plat/orgManage/orgManage'),
  models: () => [
    import('./pages/plat/orgManage/models/orgManage'),
  ],
});

//机构管理云订货
//
const orgManageY = app => dynamic({
  app,
  component: () => import('./pages/plat/orgManageY/orgManage'),
  models: () => [
    import('./pages/plat/orgManageY/models/orgManage'),
  ],
});
//数据权限规则
 //
 const dataRules = app => dynamic({
  app,
  component: () => import('./pages/plat/dataRules/dataRules'),
  models: () => [
    import('./pages/plat/dataRules/models/dataRules'),
  ],
});
//质控计划
const QualityControlPlan = app => dynamic({
  app,
  component: () => import('./pages/plat/QualityControlPlan/QualityControlPlan'),
  models: () => [
    import('./pages/plat/QualityControlPlan/models/qualityControlPlan'),
  ],
});
//机构系统参数
const MechanismSystemParamet = app => dynamic({
  app,
  component: () => import('./pages/plat/MechanismSystemParameters/mechanismSystemParameters'),
  models: () => [
    import('./pages/plat/MechanismSystemParameters/models/mechanismSystemParameters'),
  ],
});
// const { menuGlobal } = config
//项目库管理
const LibraryManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/LibraryManagement/LibraryManagement'),
  models: () => [
    import('./pages/plat/LibraryManagement/models/libraryManagement'),
  ],
});
// //仪器管理
const InstrumentManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/InstrumentManagement/InstrumentManagement'),
  models: () => [
    import('./pages/plat/InstrumentManagement/models/instrumentManagement'),
  ],
});
//机构用户管理
const InstitutionalInstrument = app => dynamic({
  app,
  component: () => import('./pages/plat/InstitutionalInstrument/InstitutionalInstrument'),
  models: () => [
    import('./pages/plat/InstitutionalInstrument/models/institutionalInstrument'),
  ],
});
//机构 科室/部门管理

const AdministrativeManagement = app => dynamic({
  app,
  component: () => import('./pages/plat/AdministrativeManagement/AdministrativeManagement'),
  models: () => [
    import('./pages/plat/AdministrativeManagement/models/administrativeManagement'),
  ],
});
//申请项目维护
const ItemGroupingRules = app => dynamic({
  app,
  component: () => import('./pages/plat/ItemGroupingRules/ItemGroupingRules'),
  models: () => [
    import('./pages/plat/ItemGroupingRules/models/itemGroupingRules'),
  ],
});
//申请项目组合维护1
const ItemPortfolio= app => dynamic({
  app,
  component: () => import('./pages/plat/ItemPortfolio/ItemPortfolio'),
  models: () => [
    import('./pages/plat/ItemPortfolio/models/itemPortfolio'),
  ],
});
//质控规则
const RuleCombination= app => dynamic({
  app,
  component: () => import('./pages/plat/RuleCombination/RuleCombination'),
  models: () => [
    import('./pages/plat/RuleCombination/models/ruleCombination'),
  ],
});


// 检验流程维护
const InspectProcess = app => dynamic({
  app,
  component: () => import('./pages/plat/InspectProcess/InspectProcess'),
  models: () => [
    import('./pages/plat/InspectProcess/models/inspectProcess'),
  ],
});

// 实验室管理
const Laboratory = app => dynamic({
  app,
  component: () => import('./pages/plat/Laboratory/Laboratory'),
  models: () => [
    import('./pages/plat/Laboratory/models/laboratory'),
  ],
});

// 检验平台
const InspectionPlatform = app => dynamic({
  app,
  component: () => import('./pages/org/orgMsg/InspectionPlatform/InspectionPlatform'),
  models: () => [
    import('./pages/org/orgMsg/InspectionPlatform/models/inspectionPlatform'),
  ],
});
// 用户数据维护
const UserPermission = app => dynamic({
  app,
  component: () => import('./pages/org/orgMsg/UserPermission/UserPermission'),
  models: () => [
    import('./pages/org/orgMsg/UserPermission/models/userPermission'),
  ],
});
// 规则分类：RuleClassify
const RuleClassify = app => dynamic({
  app,
  component: () => import('./pages/qualityControlManage/RuleClassify/RuleClassify'),
  models: () => [
    import('./pages/qualityControlManage/RuleClassify/models/ruleClassify'),
  ],
});
// 质控规则:QualityControlRule
const QualityControlRule = app => dynamic({
  app,
  component: () => import('./pages/qualityControlManage/QualityControlRule/QualityControlRule'),
  models: () => [
    import('./pages/qualityControlManage/QualityControlRule/models/qualityControlRule'),
  ],
});

// 检验时间，报告时间规则设置
const TestAndReportTime = app => dynamic({
  app,
  component: () => import('./pages/plat/TestAndReportTime/TestAndReportTime'),
  models: () => [
    import('./pages/plat/TestAndReportTime/models/testAndReportTime'),
  ],
});
// TAT
const TatAngencydataInfo = app => dynamic({
  app,
  component: () => import('./pages/plat/TatAngencydataInfo/TatAngencydataInfo'),
  models: () => [
    import('./pages/plat/TatAngencydataInfo/models/tatAngencydataInfo'),
  ],
});
// 细菌信息
const GermBaseInfo = app => dynamic({
  app,
  component: () => import('./pages/org/itemMsg/GermBaseInfo/GermBaseInfo'),
  models: () => [
    import('./pages/org/itemMsg/GermBaseInfo/models/germBaseInfo'),
  ],
});
//抗生素信息
const AntibioticInfo= app => dynamic({
  app,
  component: () => import('./pages/org/itemMsg/AntibioticInfo/AntibioticInfo'),
  models: () => [
    import('./pages/org/itemMsg/AntibioticInfo/models/antibioticInfo'),
  ],
});
//抗生素组信息
const AntibioticGroupInfo= app => dynamic({
  app,
  component: () => import('./pages/org/itemMsg/AntibioticGroupInfo/AntibioticGroupInfo'),
  models: () => [
    import('./pages/org/itemMsg/AntibioticGroupInfo/models/antibioticGroupInfo'),
  ],
});
// 细菌管理
const GermManage = app => dynamic({
  app,
  component: () => import('./pages/plat/GermManage/GermManage'),
  models: () => [
    import('./pages/plat/GermManage/models/germmanage'),
  ],
});
// 抗生素管理
const AntibioticManage = app => dynamic({
  app,
  component: () => import('./pages/plat/AntibioticManage/AntibioticManage'),
  models: () => [
    import('./pages/plat/AntibioticManage/models/antibioticmanage'),
  ],
});
const RuleManagement= app => dynamic({
  app,
  component: () => import('./pages/plat/RuleManagement/RuleManagement'),
  models: () => [
    import('./pages/plat/RuleManagement/models/ruleManagement'),
  ],
});
const AutomaticAuditRule= app => dynamic({
  app,
  component: () => import('./pages/plat/AutomaticAuditRule/AutomaticAuditRule'),
  models: () => [
    import('./pages/plat/AutomaticAuditRule/models/automaticAuditRule'),
  ],
});

//住院条码打印:HospitalBarcodePrint
const HospitalBarcodePrint = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/HospitalBarcodePrint/HospitalBarcodePrint'),
  models: () => [
    import('./pages/specimenCollection/HospitalBarcodePrint/models/hospitalBarcodePrint'),
  ],
});
//住院标本列表:HospitalSpecimensList
const HospitalSpecimensList = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/HospitalSpecimensList/HospitalSpecimensList'),
  models: () => [
    import('./pages/specimenCollection/HospitalSpecimensList/models/hospitalSpecimensList'),
  ],
});
//门诊条码打印
const OutpatientBarcodePrint = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/OutpatientBarcodePrint/OutpatientBarcodePrint'),
  models: () => [
    import('./pages/specimenCollection/OutpatientBarcodePrint/models/outpatientBarcodePrint'),
  ],
});
// 采血室条码打印
const BloodCollection= app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/BloodCollection/BloodCollection'),
  models: () => [
    import('./pages/specimenCollection/BloodCollection/models/bloodCollection'),
  ],
});

//住院标本按批次查询:HospitalSpecimensBatch
// const HospitalSpecimensBatch = app => dynamic({
//   app,
//   component: () => import('./pages/specimenCollection/HospitalSpecimensBatch/HospitalSpecimensBatch'),
//   models: () => [
//     import('./pages/specimenCollection/HospitalSpecimensBatch/models/hospitalSpecimensBatch'),
//   ],
// });
//标本核收

const SpecimenAccept = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/SpecimenAccept/SpecimenAccept'),
  models: () => [
    import('./pages/specimenCollection/SpecimenAccept/models/specimenAccept'),
  ],
});
// //不合格标本数

const NumberUnqualified = app => dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/NumberUnqualified/NumberUnqualified'),
  models: () => [
    import('./pages/laboratoryStatistics/NumberUnqualified/models/numberUnqualified'),
  ],
});
//申请项目量统计
const ApplicationStatistics = app => dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/ApplicationStatistics/ApplicationStatistics'),
  models: () => [
    import('./pages/laboratoryStatistics/ApplicationStatistics/models/applicationStatistics'),
  ],
});
//科室送检检验项目量统计
const DepartmentsInspectionItems =app=>dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/DepartmentsInspectionItems/DepartmentsInspectionItems'),
  models: () => [
    import('./pages/laboratoryStatistics/DepartmentsInspectionItems/models/departmentsInspectionItems'),
  ],
});
// 科室送检申请项目量统计
const DepartmentsItem = app => dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/DepartmentsItem/DepartmentsItem'),
  models: () => [
    import('./pages/laboratoryStatistics/DepartmentsItem/models/departmentsItem'),
  ],
});
//审核者工作样本量统计
const AuditorSampleStatistics  = app => dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/AuditorSampleStatistics/AuditorSampleStatistics'),
  models: () => [
    import('./pages/laboratoryStatistics/AuditorSampleStatistics/models/auditorSampleStatistics'),
  ],
});
//TAT统计
const TATtotle  = app => dynamic({
  app,
  component: () => import('./pages/laboratoryStatistics/TATtotle/TATtotle'),
  models: () => [
    import('./pages/laboratoryStatistics/TATtotle/models/TATtotle'),
  ],
});
const HospitalSpecimensBatch = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/HospitalSpecimensBatch/HospitalSpecimensBatch'),
  models: () => [
    import('./pages/specimenCollection/HospitalSpecimensBatch/models/hospitalSpecimensBatch'),
  ],
});
// 门诊标本列表:ListofoutpatientSpecimens
const outpatientSpecimenList = app => dynamic({
  app,
  component: () => import('./pages/specimenCollection/OutpatientSpecimenList/OutpatientSpecimenList'),
  models: () => [
    import('./pages/specimenCollection/OutpatientSpecimenList/models/outpatientSpecimenList'),
  ],
});

//检验工作站:InspectionWorkstation
const InspectionWorkstation = app => dynamic({
  app,
  component: () => import('./pages/specimenInspection/InspectionWorkstation/InspectionWorkstation'),
  models: () => [
    import('./pages/specimenInspection/InspectionWorkstation/models/inspectionWorkstation'),
  ],
});
//异常标本111
const SpecimenAbnormal= app => dynamic({
  app,
  component: () => import('./pages/plat/SpecimenAbnormal/SpecimenAbnormal'),
  models: () => [
    import('./pages/plat/SpecimenAbnormal/models/specimenAbnormal'),
  ],
});
//标本交接:SpecimenTransfer
const SpecimenTransfer = app => dynamic({
  app,
  component: () => import('./pages/specimenPreparation/specimenTransfer/SpecimenTransfer'),
  models: () => [
    import('./pages/specimenPreparation/specimenTransfer/models/specimenTransfer'),
  ],
});
//异常标本111
const FullMessageList= app => dynamic({
  app,
  component: () => import('./pages/notify/fullMessageList/FullMessageList'),
  models: () => [
    import('./pages/notify/fullMessageList/models/fullMessageList'),
  ],
});

//异常标本111
const MessageTemplateManagement= app => dynamic({
  app,
  component: () => import('./pages/notify/MessageTemplateManagement/MessageTemplateManagement'),
  models: () => [
    import('./pages/notify/MessageTemplateManagement/models/messageTemplateManagement'),
  ],
});
// 危机值提醒:CriticalValueReminder
const CriticalValueReminder = app => dynamic({
  app,
  component: () => import('./pages/specimenInspection/CriticalValueReminder/CriticalValueReminder'),
  models: () => [
    import('./pages/specimenInspection/CriticalValueReminder/models/criticalValueReminder'),
  ],
});
//报告查询打印:ReportSearchPrint
const ReportSearchPrint = app => dynamic({
  app,
  component: () => import('./pages/specimenInspection/ReportSearchPrint/ReportSearchPrint'),
  models: () => [
    import('./pages/specimenInspection/ReportSearchPrint/models/reportSearchPrint'),
  ],
});
// 检验项目量统计:InspectionItems
const InspectionItems = app => dynamic({
  app,
  component: () => import('./pages/statisticalReport/InspectionItems/InspectionItems'),
  models: () => [
    import('./pages/statisticalReport/InspectionItems/models/inspectionItems'),
  ],
});
// 检验平台工作样本量统计:InspectionSample
const InspectionSample = app => dynamic({
  app,
  component: () => import('./pages/statisticalReport/InspectionSample/InspectionSample'),
  models: () => [
    import('./pages/statisticalReport/InspectionSample/models/inspectionSample'),
  ],
});
//科室送检样本量统计 
const DepartmentsSamplesStatistics =app=>dynamic({
  app,
  component: () => import('./pages/statisticalReport/DepartmentsSamplesStatistics/DepartmentsSamplesStatistics'),
  models: () => [
    import('./pages/statisticalReport/DepartmentsSamplesStatistics/models/departmentsSamplesStatistics'),
  ],
});
//检验者工作样本量统计:InspectorsSampleStatistics
const InspectorsSampleStatistics = app => dynamic({
  app,
  component: () => import('./pages/statisticalReport/InspectorsSampleStatistics/InspectorsSampleStatistics'),
  models: () => [
    import('./pages/statisticalReport/InspectorsSampleStatistics/models/inspectorsSampleStatistics'),
  ],
});
// 质控结果:QualityControlResult
const QualityControlResult = app => dynamic({
  app,
  component: () => import('./pages/qualityControlManage/QualityControlResult/QualityControlResult'),
  models: () => [
    import('./pages/qualityControlManage/QualityControlResult/models/qualityControlResult'),
  ],
});

// 文案管理:languageManage
const languageManage = app => dynamic({
  app,
  component: () => import('./pages/language/language'),
  models: () => [
    import('./pages/language/models/language'),
  ],
});

//绿色通道:greenChannel
const greenChannel = app => dynamic({
  app,
  component: () => import('./pages/pretreat/greenChannel/greenChannel'),
  models: () => [
    import('./pages/pretreat/greenChannel/modles/greenChannel'),
  ],
});


function RouterConfig({ history, app }) {

  return (

    <Router history={history}>
      <Switch>
        {/* {localStorage.getItem('userMessage') ? <Route exact path="/" component={logIn(app)} /> :null} */}
        <Route exact path="/" component={logIn(app)}/>
        <Layout>
           <Route exact path="/home" component={Home(app)} />
          <Route exact path="/labBasis/sysMenu" component={SysMenu(app)} />
          <Route exact path="/labBasis/sysMenuApp" component={SysMenu(app)} />
          <Route exact path="/labBasis/sysRole" component={SysRole(app)} />
          <Route exact path="/labBasis/itemLib" component={ItemLib(app)} />
          <Route exact path="/labBasis/organization" component={Organization(app)} />
          <Route exact path="/labBasis/instrument" component={Instrument(app)} />
          <Route exact path="/labBasis/dicManagement" component={dicManagement(app)} />
          <Route exact path="/labBasis/tenantManagement" component={TenantManagement(app)} />
          <Route exact path="/labBasis/apiPermission" component={apiPermission(app)} />
          <Route exact path="/labBasis/dataPermissionRules" component={dataPermissionRules(app)} />
          <Route exact path="/labBasis/fnManagement" component={fnManagement(app)} />
          <Route exact path="/labBasis/tenantMenu" component={tenantMenu(app)} />
          <Route exact path="/labBasis/defaultMenu" component={defaultMenu(app)} />
          <Route exact path="/labBasis/sysUser" component={sysUser(app)} />
          <Route exact path="/labBasis/User" component={User(app)} />
          <Route exact path="/labBasis/operationLog" component={OperationLog(app)} />
          <Route exact path="/labBasis/dividionalManage" component={DividionalManage(app)} />
          <Route exact path="/labBasis/roleManagement" component={RoleManagement(app)} />
          <Route exact path="/labBasis/systemParameter" component={systemParameter(app)} />
          <Route exact path="/labBasis/institutionsMenu" component={InstitutionsMenu(app)} />
          <Route exact path="/labBasis/institutionsUsers" component={InstitutionsUsers(app)} />
          <Route exact path="/labBasis/orgManage" component={orgManage(app)} />
          <Route exact path="/labBasis/orgManageItemGood" component={orgManageY(app)} />
          <Route exact path="/labBasis/dataRules" component={dataRules(app)} />
          <Route exact path="/labBasis/inspectProcess" component={InspectProcess(app)} />
          <Route exact path="/labBasis/laboratory" component={Laboratory(app)} />
          <Route exact path="/labBasis/inspectionPlatform" component={InspectionPlatform(app)}/>
          <Route exact path="/labBasis/userPermission" component={UserPermission(app)} />
          <Route exact path="/labQC/ruleClassify" component={RuleClassify(app)} />
          <Route exact path="/labQC/qualityControlRule" component={QualityControlRule(app)} />
          <Route exact path="/labBasis/QualityControlPlan" component={QualityControlPlan(app)} />
          <Route exact path="/labBasis/OrgInspectionItem" component={OrgInspectionItem(app)} />
          <Route exact path="/labBasis/libraryManagement"  component={LibraryManagement(app)}/>
          <Route exact path="/labBasis/instrumentManagement"  component={InstrumentManagement(app)}/>
          <Route exact path="/labBasis/institutionalInstrumen"  component={InstitutionalInstrument(app)}/>
          <Route exact path="/labBasis/administrativeManageme"  component={AdministrativeManagement(app)}/>
          <Route exact path="/labBasis/applyItem" component={applyItem(app)} />
          <Route exact path="/labBasis/applyItemP" component={applyItemP(app)} />
          <Route exact path="/labBasis/itemGroupingRules"  component={ItemGroupingRules(app)}/>
          <Route exact path="/labBasis/MechanismSystemParamet" component={MechanismSystemParamet(app)} />
          <Route exact path="/labBasis/itemPortfolio"  component={ItemPortfolio(app)}/>
          <Route exact path="/labBasis/ruleCombination"  component={RuleCombination(app)}/>
          <Route exact path="/labBasis/testAndReportTime" component={TestAndReportTime(app)} />
          <Route exact path="/labBasis/tatAngencydataInfo" component={TatAngencydataInfo(app)} />
          <Route exact path="/labBasis/germBaseInfo" component={GermBaseInfo(app)} />
          <Route exact path="/labBasis/GermManage" component={GermManage(app)} />
          <Route exact path="/labBasis/AntibioticManage" component={AntibioticManage(app)} />
          <Route exact path="/labBasis/automaticAuditRule" component={AutomaticAuditRule(app)} />
          <Route exact path="/labBasis/ruleManagement" component={RuleManagement(app)} />
          <Route exact path="/labInfo/hospitalBarcodePrint" component={HospitalBarcodePrint(app)} />
          <Route exact path="/labInfo/hospitalSpecimensList" component={HospitalSpecimensList(app)} />
          <Route exact path="/labInfo/outpatientSpecimenList" component={outpatientSpecimenList(app)} />
          <Route exact path="/labInfo/outpatientBarcodePrint" component={OutpatientBarcodePrint(app)} />
          <Route exact path="/labInfo/specimenAbnormal" component={SpecimenAbnormal(app)} />
          <Route exact path="/labInfo/specimenAccept" component={SpecimenAccept(app)} />
          <Route exact path="/labInfo/numberUnqualified" component={NumberUnqualified(app)} />
          <Route exact path="/labInfo/applicationStatistics" component={ApplicationStatistics(app)} />
           <Route exact path="/labInfo/hospitalSpecimensBatch" component={HospitalSpecimensBatch(app)} />
           <Route exact path="/labInfo/InspectionWorkstation" component={InspectionWorkstation(app)} />
           <Route exact path="/labInfo/departmentsItem" component={DepartmentsItem(app)} />
          <Route exact path="/labBasis/specimenTransfer" component={SpecimenTransfer(app)} />
          <Route exact path="/labBasis/antibioticInfo" component={AntibioticInfo(app)} />
           <Route exact path="/labBasis/antibioticGroupInfo" component={AntibioticGroupInfo(app)} />
           <Route exact path="/labInfo/criticalValueReminder" component={CriticalValueReminder(app)} />
          <Route exact path="/notify/fullMessageList" component={FullMessageList(app)} />
          <Route exact path="/notify/MsgTemplateManagement" component={MessageTemplateManagement(app)} />
          <Route exact path="/labInfo/criticalValueReminder" component={CriticalValueReminder(app)} />
          <Route exact path="/labInfo/reportSearchPrint" component={ReportSearchPrint(app)} />
          <Route exact path="/labInfo/inspectionSample" component={InspectionSample(app)} />
          <Route exact path="/labInfo/inspectionItems" component={InspectionItems(app)} />
          <Route exact path="/labInfo/departmentsSamplesStatistics" component={DepartmentsSamplesStatistics(app)} />
          <Route exact path="/labInfo/inspectorsSampleStatistics" component={InspectorsSampleStatistics(app)} />
          <Route exact path="/labInfo/auditorSampleStatistics" component={AuditorSampleStatistics(app)} />
          <Route exact path="/labInfo/tatTotle" component={TATtotle(app)} />
          <Route exact path="/labInfo/departmentsInspection" component={DepartmentsInspectionItems(app)} />
          <Route exact path="/labQC/qualityControlResult" component={QualityControlResult(app)} />
          <Route exact path="/system/languageManage" component={languageManage(app)} />
          <Route exact path="/labBasis/DockingConfiguration" component={DockingConfiguration(app)} />
          <Route exact path="/pretreat/greenChannel" component={greenChannel(app)} />
          <Route exact path="/labBasis/DockingExecutionConfiguration" component={DockingExecutionConfiguration(app)} />
          <Route exact path="/labInfo/BloodCollection" component={BloodCollection(app)} />
          <Route exact path="/labBasis/APIItem" component={APIItem(app)} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default RouterConfig;

