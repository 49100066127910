import http from './api'

export function getByDictType(params){// 获取字典值
  return http('/elab-base/profile/dict/byDictType',{dictTypeCode:params})
    .then(res=>{
      return res
    })
}

export function roleButton(id){// 获取字典值
  return http('/elab-base/profile/user/role/menuTree',{id:id})
    .then(res=>{
      return res
    })
}


