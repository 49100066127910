
import http from './../../../../utils/api'

export function getDeptList() {//科室list
    return http('/org-base/profile/orgGroup/listByOrgUserDept', {})
    .then((response) => {
        return response;
    });
}

export function searchItemBtn(params){
    return http('/org-order/barcodePrint/listHospitalizationOrder', {
        deptId:params.dept?params.dept:null,
        endDate:params.endDate?params.endDate:null,
        startDate:params.startDate?params.startDate:null,
        noQueryType:params.searchType?params.searchType:null,
        noQueryValue:params.searchTypeNum?params.searchTypeNum:null,
    })
    .then((response) => {
        return response;
    });
}
export function searchItemPrint(params){
    return http('/org-order/barcodePrint/listPrint', {
        deptId:params.dept?params.dept:null,
        endDate:params.endDate?params.endDate:null,
        startDate:params.startDate?params.startDate:null,
        noQueryType:params.searchType?params.searchType:null,
        noQueryValue:params.searchTypeNum?params.searchTypeNum:null,
    })
    .then((response) => {
        return response;
    });
}

export function printBarcode(params) {//打印条码
    return http('/org-order/barcodePrint/printBarcodeZy', {
        ids:params.ids
    }).then((response) => {
        return response;
    });
}

export function mergeBarcode(params) {//合并条码
    return http('/org-order/barcodePrint/printBarcodeMerge', {
        ids:params.ids
    }).then((response) => {
        return response;
    });
}
export function value(params) {//时间范围
    return http('/elab-base/profile/org/param/default/value', {
        paramCode:"InpatientDateRange",
        paramType:4,
    })
    .then((response) => {
        return response;
    });
}

//下拉列表接口
export function inPatientIndex(params) {//住院
    return http('/plat-base/platDict/enum/inPatientIndex', {
    })
    .then((response) => {
        return response;
    });
}

//字典颜色
export function colors(params) {
    return http('/plat-base/platDict/colors', {
        dictTypeCodes:""
    })
    .then((response) => {
        return response;
    });
}
//获取打印Ur
export function getUrl(params) {
    return http('/org-report/profile/barcode/print/inpatient', {
    })
    .then((response) => {
        return response;
    });
}