import React from 'react';
import { Nav } from "@alifd/next";
import { Link } from 'dva/router';
import * as httpApi from '../../utils/httpApi'
const jump =(value) => {
  localStorage.setItem('MenuId',value.id)
  localStorage.setItem('menuName',value.menuName)
  document.title = value.menuName
 /* httpApi.roleButton(value.id).then(res => {
    localStorage.setItem('btn',JSON.stringify(res))
  })*/
}
const { SubNav, Item, Group, Divider } = Nav;

  const  menu = ((menuData) => {  //创建菜单
        let submenuIndex = 0; //累计的每一项展开菜单索引
        let menu = [];
        if(!menuData){
          return ;
        }
        const create = (menuData, el) => {
            for (let i = 0; i < menuData.length; i++) {
                if (menuData[i].children && menuData[i].children.length>0 && menuData[i].menuType == 1 && menuData[i].children[0].menuType == 1) {

                    let children = [];
                    create(menuData[i].children, children);
                    submenuIndex++;
                    el.push(
                        <Nav.SubNav
                            icon={menuData[i].icon}
                            key={`sub${submenuIndex}`}
                            label={menuData[i].menuName}
                        >
                            {children}
                        </Nav.SubNav>
                    )
                } else {
                  if(menuData[i].menuType == 1){
                    el.push(
                      <Item key={menuData[i].menuRoute} title={menuData[i].menuName} icon={menuData[i].icon}
                      >
                        <Link onClick={jump.bind(this,menuData[i])} to={menuData[i].menuRoute}>
                          <span>{menuData[i].menuName}</span>
                        </Link>
                      </Item>
                    )
                  }

                }
            }

        };
        create(menuData, menu);
        return menu;
    })
export default menu
