
import http from './../../utils/api'

//退出登录
export function orgType(params) {
  return http('/sso/loginOut',{
      ...params
  }).then((res) => {
      return res;
    });
}
//获取菜单
export function getMenu(params) {
  return http('/elab-base/profile/user/dept/menuTree',{
      ...params
  }).then((res) => {
      return res;
    });
}
