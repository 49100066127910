import React, { Component } from 'react';
import { routerRedux } from 'dva/router';
import store from '../../index';
import { PubSub } from 'pubsub-js';

import { Nav, Icon, Button, Balloon, Dialog, Form, Input, Shell,Menu,Overlay,Switch,Notification} from "@alifd/next";
import './layout.scss';
import { connect } from 'dva';
import menu from './../Encapsulation/EnucapsulationMenu';
import hozMenu from './../horMenu/horMenu';
import http from './../../utils/api';
import {HN} from '../../utils/tools';
import {createWebSocket,closeWebSocket} from './websocket';
import moment from "moment";
const FormItem = Form.Item;
const { Popup } = Overlay;


class MessageAlert extends Component{
  componentDidMount(){
    this.msgList(true)
}
  constructor(props) {
    super(props);
    this.state ={
      msgListValue:[],
      detail:'',
      alertShow:false,
      checked: true,

    }
  }
  jump =()=> {
    const {dispatch} = store;
    this.setState({alertShow:false})
    dispatch(routerRedux.push('/notify/fullMessageList'))
  }
  onClose =()=>{
    this.setState({
      AlertInfoTitle:'',
      AlertInfoVisible:false,
      detail:''
    })
  }
  openIframe = (item) =>{
    console.log(item)
    let _this = this;
    http('/tools/user/message/mark/read',{id:item.id})
      .then(res => {
        console.log(res)
        if(res){

          _this.msgList(_this.state.checked)
          _this.setState({
            AlertInfoTitle:item.title,
            AlertInfoVisible:true,
            detail:window.location.origin+item.detailUrl
          })
        }
      })


    console.log(window.location.origin+item.detailUrl)
  }
  msgList = (readed) => {
    http('/tools/user/message/list',{
      readed:readed?0:'',
      pageCount:2000,
      pageNum:1,
      startDate:moment().subtract('0','days').format('YYYY-MM-DD'),
      endDate:moment().subtract('0','days').format('YYYY-MM-DD'),
    })
      .then(res => {
        this.setState({
          msgListValue:res.data
        })
      })
  }
  render(){
    const onChange = (checked) =>{
      console.log(checked)
      this.setState({
        checked:checked,
      })
      this.msgList(checked)

    }
    const list = this.state.msgListValue?this.state.msgListValue:[];
    const detail = this.state.detail
    return(
      <div className="message-menu">
      <a className="message-icon"><span className="icon iconfont" onClick={()=>this.setState({alertShow:!this.state.alertShow})} style={{marginRight:'40px',color:'#fff',fontSize:'30px'}}>&#xe63e;</span></a>
        {this.state.alertShow?<div className='message-select-after'></div>
        :''}
        {this.state.alertShow?
        <div className="message-select" id="message-select">
        <div className="title-menu">
          <a className="item-message active" style={{lineHeight:'35px'}}><span className="icon iconfont"  style={{color:'#fff',fontSize:'30px'}}>&#xe70f;</span></a>
          {/*<a className="collection  " style={{lineHeight:'35px'}}><span className="icon iconfont" style={{color:'#fff',fontSize:'30px'}}>&#xe73d;</span></a>*/}
          <div className="blank"></div>
          {/*<a className="set  " style={{lineHeight:'35px'}}><span className="icon iconfont" style={{color:'#fff',fontSize:'30px'}}>&#xe622;</span></a>*/}
        </div>
        <div className="message-detail">
          <div className="message-body">
            {
              list.map((item,i) => {
                return (<div className="day-message mb-10">
                  <div className="title-day mb-10"style={{display:i>0?list[i].sendDate==list[i-1].sendDate?'none':'':''}}>{moment(item.sendDate).format('YY-MM-DD')}</div>
                  <div className="content" onClick={()=>this.openIframe(item)}>
                    <div className="content-title">
                      <div className="msg-title">
                        <div style={{position: 'relative'}}>
                          {item.isRead == 0?<div className="dot"></div>:''}
                          <span className="red">{item.title}</span></div>
                      </div>
                      <div>
                        <div className="collect-icon">
                          {item.isStar==1?<i className="icon iconfont icon-shoucang1" style={{fontSize:'20px',color:'#ff7519'}}></i>
                          :<i className="icon iconfont icon-shoucang2" style={{fontSize:'20px',color:'#ff7519'}}></i>}
                        </div>
                        <span className="send-time">{item.sendTime}</span></div>
                    </div>
                    <div
                      style={{width: '242px', overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>{item.content}
                    </div>
                  </div>
                </div>)
              })}

            <div className="message-footer">
              <div className="display-f">
                <div className="mr-10">隐藏已读消息</div>
                <Switch onChange={onChange} checked={this.state.checked} aria-label="accessible switch"/>

                {/*<div role="switch" tabIndex="0" className="next-switch next-switch-on next-switch-medium"
                     aria-checked="true">
                  <div className="next-switch-children"></div>
                </div>*/}
              </div>
              <div className="mr-10" onClick={this.jump}>
                <button type="button" className="next-btn next-medium next-btn-primary next-btn-text" ><span
                  className="next-badge" title="2">查看全部消息</span>
                  {/*<sup className="next-badge-scroll-number next-badge-count"><span className="next-badge-scroll-number-only"><span>0</span><span>1</span><span>2</span><span>3</span><span>4</span><span>5</span><span>6</span><span>7</span><span>8</span><span>9</span><span>0</span><span>1</span><span>2</span><span>3</span><span>4</span><span>5</span><span>6</span><span>7</span><span>8</span><span>9</span><span>0</span><span>1</span><span>2</span><span>3</span><span>4</span><span>5</span><span>6</span><span>7</span><span>8</span><span>9</span></span></sup></span>*/}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>:''}
      <Dialog
        title={this.state.AlertInfoTitle}
        footer={false}
        visible={this.state.AlertInfoVisible}
        onClose={this.onClose}
      >
        <iframe
          src={detail}
          frameBorder="0"
          height="750px"
          width="600px"
          scrolling="auto"
        />
      </Dialog>

    </div>
    )
  }
}
let AlertNotice = ''
let detail ={}
class AlertNoticeWebsocket extends Component{
  constructor(props) {
    super(props)
  }
  onClose =()=> {
    this.props.onClose();
  }
  onRead =()=> {
    this.props.onRead(detail.id);
  }
  jump =()=> {
    this.props.jump(detail.detailUrl);
  }
  render(){
    return(
      <Dialog
        // title={AlertNotice}
        footer={false}
        visible={this.props.AlertNoticeVisible}
        onClose={this.onClose}
        width={'100px'}
      >
        <p style={{color:'red',fontSize:'18px'}}>{detail.title}</p>
        <p>{detail.content}</p>
        <div style={{marginTop:'10px',textAlign:'right'}}>
          <Button type="normal" onClick={this.onRead} style={{marginRight:'10px'}}>标记已读</Button>
          <Button type="normal" onClick={this.onClose} style={{marginRight:'10px'}}>忽略</Button>
          <Button type="primary" onClick={this.jump}>立刻前往</Button>
        </div>
      </Dialog>
    )
  }
}

let messageSocket;
class Layout extends Component {
  componentDidMount(){
    this.getPubTree()
    // this.sendMsg();
    let baseUrl = HN.configBaseURL().replace('https','')
    let url='wss'+baseUrl+'/tools/ws?access_token='+localStorage.getItem('token');//服务端连接的url
    createWebSocket(url);
    let _this = this;
    messageSocket = PubSub.subscribe('message', function (topic,message) {
      //message 为接收到的消息  这里进行业务处理
      console.log(message,'message')
      if(message.data){
        detail =message.data;
        AlertNotice = detail.title
        _this.setState({AlertNoticeVisible : true})
      }
    })
  }
  componentWillUnmount(){
    closeWebSocket();
    PubSub.unsubscribe(messageSocket);
  }
  static displayName = 'Layout';
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      popupAlign: "follow",
      queryKey:'',
      deptTree:[],
      indexItem:'',
      searchTree:[],
      searchTree1:[],
      deptName:'',
      isHoz:localStorage.getItem('menu_style')==1?false:true,//true横向菜单
      menuTree:[],
      allMenu:false,
      AlertNoticeVisible:false,
      openAlertNoticeVisible:false,
    };
  }
  changePub = () => {
    this.setState({
      isShow:!this.state.isShow,
      indexItem:''
    })
  }
  clickAll = () => {
    this.setState({
      allMenu:!this.state.allMenu,
      indexItem:''
    })
  }
  paramsChangeId = (value) => {

    let arr = this.state.searchTree1.filter(val => {
      // console.log(val.deptName,'1')
      // console.log(val.deptName.match(value))
      return val.deptName.match(value);
    })
    console.log(arr)
    this.setState({
      queryKey:value,
      searchTree:arr
    })
  }
  getHomePage = (value) =>{
    let _this = this;
    http('/elab-base/profile/user/dept/menuTree',{id:value})
      .then(res => {
        let menuBtnList = [];
        const getBtn =(arr)=> {
          for(let items of arr){
            if(items.children && items.children.length > 0){
              getBtn(items.children)
            }else{
              if(items.menuType == 2){
                menuBtnList.push(items.menuCode)
              }
            }
          }
        }
        getBtn(res);
        _this.setState({
          menuTree:res,
        })
        localStorage.setItem('btn',JSON.stringify(menuBtnList))

      })
  }

  getPubTree = () => {
    let _this = this;
    http('/elab-base/profile/user/role/deptTree',{})
      .then(res => {
        if(res.length==0){
          _this.getHomePage(0)
        }
        _this.setState({
          deptTree:res,
          searchTree:this.searchTree(res),
          searchTree1:this.searchTree(res),
        })
      })
  }
  sendMsg = () => {
    let _this = this;
    http('/tools/profile/send/template',{
      "userId":79,
      "sampleNo":"123",
      "testItemName":"血常规",
      "templateNo": "tm001",
      "result":"14g/ml",
      "criticalRange":"20-30g/ml"
    })
      .then(res => {
        // console.log(res)
      })
  }

  searchTree(value){
    let newArr = [];
    const getTree = (val) => {
      if(val&&val.length>0){
        val.map(v => {
          if(v.select){
            newArr.push(v)
          }
          if(v.defSelect){
            this.getHomePage(v.id)
            this.setState({
              deptName:v.deptName
            })
          }
          if(v.children.length > 0){
            getTree(v.children)
          }
        })
      }

    }
    getTree(value)
    return newArr
  }
  logOut = () => {
    this.props.dispatch({ type: 'app/logOut' });
  }
  jump = (value) => {
    this.setState({
      allMenu:false
    })
    this.props.dispatch({ type: 'app/jump',payload: { value } });
  }
  changePasswordClick = () => {
    this.props.dispatch({ type: "app/changePasswordClick" })
  }
  onClose = () => {
    this.props.dispatch({ type: "app/onClosePassword" })
  }
  onSave = () => {
    this.props.dispatch({ type: "app/passwordOnsave" })
  }
  clickTab = (value,item) => {
    if(item.select){
      this.setState({
        deptName:item.deptName,
        isShow:false
      })
      this.getHomePage(item.id)
      this.props.dispatch(routerRedux.push('/home'))
      return ;
    }
    this.setState({
      indexItem:value
    })
  }
  getTableHeight = (n = 0) => {
    const height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - n;
    return height;
  }
  onRead =(id)=> {
    http('/tools/user/message/mark/read',{id:id})
      .then(res => {
        this.setState({AlertNoticeVisible:false})
      })
  }
  onClick =()=> {
    this.setState({AlertNoticeVisible:false})
  }
  onCloseOpen =()=> {
    this.setState({openAlertNoticeVisible:false})
  }
  openJump =(url)=> {
    this.setState({
      openAlertNoticeVisible:true,
      openAlertNoticeUrl:url,
      AlertNoticeVisible:false
    })
  }
  render() {
    const Content = () => (
      <div>
        <h4 onClick={this.changePasswordClick} style={{ marginTop: 0 }}>修改密码</h4>
        <h4 onClick={this.logOut}>退出登录</h4>
      </div>
    );

    const header = <span className="fusion">云list</span>;

    const footer = <span className="login-in" >
      {/*<span className="icon iconfont" style={{marginRight:'40px',color:'#fff',fontSize:'30px'}}>&#xe63e;</span>*/}
      <Icon type="account" size="small" style={{ marginRight: '10px' }} />
      <Balloon className='balloonStyle' trigger={localStorage.getItem('username')} align="br" alignEdge closable={false} triggerType="click" >
        <Content />
      </Balloon>
    </span>;

    let arrTree = this.state.queryKey?this.state.searchTree:this.state.deptTree

    return (
      // 垂直
      <div style={{height:'100%'}} >
        <Shell className={"iframe-hack"} device={this.state.device} type={"brand"} >
          <Shell.Branding>
            {this.state.isHoz?'':<div className="rectangular"></div>}
            {header}
            {this.state.isHoz?
              <div className="hozMenu">
                <Menu hozInLine direction="hoz" mode="popup" className="my-hoz-menu" popupClassName="my-hoz-menu" popupAutoWidth>
                  {hozMenu(this.state.menuTree)}
                </Menu>

                {this.state.menuTree.length>6?
                  <div className={'last-menu'} onClick={this.clickAll}>全部菜单</div>:''
                }

              </div>:''}
          </Shell.Branding>

          <Shell.Navigation direction="hoz">
            <div>
            <MessageAlert/>
            <AlertNoticeWebsocket
              onRead = {this.onRead}
              onClose = {this.onClick}
              jump = {this.openJump}
              AlertNoticeVisible = {this.state.AlertNoticeVisible}
            />
              <Dialog
                // title={this.state.AlertInfoTitle}
                footer={false}
                visible={this.state.openAlertNoticeVisible}
                onClose={this.onCloseOpen}
              >
                <iframe
                  src={this.state.openAlertNoticeUrl}
                  frameBorder="0"
                  height="750px"
                  width="600px"
                  scrolling="auto"
                />
              </Dialog>
            {this.state.deptTree.length>0?
              <div className="newchange-location">
                <div className="newchange-location-menu"  >
                  <div className="newchange-location-menu-title">
                    <div className="ell-box" onClick={this.changePub}>{this.state.deptName?this.state.deptName:1}</div>
                    <div className="menu-arrow"></div>
                  </div>
                  {
                    this.state.isShow?<div className="newchange-location-menu-cont" >
                        <ul className="newchange-location-menu-tree">
                          <Input hasClear
                                 placeholder="请输入"
                                 maxLength={60}
                                 value={this.state.queryKey}
                                 onChange={this.paramsChangeId}/>
                          {
                            arrTree.map((items,index) => {
                              return (
                              <li>
                                <div className="menu-name" onClick={this.clickTab.bind(this,index,items)}>{items.deptName}</div>

                                {index === this.state.indexItem ?

                                  <ul className="menu-leafNode">

                                    {
                                      items.children.length > 0 ?
                                        items.children.map(item => {
                                          return (
                                            <li>
                                              <div className="menu-name" onClick={this.clickTab.bind(this,index,item)}>{item.deptName}</div>
                                            </li>
                                          )
                                        })
                                        : ''
                                    }

                                  </ul>
                                  :''
                                }
                              </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      :''
                  }

                </div>
              </div>
              :''
            }

            {/*{selectPub}*/}
            {/*<Search key="2" shape="simple" type="dark" palceholder="Search" style={{width: '200px'}}/>*/}
            </div>
          </Shell.Navigation>
          <Shell.Action>
            {footer}
          </Shell.Action>

          {this.state.isHoz?'':<Shell.Navigation>
            <Nav embeddable  aria-label="global navigation">
              {menu(this.state.menuTree)}
            </Nav>
          </Shell.Navigation>}
          <Shell.Content>
            <div className="contanintStyle">
              <div className="layoutContainerH" >
                {this.props.children || 'Welcome to your labBasis'}
              </div>
              {this.state.allMenu?
                <div className="all-menu" >
                  <div className="left-side"><a>全部菜单</a></div>
                  <div className="main-body">
                    {/*<div className="search-menu">
                  <span className="next-input next-medium">
                    <Input placeholder="搜索" height="100%" autoComplete="off" value=""/>
                  </span><a><i type="guanbi-smoll"></i></a></div>*/}
                    <ul className="all-menu-list">

                      {this.state.menuTree.length>0?
                        this.state.menuTree.map(items => {
                          return (
                            <dl>
                              <dt><a style={{'color':'#333',fontSize:'14px'}} onClick={this.jump.bind(this,items)} rel="noopener noreferrer" target="_self">
                                <i></i>{items.menuName}</a></dt>
                              {
                                items.children!=null?
                                items.children.map(item => {
                                  return (
                                    <dd title={item.menuName}>
                                      <a onClick={this.jump.bind(this,item)}  rel="noopener noreferrer" target="_self">
                                        <i></i>{item.menuName}</a></dd>
                                  )
                                })
                                  :''
                              }
                            </dl>
                          )
                        }):''
                      }
                    </ul>
                  </div>
                </div>
                :''
              }

            </div>

          </Shell.Content>
        </Shell>

          {/*{this.state.isShow ?
          <div style={{height:'100%'}}>
          <div>
            <Nav
              className="basic-nav"
              direction="hoz"
              type="primary"
              header={header}
              footer={footer}
              defaultSelectedKeys={['home']}
              triggerType="hover" />
          </div>
          <div className={'container'}>

             菜单
            style={{ height: this.getTableHeight(50) + 'px' }}
            <div className="menulistStyle" style={{ height: '100%' }}>
              <div className="layoutVerDv">
                    <Menu defaultOpenKeys="1" className="my-menu" openMode="single" onItemClick={this.openClick}>
                    {menu(this.menuList)}
                  </Menu>

              </div>
            </div>
             页面内容
            <div className="contanintStyle">
              <div className="layoutContainerH" >
                {this.props.children || 'Welcome to your labBasis'}
              </div>
            </div>
          </div>
          </div>
          :
          // 水平
          <div >
             菜单
            <div >
              <Nav className="basic-nav"
                direction="hoz"
                type="primary"
                header={header}
                footer={footer}
                defaultSelectedKeys={['home']}
                triggerType="hover">
                {menu(this.menuList)}
              </Nav>
            </div>
             页面内容
            <div className="layoutContainerH" >
              {this.props.children || 'Welcome to your labBasis'}
            </div>

          </div>
        }*/}
        {/* 修改密码 */}
        <div>
          <Dialog
            title="修改密码"
            footer={false}
            visible={this.props.app.visible}
            onClose={this.onClose}
            className="tatAngencydataInfoDialog"
            style={{ width: '500px' }}
          >
            <Form inline field={this.field} className="tatAngencydataInfoAddForm">
              <FormItem
                label="原始密码:"
                required
                requiredMessage="原始密码不能为空"
                style={{ marginLeft: '12px' }}
              >
                <Input placeholder="原始密码" name="tatName" maxLength={60} style={{ width: '260px' }} />
              </FormItem>
              <FormItem
                label="新密码:"
                required
                requiredMessage="新密码不能为空"
                patternMessage="请输入6-20个字符"
                pattern={/^(?!bai[0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/}
                style={{ marginLeft: '24px' }}
              >
                <Input placeholder="新密码" name="tatCode" maxLength={20} style={{ width: '260px' }} />
              </FormItem>
              <FormItem
                label="确认新密码:"
                required
                requiredMessage="确认新密码不能为空"
                patternMessage="确认新密码"
              >
                <Input placeholder="确认新密码" name="tatCode2" maxLength={128} style={{ width: '260px' }} />
              </FormItem>
              <FormItem style={{ width: '100%', marginBottom: '0', marginTop: '15px', textAlign: 'center' }}>
                <Form.Submit validate type="primary" onClick={this.onSave} style={{ margin: '0 10px' }}>保存</Form.Submit>
                <Button type="normal" onClick={this.onClose}>关闭</Button>
              </FormItem>
            </Form>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, Layout }) => ({ app, Layout })
export default connect(mapStateToProps)(Layout)