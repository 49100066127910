const languageType = [
    {
        code: 'TITLE-SSXMZHWH',
        zh: '申请项目组合维护',
        en: 'Application Portfolio Maintenance',
        French: 'jjj'
    },
    {
        code: 'TITLE-GZFL',
        zh: '规则分类',
        en: 'Rule Classification',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZKGZ',
        zh: '质控规则',
        en: 'Quality Control Rules',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZKGZZH',
        zh: '质控规则组合',
        en: 'Combination Of Quality Control Rules',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZKGZZHMX',
        zh: '质控规则组合明细',
        en: 'Combination Details Of Quality Control Rules',
        French: 'jjj'
    },
    {
        code: 'TITLE-MZTMDY',
        zh: '门诊条码打印',
        en: 'Outpatient Bar Code Printing',
        French: 'jjj'
    },
    {
        code: 'TITLE-MZBBLB',
        zh: '门诊标本列表',
        en: 'Outpatient Specimen List',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZYTMDY',
        zh: '住院条码打印',
        en: 'Inpatient Bar Code Printin',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZKJH',
        zh: '质控计划',
        en: 'Quality Control Plan',
        French: 'jjj'
    },
    {
        code: 'TITLE-ZYBBYB',
        zh: '住院标本列表',
        en: 'List Of Inpatient Specimens',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYLCSZ',
        zh: '检验流程设置',
        en: 'Inspection Process Settings',
        French: 'jjj'
    },
    {
        code: 'TITLE-BGDSZ',
        zh: '报告单设置',
        en: 'Report Form Setting',
        French: 'jjj'
    },
    {
        code: 'TITLE-YQGL',
        zh: '仪器管理',
        en: 'Instrument Management',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYXMGL',
        zh: '检验项目管理',
        en: 'Inspection Project Management',
        French: 'jjj'
    },

    {
        code: 'TITLE-SQXMWH',
        zh: '申请项目维护',
        en: 'Application Project Maintenance',
        French: 'jjj'
    },
    {
        code: 'TITLE-SQXMFZGZWH',
        zh: '申请项目分组规则维护',
        en: 'Application Item Grouping Rule Maintenance',
        French: 'jjj'
    },
    {
        code: 'TITLE-BGSJGZSZ',
        zh: '报告时间规则设置',
        en: 'Report Time Rule Settings',
        French: 'jjj'
    },
    {
        code: 'TITLE-BGSJ',
        zh: '报告时间',
        en: 'Reporting Time',
        French: 'jjj'
    },
    {
        code: 'TITLE-SYSGL',
        zh: '实验室管理',
        en: 'Laboratory Management',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYKS',
        zh: '检验科室',
        en: 'Laboratory Department',
        French: 'jjj'
    },
    {
        code: 'TITLE-YQ',
        zh: '仪器',
        en: 'Instrument',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYPT',
        zh: '检验平台',
        en: 'Inspection Platform',
        French: 'jjj'
    },
    {
        code: 'TITLE-XJXX',
        zh: '细菌信息',
        en: 'Bacterial Information',
        French: 'jjj'
    },
    {
        code: 'TITLE-YQTD',
        zh: '仪器通道',
        en: 'Instrument Channel',
    },
    {
        code: 'TITLE-ZYBBABCCX',
        zh: '住院标本按批次查询',
        en: 'In Hospital Specimen Inquiry By Batch',
        French: 'jjj'
    },

    {
        code: 'TITLE-KSSXX',
        zh: '抗生素信息',
        en: 'Antibiotic Information',
        French: 'jjj'
    },
    {
        code: 'TITLE-KSSJYBTJ',
        zh: '科室送检样本统计',
        en: 'Statistics Of Samples Submitted By Departments',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYZGZYBLTJ',
        zh: '检验者工作样本量统计',
        en: 'Statistics Of Working Sample Size Of Examiners',
        French: 'jjj'
    },
    {
        code: 'TITLE-BHGBBS',
        zh: '不合格标本数',
        en: 'Number Of Unqualified Specimens',
        French: 'jjj'
    },
    {
        code: 'TITLE-SQXMLTJ',
        zh: '申请项目量统计',
        en: 'Statistics Of Applied Projects',
        French: 'jjj'
    },
    {
        code: 'TITLE-JYXMLTJ',
        zh: '检验项目量统计',
        en: 'Statistics Of Inspection Items',
        French: 'jjj'
    },
    {  
        code:'TITLE-BBHS',
        zh:'标本核收',
        en:'Specimen Collection',
        French:'jjj'
    },
    {
        code:'TITLE-BBJJ',
        zh:'标本交接',
        en:'Specimen Handover',
        French:'jjj'
    },
    {
        code:'TITLE-KSSZXX',
        zh:'抗生素组信息',
        en:'Antibiotic Group Information',
        French:'jjj'
    },
    {
        code:'TITLE-YHSJWH',
        zh:'用户数据维护',
        en:'User Data Maintenance',
        French:'jjj'
    },
    {
        code:'TITLE-ZDSHGZ',
        zh:'自动审核规则',
        en:'Automatic Audit Rules',
        French:'jjj'
    },
    {
        code:'TITLE-CDGL',
        zh:'菜单管理',
        en:'Menu Management',
        French:'jjj'
    },
    {
        code:'TITLE-ZHGL',
        zh:'租户管理',
        en:'Tenant Management',
        French:'jjj'
    },
    {
        code:'TITLE-ZHCD',
        zh:'租户菜单',
        en:'Tenant Menu',
        French:'jjj'
    },
    {
        code:'TITLE-ZDGL',
        zh:'字典管理',
        en:'Dictionary Management',
        French:'jjj'
    },
    {
        code:'TITLE-GZGL',
        zh:'规则管理',
        en:'Rule Management',
        French:'jjj'
    },
    {
        code:'TITLE-TATFA',
        zh:'TAT方案',
        en:'Tat Scheme',
        French:'jjj'
    },
    {
        code:'TITLE-JYPTGZYBLTJ',
        zh:'检验平台工作样本量统计',
        en:'Statistics Of Working Sample Size Of Test Platform',
        French:'jjj'
    },
    {
        code:'TITLE-KSSJSQXMLTJ',
        zh:'科室送检申请项目量统计',
        en:'Statistics Of Application Items Submitted By Departments',
        French:'jjj'
    },
    {
        code:'TITLE-KSSJJYXMLTJ',
        zh:'科室送检检验项目量统计',
        en:'Statistics Of Inspection Items Submitted By Departments',
        French:'jjj'
    },
    {
        code:'TITLE-SHZGZYBLTJ',
        zh:'审核者工作样本量统计',
        en:'Auditor Work Sample Size Statistics',
        French:'jjj'
    },
    {
        code:'TITLE-TATTJ',
        zh:'TAT统计',
        en:'TAT Statistics',
        French:'jjj'
    },
  
  { 
        code:'TITLE-YCBB',
        zh:'异常标本',
        en:'Abnormal Specimen',
        French:'jjj'
    },
  // 2020/12/22  翻译更新截止代码

  { 
        code:'TITLE-LXGL',
        zh:'类型管理',
        en:'Type Management',
        French:'jjj'
    },
    { 
        code:'TITLE-XMKGL',
        zh:'项目库管理',
        en:'Project Library Management',
        French:'jjj'
    },
    { 
        code:'TITLE-XJXXWH',
        zh:'细菌信息维护',
        en:'Bacterial Information Maintenance',
        French:'jjj'
    },
    { 
        code:'TITLE-KSSXXGL',
        zh:'抗生素信息管理',
        en:'Antibiotic Information Management',
    },
    { 
        code:'TITLE-WAFY',
        zh:'文案翻译',
        en:'Document Translation',
        French:'jjj'
    },
    { 
        code:'TITLE-CXSTMDY',
        zh:'采血室条码打印',
        en:'Bar Code Printing In Blood Collection Room',
        French:'jjj'
    },
    { 
        code:'TITLE-DJPZ',
        zh:'对接配置',
        en:'Docking Configuration',
        French:'jjj'
    },
    { 
        code:'TITLE-APIXMDY',
        zh:'Api项目对应',
        en:'API Project Correspondence',
        French:'jjj'
    },
    { 
        code:'TITLE-JGYH',
        zh:'机构用户',
        en:'Institutional Users',
        French:'jjj'
    },
];

export default {
    languageType
}