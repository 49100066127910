const languageType = [
        {
            en: "Query",
            code: "Button-CX",
            French: "aaa",
            zh: "查询"
        },
        {
            en: "Close",
            code: "Button-GB",
            French: "aaa",
            zh: "关闭"
        },
        {
            en: "Add Superior",
            code: "Button-TJSJ",
            French: "aaa",
            zh: "添加上级"
        },
        {
            en: "Add Subordinate",
            code: "Button-TJXJ",
            French: "aaa",
            zh: "添加下级"
        },
        {
            en: "Test Fallback",
            code: "Button-JYHT",
            French: "aaa",
            zh: "检验回退"
        },
        {
            en: "Verification",
            code: "Button-YZ",
            French: "aaa",
            zh: "验证"
        },
        {
            en: "Generate",
            code: "Button-ShenCheng",
            French: "aaa",
            zh: "生成"
        },
        {
            en: "Related Functions",
            code: "Button-GLGN",
            French: "aaa",
            zh: "关联功能"
        },
        {
            en: "Reset",
            code: "Button-CZ",
            French: "bbb",
            zh: "重置"
        },
        {
            en: "Generating Rules",
            code: "Button-SCGZ",
            French: "bbb",
            zh: "生成规则"
        },
        {
            en: "New Registration",
            code: "Button-XZDJ",
            French: "bbb",
            zh: "新增登记"
        },
        {
            en: "Modify",
            code: "Button-XG",
            French: "bbb",
            zh: "修改"
        },
        {
            en: "New Application Items",
            code: "Button-XZSQXM",
            French: "bbb",
            zh: "新增申请项目"
        },
        {
            en: "Determine",
            code: "Button-QD",
            French: "bbb",
            zh: "确定"
        },
        {
            en: "Cancel",
            code: "Button-QX",
            French: "bbb",
            zh: "取消"
        },
        {
            en: "Sign In",
            code: "Button-DL",
            French: "ccc",
            zh: "登录"
        },
        {
            en: "Language Choice",
            code: "Button-YYXZ",
            French: "ddd",
            zh: "语言选择"
        },
        {
            en: "Newly Added",
            code: "Button-XZ",
            French: "jjj",
            zh: "新增"
        },
        {
            en: "The Previous",
            code: "Button-SYG",
            French: "jjj",
            zh: "上一个"
        },
        {
            en: "Next",
            code: "Button-XYG",
            French: "jjj",
            zh: "下一个"
        },
        {
            en: "Click To Upload The Report Form Template",
            code: "Button-DJSCBGDMB",
            French: "jjj",
            zh: "点击上传报告单模板"
        },
        {
            en: "Import Instrument From Platform",
            code: "Button-CPTDRYQ",
            French: "jjj",
            zh: "从平台导入仪器"
        },
        {
            en: "Import Project",
            code: "Button-DRXM",
            French: "jjj",
            zh: "导入项目"
        },
        {
            en: "Import Inspection Items",
            code: "Button-JYXMDR",
            French: "jjj",
            zh: "检验项目导入"
        },
        {
            en: "Application Project Import",
            code: "Button-SQXMDR",
            French: "jjj",
            zh: "申请项目导入"
        },
        {
            en: "Import Platform Project",
            code: "Button-DRPTXM",
            French: "jjj",
            zh: "导入平台项目"
        },
        {
            en: "New Role",
            code: "Button-XZJS",
            French: "jjj",
            zh: "新增角色"
        },
        {
            en: "Role Assignment",
            code: "Button-JSFP",
            French: "jjj",
            zh: "角色分配"
        },
        {
            en: "Edit",
            code: "Button-BJ",
            French: "jjj",
            zh: "编辑"
        },
        {
            en: "Delete",
            code: "Button-SC",
            French: "jjj",
            zh: "删除"
        },
        {
            en: "Delete Role",
            code: "Button-SCJS",
            French: "jjj",
            zh: "删除角色"
        },
        {
            en: "Batch Deletion",
            code: "Button-PLSC",
            French: "jjj",
            zh: "批量删除"
        },
        {
            en: "Batch Distribution",
            code: "Button-PLFP",
            French: "jjj",
            zh: "批量分配"
        },
        {
            en: "Import Details",
            code: "Button-DRMX",
            French: "jjj",
            zh: "导入明细"
        },
        {
            en: "Import Details Of Selection",
            code: "Button-DRXZDMX",
            French: "jjj",
            zh: "导入选择的明细"
        },
        {
            en: "New Rule Combination",
            code: "Button-XZGZZH",
            French: "jjj",
            zh: "新增规则组合"
        },
        {
            en: "Import Inspection Items",
            code: "Button-DRJYXM",
            French: "jjj",
            zh: "导入检验项目"
        },
        {
            en: "Import Inspection Platform",
            code: "Button-DRJYPT",
            French: "jjj",
            zh: "导入检验平台"
        },
        {
            en: "Import Of Inspection Platform",
            code: "Button-JYPTDR",
            French: "jjj",
            zh: "检验平台导入"
        },
        {
            en: "Enable",
            code: "Button-QY",
            French: "jjj",
            zh: "启用"
        },
        {
            en: "Out Of Service",
            code: "Button-TY",
            French: "jjj",
            zh: "停用"
        },
        {
            en: "New Parameter",
            code: "Button-XZCS",
            French: "jjj",
            zh: "新增参数"
        },
        {
            en: "New Project Library",
            code: "Button-XZXMK",
            French: "jjj",
            zh: "新增项目库"
        },
        {
            en: "Application For New Projects",
            code: "Button-XZXMSQ",
            French: "jjj",
            zh: "新增项目申请"
        },
        {
            en: "New Instruments",
            code: "Button-XZYQ",
            French: "jjj",
            zh: "新增仪器"
        },
        {
            en: "New Users",
            code: "Button-XZYH",
            French: "jjj",
            zh: "新增用户"
        },
        {
            en: "See",
            code: "Button-CK",
            French: "jjj",
            zh: "查看"
        },
        {
            en: "Authority Assignment",
            code: "Button-QXFP",
            French: "jjj",
            zh: "权限分配"
        },
        {
            en: "I Got it!",
            code: "Button-WZDL",
            French: "jjj",
            zh: "我知道了"
        },
        {
            en: "Merge Barcode",
            code: "Button-HBTM",
            French: "jjj",
            zh: "合并条码"
        },
        {
            en: "Barcode Printer",
            code: "Button-TMDYJ",
            French: "jjj",
            zh: "条码打印机"
        },
        {
            en: "Receipt Printer",
            code: "Button-HZDYJ",
            French: "jjj",
            zh: "回执打印机"
        },
        {
            en: "Retype Barcode",
            code: "Button-CDTM",
            French: "jjj",
            zh: "重打条码"
        },
        {
            en: "Print Barcode",
            code: "Button-DYTM",
            French: "jjj",
            zh: "打印条码"
        },
        {
            en: "Patient Selection",
            code: "Button-XZHZ",
            French: "jjj",
            zh: "选择患者"
        },
        {
            en: "Card Reading",
            code: "Button-DK",
            French: "jjj",
            zh: "读卡"
        },
        {
            en: "New Combination",
            code: "Button-XZZH",
            French: "jjj",
            zh: "新增组合"
        },
        {
            en: "Regular Expression Statement",
            code: "Button-GZBDSYJ",
            French: "jjj",
            zh: "规则表达式语句"
        },
        {
            en: "Regular Expression Description Statement",
            code: "Button-GZBDSMSYJ",
            French: "jjj",
            zh: "规则表达式描述语句"
        },
        {
            en: "Edit Expression",
            code: "Button-BJBDS",
            French: "jjj",
            zh: "编辑表达式"
        },
        {
            en: "New Antibiotic Group",
            code: "Button-XZKSSZ",
            French: "jjj",
            zh: "新增抗生素组"
        },
        {
            en: "Import Selected Instrument",
            code: "Button-DRXZDYQ",
            French: "jjj",
            zh: "导入选择的仪器"
        },
        {
            en: "Confirm Receipt",
            code: "Button-QRJS",
            French: "jjj",
            zh: "确认接收"
        },
        {
            en: "Refresh",
            code: "Button-ShuaXin",
            French: "jjj",
            zh: "刷新"
        },
        {
            en: "Preservation",
            code: "Button-BC",
            French: "jjj",
            zh: "保存"
        },
        {
            en: "New Group",
            code: "Button-XZFZ",
            French: "jjj",
            zh: "新增分组"
        },
        {
            en: "New Quality Control Plan",
            code: "Button-XZZKJH",
            French: "jjj",
            zh: "新增质控计划"
        },
        {
            en: "Import Automatic Audit Rules",
            code: "Button-DRZDSHGZ",
            French: "jjj",
            zh: "导入自动审核规则"
        },
        {
            en: "Import",
            code: "Button-DR",
            French: "jjj",
            zh: "导入"
        },
        {
            en: "Print Report",
            code: "Button-DYBG",
            French: "jjj",
            zh: "打印报告"
        },
        {
            en: "Print Settings",
            code: "Button-DYSZ",
            French: "jjj",
            zh: "打印设置"
        },
        {
            en: "Upload Pictures",
            code: "Button-SCTP",
            French: "jjj",
            zh: "上传图片"
        },
        {
            en: "Printing",
            code: "Button-DY",
            French: "jjj",
            zh: "打印"
        },
        {
            en: "Inspection And Acceptance",
            code: "Button-JYJS",
            French: "jjj",
            zh: "检验接收"
        },
        {
            en: "Select Platform",
            code: "Button-XZPT",
            French: "jjj",
            zh: "选择平台"
        },
        {
            en: "List Printing",
            code: "Button-QDDY",
            French: "jjj",
            zh: "清单打印"
        },
        {
            en: "Retype Barcode",
            code: "Button-CDTM",
            French: "jjj",
            zh: "重打条码"
        },
        {
            en: "New Departments",
            code: "Button-XZBM",
            French: "jjj",
            zh: "新增部门"
        },
        {
            en: "Query Sample",
            code: "Button-CXYB",
            French: "jjj",
            zh: "查询样本"
        },
        {
            en: "Generate New Results",
            code: "Button-SCXJG",
            French: "jjj",
            zh: "生成新结果"
        },
        {
            en: "Update Original Results",
            code: "Button-GXYJG",
            French: "jjj",
            zh: "更新原结果"
        },
        {
            en: "Confirm",
            code: "Button-QR",
            French: "jjj",
            zh: "确认"
        },
        {
            en: "Remove User ",
            code: "Button-YCYH",
            French: "jjj",
            zh: "移除用户"
        },
        {
            en: "Invalid Barcode",
            code: "Button-ZFTM",
            French: "jjj",
            zh: "作废条码"
        },
        {
            en: "Operation Return",
            code: "Button-CZTH",
            French: "jjj",
            zh: "操作退回"
        },
        {
            en: "Inspection Refund",
            code: "Button-JYTF",
            French: "jjj",
            zh: "检验退费"
        },
        {
            en: "Partial Refund",
            code: "Button-BFTF",
            French: "jjj",
            zh: "部分退费"
        },
        {
            en: "Batch Check Selected",
            code: "Button-PLHDYX",
            French: "jjj",
            zh: "批量核对已选"
        },
        {
            en: "The Specimen Was Sent Out",
            code: "Button-BBSC",
            French: "jjj",
            zh: "标本送出"
        },
        {
            en: "Batch Confirm Selected",
            code: "Button-PLQRYX",
            French: "jjj",
            zh: "批量确认已选"
        },
        {
            en: "Sampling Confirmation",
            code: "Button-CYQR",
            French: "jjj",
            zh: "采样确认"
        },
        {
            en: "Print List",
            code: "Button-DYQD",
            French: "jjj",
            zh: "打印清单"
        },
        {
            en: "New Quality Control Results",
            code: "Button-XZZKJG",
            French: "jjj",
            zh: "新增质控结果"
        },
        {
            en: "Return Field",
            code: "FHZD",
            French: "jjj",
            zh: "返回字段"
        },
        {
            en: "Filtering SQL",
            code: "GLSQL",
            French: "jjj",
            zh: "过滤SQL"
        },
        {
            en: "Global System Parameters: Tenantid (Tenant ID), Orgid (Organization ID), Userid (User ID)",
            code: "QJXTCSID",
            French: "jjj",
            zh: "全局系统参数:tenantId(租户ID)、orgId(机构ID)、userId(用户ID)"
        },
        {
            en: "Superior Function",
            code: "SJGN",
            French: "jjj",
            zh: "上级功能"
        },
        {
            en: "Parameter Type",
            code: "CSLX",
            French: "jjj",
            zh: "参数类型"
        },
        {
            en: "Parameter Coding",
            code: "CSBM",
            French: "jjj",
            zh: "参数编码"
        },
        {
            en: "Parameter Name",
            code: "CSMC",
            French: "jjj",
            zh: "参数名称"
        },
        {
            en: "Default Value",
            code: "MRZ",
            French: "jjj",
            zh: "默认值"
        },
        {
            en: "Parameter Code Cannot Be Empty",
            code: "CSBMBNWK",
            French: "jjj",
            zh: "参数编码不能为空"
        },
        {
            en: "The Default Value Cannot Be Empty",
            code: "MRZBNWK",
            French: "jjj",
            zh: "默认值不能为空"
        },
        {
            en: "Parameter Type Cannot Be Empty",
            code: "CSLXBNWK",
            French: "jjj",
            zh: "参数类型不能为空"
        },
        {
            en: "Function Name Cannot Be Empty",
            code: "GNMCBNWK",
            French: "jjj",
            zh: "功能名称不能为空"
        },
        {
            en: "Function Code Cannot Be Empty",
            code: "GNBMBNWK",
            French: "jjj",
            zh: "功能编码不能为空"
        },
        {
            en: "Rule Name Cannot Be Empty",
            code: "GZMCBNWK",
            French: "jjj",
            zh: "规则名称不能为空"
        },
        {
            en: "Interface Name Cannot Be Empty",
            code: "JKMCBNWK",
            French: "jjj",
            zh: "接口名称不能为空"
        },
        {
            en: "Interface Code Cannot Be Empty",
            code: "JKBMBNWK",
            French: "jjj",
            zh: "接口编码不能为空"
        },
        {
            en: "Interface Path Cannot Be Empty",
            code: "JKLJBNWK",
            French: "jjj",
            zh: "接口路径不能为空"
        },
        {
            en: "Interface Type Cannot Be Empty",
            code: "JKLXBNWK",
            French: "jjj",
            zh: "接口类型不能为空"
        },
        {
            en: "Dictionary Name Cannot Be Empty",
            code: "ZDMCBNWK",
            French: "jjj",
            zh: "字典名称不能为空"
        },
        {
            en: "Real Name Cannot Be Empty",
            code: "ZSXMBNWK",
            French: "jjj",
            zh: "真实姓名不能为空"
        },
        {
            en: "Please Enter The Correct Contact Information",
            code: "QSRZQDLXFS",
            French: "jjj",
            zh: "请输入正确的联系方式"
        },
        {
            en: "Dictionary Code Cannot Be Empty",
            code: "ZDBMBNWK",
            French: "jjj",
            zh: "字典编码不能为空"
        },
        {
            en: "Data Type Cannot Be Empty",
            code: "SJLXBNWK",
            French: "jjj",
            zh: "数据类型不能为空"
        },
        {
            en: "Code Cannot Be Empty",
            code: "BMBNWK",
            French: "jjj",
            zh: "编码不可以为空"
        },
        {
            en: "Tenant Name Cannot Be Empty",
            code: "ZHMCBNWK",
            French: "jjj",
            zh: "租户名称不能为空"
        },
        {
            en: "Tenant Domain Name Cannot Be Empty",
            code: "ZHYMBNWK",
            French: "jjj",
            zh: "租户域名不能为空"
        },
        {
            en: "Menu Name Cannot Be Empty",
            code: "CDMCBNWK",
            French: "jjj",
            zh: "菜单名称不能为空"
        },
        {
            en: "Menu Code Cannot Be Empty",
            code: "CDBMBNWK",
            French: "jjj",
            zh: "菜单编码不能为空"
        },
        {
            en: "Report Time Rule Settings",
            code: "BGSJGZSZ",
            French: "jjj",
            zh: "报告时间规则设置"
        },
        {
            en: "Preliminary Trial",
            code: "Button-CS",
            French: "jjj",
            zh: "测试"
        },
        {
            en: "To Examine",
            code: "Button-SH",
            French: "jjj",
            zh: "审核"
        },
        {
            en: "Test Rules",
            code: "Button-CSGZ",
            French: "jjj",
            zh: "测试规则"
        },
        {
            en: "Detection Time Description",
            code: "JCSJMX",
            French: "jjj",
            zh: "检测时间描述"
        },
        {
            en: "New Combination",
            code: "Button-XZZH",
            French: "jjj",
            zh: "新增组合"
        },
        {
            en: "Regular Expression Statement",
            code: "Button-GZBDSYJ",
            French: "jjj",
            zh: "规则表达式语句"
        },
        {
            en: "Regular Expression Description Statement",
            code: "Button-GZBDSMSYJ",
            French: "jjj",
            zh: "规则表达式描述语句"
        },
        {
            en: "Edit Expression",
            code: "Button-BJBDS",
            French: "jjj",
            zh: "编辑表达式"
        },
        {
            en: "Upload Electronic Signature",
            code: "Button-SCDZQM",
            French: "jjj",
            zh: "上传电子签名"
        },
        {
            en: "Add Antibiotics",
            code: "Button-TJKSS",
            French: "jjj",
            zh: "添加抗生素"
        },
        {
            en: "Import Selected Instrument",
            code: "Button-DRXZDYQ",
            French: "jjj",
            zh: "导入选择的仪器"
        },
        {
            en: "Screen",
            code: "Button-SX",
            French: "jjj",
            zh: "筛选"
        },
        {
            en: "Confirm Receipt",
            code: "Button-QRJS",
            French: "jjj",
            zh: "确认接收"
        },
        {
            en: "Refresh",
            code: "Button-ShuaXin",
            French: "jjj",
            zh: "刷新"
        },
        {
            en: "Preservation",
            code: "Button-BC",
            French: "jjj",
            zh: "保存"
        },
        {
            en: "The Return Field Cannot Be Empty",
            code: "FHZDBNWK",
            French: "jjj",
            zh: "返回字段不能为空"
        },
        {
            en: "Can Not Be Empty Name",
            code: "MCBNWK",
            French: "jjj",
            zh: "名称不可以为空"
        },
        {
            en: "Test Category",
            code: "JCLB",
            French: "jjj",
            zh: "检测类别"
        },
        {
            en: "Detection Time",
            code: "JCSJ",
            French: "jjj",
            zh: "检测时间"
        },
        {
            en: "Inspection Time Description Cannot Be Empty",
            code: "JCSJBNWK",
            French: "jjj",
            zh: "检验时间描述不能为空"
        },
        {
            en: "Inspection Category Cannot Be Empty",
            code: "JYLBBNWK",
            French: "jjj",
            zh: "检验类别不能为空"
        },
        {
            en: "Estimated Inspection Time",
            code: "YJJYSJ",
            French: "jjj",
            zh: "预计检验时间"
        },
        {
            en: "Estimated Reporting Time",
            code: "YJBGSJ",
            French: "jjj",
            zh: "预计报告时间"
        },
        {
            en: "Collection Time",
            code: "CJSJ",
            French: "jjj",
            zh: "采集时间"
        },
        {
            en: "Inspection Rules",
            code: "JYGZ",
            French: "jjj",
            zh: "检验规则"
        },
        {
            en: "Inspection Rule Cannot Be Empty",
            code: "JYGZBNWK",
            French: "jjj",
            zh: "检验规则不能为空"
        },
        {
            en: "Collection Time Cannot Be Empty",
            code: "CJSJBNWK",
            French: "jjj",
            zh: "采集时间不能为空"
        },
        {
            en: "Reporting Time",
            code: "BGSJ",
            French: "jjj",
            zh: "报告时间"
        },
        {
            en: "Deadline For Detection",
            code: "JZJCSJD",
            French: "jjj",
            zh: "截止检测时间点"
        },
        {
            en: "Days Between Reports",
            code: "BGJGTS",
            French: "jjj",
            zh: "报告间隔天数"
        },
        {
            en: "Reporting Time Unit",
            code: "BGSJDW",
            French: "jjj",
            zh: "报告时间单位"
        },
        {
            en: "The Deadline Of Detection Cannot Be Empty",
            code: "JZJCSJDBNWK",
            French: "jjj",
            zh: "截止检测时间点不能为空"
        },
        {
            en: "Report Time Cannot Be Empty",
            code: "BGSJBNWK",
            French: "jjj",
            zh: "报告时间不能为空"
        },
        {
            en: "Group Number",
            code: "FZBM",
            French: "jjj",
            zh: "分组编号"
        },
        {
            en: "Group Name",
            code: "FZMC",
            French: "jjj",
            zh: "分组名称"
        },
        {
            en: "Sending Department",
            code: "SJKS",
            French: "jjj",
            zh: "送检科室"
        },
        {
            en: "Specimen Type",
            code: "BBLX",
            French: "jjj",
            zh: "标本类型"
        },
        {
            en: "Container Type",
            code: "RQLX",
            French: "jjj",
            zh: "容器类型"
        },
        {
            en: "Sampling Location",
            code: "CYDD",
            French: "jjj",
            zh: "采样地点"
        },
        {
            en: "Sampling Conditions",
            code: "CYTJ",
            French: "jjj",
            zh: "采样条件"
        },
        {
            en: "Sampling Site",
            code: "CYBW",
            French: "jjj",
            zh: "采样部位"
        },
        {
            en: "Inspection Report Time",
            code: "JYBGSJ",
            French: "jjj",
            zh: "检验报告时间"
        },
        {
            en: "Reporting Location",
            code: "BGDD",
            French: "jjj",
            zh: "报告地点"
        },
        {
            en: "Group Number Cannot Be Empty",
            code: "FZBMBNWK",
            French: "jjj",
            zh: "分组编号不能为空"
        },
        {
            en: "Inspection Report Time Cannot Be Empty",
            code: "JYBGSJBNWK",
            French: "jjj",
            zh: "检验报告时间不能为空"
        },
        {
            en: "Group Name Cannot Be Empty",
            code: "FZMCBNWK",
            French: "jjj",
            zh: "分组名称不能为空"
        },
        {
            en: "New Group",
            code: "Button-XZFZ",
            French: "jjj",
            zh: "新增分组"
        },
        {
            en: "Name Of Antibiotics",
            code: "KSSMC",
            French: "jjj",
            zh: "抗生素名称"
        },
        {
            en: "Instrument Information",
            code: "YQXX",
            French: "jjj",
            zh: "仪器信息"
        },
        {
            en: "Double Trial Or Not",
            code: "SFSS",
            French: "jjj",
            zh: "是否双审"
        },
        {
            en: "Are The First Reviewers / Auditors Allowed To Be The Same",
            code: "SFYXCSZ",
            French: "jjj",
            zh: "是否允许初审者/审核相同"
        },
        {
            en: "Rule Type",
            code: "GZLX",
            French: "jjj",
            zh: "规则类型"
        },
        {
            en: "New Quality Control Plan",
            code: "Button-XZZKJH",
            French: "jjj",
            zh: "新增质控计划"
        },
        {
            en: "Import Automatic Audit Rules",
            code: "Button-DRZDSHGZ",
            French: "jjj",
            zh: "导入自动审核规则"
        },
        {
            en: "Import",
            code: "Button-DR",
            French: "jjj",
            zh: "导入"
        },
        {
            en: "Operation Time",
            code: "CZSJ",
            French: "jjj",
            zh: "操作时间"
        },
        {
            en: "Print All Unselected Data",
            code: "DYSYWXZDSJ",
            French: "jjj",
            zh: "打印所有未选中的数据"
        },
        {
            en: "Print Report",
            code: "Button-DYBG",
            French: "jjj",
            zh: "打印报告"
        },
        {
            en: "Print Settings",
            code: "Button-DYSZ",
            French: "jjj",
            zh: "打印设置"
        },
        {
            en: "Upload Pictures",
            code: "Button-SCTP",
            French: "jjj",
            zh: "上传图片"
        },
        {
            en: "Adding Culture Results",
            code: "Button-TJPYJG",
            French: "jjj",
            zh: "添加培养结果"
        },
        {
            en: "Printing",
            code: "Button-DY",
            French: "jjj",
            zh: "打印"
        },
        {
            en: "Print All The Data",
            code: "DYSSSJ",
            French: "jjj",
            zh: "打印所有的数据"
        },
        {
            en: "Print Selected Data",
            code: "DYXZDSJ",
            French: "jjj",
            zh: "打印选中的数据"
        },
        {
            en: "Inspection And Acceptance",
            code: "Button-JYJS",
            French: "jjj",
            zh: "检验接收"
        },
        {
            en: "Select Platform",
            code: "Button-XZPT",
            French: "jjj",
            zh: "选择平台"
        },
        {
            en: "List Printing",
            code: "Button-QDDY",
            French: "jjj",
            zh: "清单打印"
        },
        {
            en: "Retype Barcode",
            code: "Button-CDTM",
            French: "jjj",
            zh: "重打条码"
        },
        {
            en: "Emergency Treatment",
            code: "KZ",
            French: "jjj",
            zh: "急诊"
        },
        {
            en: "Bar Code",
            code: "TM",
            French: "jjj",
            zh: "条码"
        },
        {
            en: "Specimen Status",
            code: "BBZT",
            French: "jjj",
            zh: "标本状态"
        },
        {
            en: "Receiver",
            code: "JSR",
            French: "jjj",
            zh: "接收人"
        },
        {
            en: "Receiving Time",
            code: "JSSJ",
            French: "jjj",
            zh: "接收时间"
        },
        {
            en: "Sender",
            code: "SCR",
            French: "jjj",
            zh: "送出人"
        },
        {
            en: "Delivery Time",
            code: "SCSJ",
            French: "jjj",
            zh: "送出时间"
        },
        {
            en: "Delivery Batch",
            code: "SCPC",
            French: "jjj",
            zh: "送出批次"
        },
        {
            en: "Sampler",
            code: "CYR",
            French: "jjj",
            zh: "采样人"
        },
        {
            en: "Sampling Time",
            code: "CYSJ",
            French: "jjj",
            zh: "采样时间"
        },
        {
            en: "Sampling Confirmation Batch Number",
            code: "CYPCH",
            French: "jjj",
            zh: "采样确认批次号"
        },
        {
            en: "Printer",
            code: "DYR",
            French: "jjj",
            zh: "打印人"
        },
        {
            en: "Printing Time",
            code: "DYSJ",
            French: "jjj",
            zh: "打印时间"
        },
        {
            en: "Barcode Printing Batch Number",
            code: "TMDYPC",
            French: "jjj",
            zh: "条码打印批次号"
        },
        {
            en: "Clinical Diagnosis",
            code: "LCZD",
            French: "jjj",
            zh: "临床诊断"
        },
        {
            en: "Estimated Testing Time",
            code: "YJJCSJ",
            French: "jjj",
            zh: "预计检测时间"
        },
        {
            en: "Apply For A Doctor",
            code: "SQYS",
            French: "jjj",
            zh: "申请医生"
        },
        {
            en: "Application Project",
            code: "SQXM",
            French: "jjj",
            zh: "申请项目"
        },
        {
            en: "ID Number",
            code: "SFZH",
            French: "jjj",
            zh: "身份证号"
        },
        {
            en: "Age Unit",
            code: "NLDW",
            French: "jjj",
            zh: "年龄单位"
        },
        {
            en: "Age",
            code: "NL",
            French: "jjj",
            zh: "年龄"
        },
        {
            en: "Date Of Birth",
            code: "CSRQ",
            French: "jjj",
            zh: "出生日期"
        },
        {
            en: "Gender",
            code: "XB",
            French: "jjj",
            zh: "性别"
        },
        {
            en: "Bed Number",
            code: "CH",
            French: "jjj",
            zh: "床号"
        },
        {
            en: "Admission Number",
            code: "ZYH",
            French: "jjj",
            zh: "住院号"
        },
        {
            en: "Outpatient Number",
            code: "MZHH",
            French: "jjj",
            zh: "门诊号"
        },
        {
            en: "Medical Card Number",
            code: "JZKH",
            French: "jjj",
            zh: "就诊卡号"
        },
        {
            en: "Application Time",
            code: "SQSJ",
            French: "jjj",
            zh: "申请时间"
        },
        {
            en: "Department Ward",
            code: "KSBQ",
            French: "jjj",
            zh: "科室病区"
        },
        {
            en: "Billing Doctor",
            code: "KDYS",
            French: "jjj",
            zh: "开单医生"
        },
        {
            en: "Collection Time",
            code: "HSSJ",
            French: "jjj",
            zh: "核收时间"
        },
        {
            en: "Inspection Time",
            code: "JYSJ",
            French: "jjj",
            zh: "检验时间"
        },
        {
            en: "New Departments",
            code: "Button-XZBM",
            French: "jjj",
            zh: "新增部门"
        },
        {
            en: "Time Of First Trial",
            code: "CSSJ",
            French: "jjj",
            zh: "初审时间"
        },
        {
            en: "Audit Time",
            code: "SHSJ",
            French: "jjj",
            zh: "审核时间"
        },
        {
            en: "Superior Department",
            code: "SJBNM",
            French: "jjj",
            zh: "上级部门"
        },
        {
            en: "Reviewer",
            code: "SHZ",
            French: "jjj",
            zh: "审核者"
        },
        {
            en: "Printer",
            code: "DYZ",
            French: "jjj",
            zh: "打印者"
        },
        {
            en: "Charge Amount",
            code: "SFJE",
            French: "jjj",
            zh: "收费金额"
        },
        {
            en: "Sample Notes",
            code: "YBBZ",
            French: "jjj",
            zh: "样本备注"
        },
        {
            en: "All News",
            code: "QBXX",
            French: "jjj",
            zh: "全部消息"
        },
        {
            en: "Message Settings",
            code: "XXSZ",
            French: "jjj",
            zh: "消息设置"
        },
        {
            en: "Critical Value Alert",
            code: "WJZTX",
            French: "jjj",
            zh: "危急值提醒"
        },
        {
            en: "Laboratory Business",
            code: "SYSYW",
            French: "jjj",
            zh: "实验室业务"
        },
        {
            en: "In hospital Specimen Inquiry By Batch",
            code: "ZYBBAPCCX",
            French: "jjj",
            zh: "住院标本按批次查询"
        },
        {
            en: "Inspection Workstation",
            code: "JYGZZ",
            French: "jjj",
            zh: "检验工作站"
        },
        {
            en: "Outpatient Bar Code Printing",
            code: "MZTMDY",
            French: "jjj",
            zh: "门诊条码打印"
        },
        {
            en: "Outpatient Specimen List",
            code: "MZBBLB",
            French: "jjj",
            zh: "门诊标本列表"
        },
        {
            en: "Inpatient Bar Code Printing",
            code: "ZYTMDY",
            French: "jjj",
            zh: "住院条码打印"
        },
        {
            en: "List Of Inpatient Specimens",
            code: "ZYBBLB",
            French: "jjj",
            zh: "住院标本列表"
        },
        {
            en: "Specimen Collection",
            code: "BBHS",
            French: "jjj",
            zh: "标本核收"
        },
        {
            en: "Abnormal Specimen",
            code: "DYYCBBR",
            French: "jjj",
            zh: "异常标本"
        },
        {
            en: "Specimen Handover",
            code: "BBJJ",
            French: "jjj",
            zh: "标本交接"
        },
        {
            en: "Report Query Printing",
            code: "BGCXDY",
            French: "jjj",
            zh: "报告查询打印"
        },
        {
            en: "Laboratory Quality Inspection",
            code: "SYSZJ",
            French: "jjj",
            zh: "实验室质检"
        },
        {
            en: "Rule Classification",
            code: "GZFL",
            French: "jjj",
            zh: "规则分类"
        },
        {
            en: "Quality Control Rules",
            code: "ZKGZ",
            French: "jjj",
            zh: "质控规则"
        },
        {
            en: "Rule Combination",
            code: "GZZH",
            French: "jjj",
            zh: "规则组合"
        },
        {
            en: "Quality Control Plan",
            code: "ZKJH",
            French: "jjj",
            zh: "质控计划"
        },
        {
            en: "Quality Control Results",
            code: "ZKJG",
            French: "jjj",
            zh: "质控结果"
        },
        {
            en: "Laboratory Statistics",
            code: "SYSTJ",
            French: "jjj",
            zh: "实验室统计"
        },
        {
            en: "Statistics Of Sample Size Submitted By Departments",
            code: "KSSJYBLTJ",
            French: "jjj",
            zh: "科室送检样本量统计"
        },
        {
            en: "Statistics Of Working Sample Size Of Examiners",
            code: "JYZGZLBTJ",
            French: "jjj",
            zh: "检验者工作样本量统计"
        },
        {
            en: "Number Of Unqualified Samples",
            code: "BHGBTS",
            French: "jjj",
            zh: "不合格标本数"
        },
        {
            en: "Statistics Of Applied Projects",
            code: "SQXMLTJ",
            French: "jjj",
            zh: "申请项目量统计"
        },
        {
            en: "Statistics Of Inspection Items",
            code: "JYXMLTJ",
            French: "jjj",
            zh: "检验项目量统计"
        },
        {
            en: "Statistics Of Working Sample Size Of Inspection Platform",
            code: "JYPTGZYBLTJ",
            French: "jjj",
            zh: "检验平台工作样本量统计"
        },
        {
            en: "Statistics Of Application Items Submitted By Departments",
            code: "KSSJSQXMLTJ",
            French: "jjj",
            zh: "科室送检申请项目量统计"
        },
        {
            en: "Statistics Of Inspection Items Submitted By Departments",
            code: "KSSJJYXMLTJ",
            French: "jjj",
            zh: "科室送检检验项目量统计"
        },
        {
            en: "Auditor Work Sample Size Statistics",
            code: "SHZGZYBLTJ",
            French: "jjj",
            zh: "审核者工作样本量统计"
        },
        {
            en: "Tat Statistics",
            code: "TATTJ",
            French: "jjj",
            zh: "TAT统计"
        },
        {
            en: "Test Hospital Department",
            code: "CCYYBM",
            French: "jjj",
            zh: "测试医院部门"
        },
        {
            en: "Xihuyuan District, Zhejiang Province",
            code: "ZJXHYQ",
            French: "jjj",
            zh: "浙江西湖院区"
        },
        {
            en: "Ophthalmology Clinic 2",
            code: "YKMZ1",
            French: "jjj",
            zh: "眼科门诊2"
        },
        {
            en: "Change Password",
            code: "Button-XGMM",
            French: "jjj",
            zh: "修改密码"
        },
        {
            en: "Modify",
            code: "Button-XG",
            French: "jjj",
            zh: "修改"
        },
        {
            en: "Log Out",
            code: "TCDL",
            French: "jjj",
            zh: "退出登录"
        },
        {
            en: "Sign Out",
            code: "Button-TC",
            French: "jjj",
            zh: "退出"
        },
        {
            en: "Cloud LIS",
            code: "YLS",
            French: "jjj",
            zh: "云LIS"
        },
        {
            en: "New Password",
            code: "XMM",
            French: "jjj",
            zh: "新密码"
        },
        {
            en: "Contact Information",
            code: "LXFS",
            French: "jjj",
            zh: "联系方式"
        },
        {
            en: "Confirm Password",
            code: "QRMM",
            French: "jjj",
            zh: "确认密码"
        },
        {
            en: "Query Sample",
            code: "Button-CXYB",
            French: "jjj",
            zh: "查询样本"
        },
        {
            en: "Generate New Results",
            code: "Button-SCXJG",
            French: "jjj",
            zh: "生成新结果"
        },
        {
            en: "Update Original Results",
            code: "Button-GXYJG",
            French: "jjj",
            zh: "更新原结果"
        },
        {
            en: "Confirm",
            code: "Button-QR",
            French: "jjj",
            zh: "确认"
        },
        {
            en: "Name Of Organization",
            code: "JGMC",
            French: "jjj",
            zh: "机构名称"
        },
        {
            en: "Organization Code",
            code: "JGBM",
            French: "jjj",
            zh: "机构编码"
        },
        {
            en: "Normal",
            code: "ZC",
            French: "jjj",
            zh: "正常"
        },
        {
            en: "Title",
            code: "BT",
            French: "jjj",
            zh: "标题"
        },
        {
            en: "Request Address",
            code: "QQDZ",
            French: "jjj",
            zh: "请求地址"
        },
        {
            en: "Request Status",
            code: "QQZT",
            French: "jjj",
            zh: "请求状态"
        },
        {
            en: "Operation Account",
            code: "CZZH",
            French: "jjj",
            zh: "操作账号"
        },
        {
            en: "Business Type",
            code: "YWLX",
            French: "jjj",
            zh: "业务类型"
        },
        {
            en: "Menu Application Type",
            code: "CDYYLX",
            French: "jjj",
            zh: "菜单应用类型"
        },
        {
            en: "Remove User ",
            code: "Button-YCYH",
            French: "jjj",
            zh: "移除用户"
        },
        {
            en: "Invalid Barcode",
            code: "Button-ZFTM",
            French: "jjj",
            zh: "作废条码"
        },
        {
            en: "Operation Return",
            code: "Button-CZTH",
            French: "jjj",
            zh: "操作退回"
        },
        {
            en: "Inspection Refund",
            code: "Button-JYTF",
            French: "jjj",
            zh: "检验退费"
        },
        {
            en: "Partial Refund",
            code: "Button-BFTF",
            French: "jjj",
            zh: "部分退费"
        },
        {
            en: "Batch Check Selected",
            code: "Button-PLHDYX",
            French: "jjj",
            zh: "批量核对已选"
        },
        {
            en: "The Specimen Was Sent Out",
            code: "Button-BBSC",
            French: "jjj",
            zh: "标本送出"
        },
        {
            en: "Batch Confirm Selected",
            code: "Button-PLQRYX",
            French: "jjj",
            zh: "批量确认已选"
        },
        {
            en: "Sampling Confirmation",
            code: "Button-CYQR",
            French: "jjj",
            zh: "采样确认"
        },
        {
            en: "Print List",
            code: "Button-DYQD",
            French: "jjj",
            zh: "打印清单"
        },
        {
            en: "New Quality Control Results",
            code: "Button-XZZKJG",
            French: "jjj",
            zh: "新增质控结果"
        },
        {
            en: "Handover Confirmation",
            code: "Button-JJQR",
            French: "jjj",
            zh: "交接确认"
        },
        {
            en: "Counterclaim",
            code: "Button-FS",
            French: "jjj",
            zh: "反审"
        },
        {
            en: "Click To Upload E-Signature",
            code: "Button-DJSCDZQM",
            French: "jjj",
            zh: "点击上传电子签名"
        },
        {
            en: "Test",
            code: "Button-CS",
            French: "jjj",
            zh: "测试"
        },
    { 
        code:'TITLE-YCBB',
        zh: '实验室信息管理系统',
        en: 'Laboratory Information Management Systems',
        French: 'jjj'
    },
// 2020/12/22  翻译更新截止代码
{ 
    code:'TITLE-QRHS',
    zh: '确认核收',
    en: 'Confirm acceptance check',
    French: 'jjj'
},
{
    en: "trial",
    code: "Button-CSZ",
    French: "jjj",
    zh: "初审"
},
{
    en: "New Configuration",
    code: "Button-XZPZ",
    French: "jjj",
    zh: "新增配置"
},
{
    en: "Get Interface Project",
    code: "Button-HQJKXM",
    French: "jjj",
    zh: "获取接口项目"
},
{
    en: "Import Selected Items",
    code: "Button-DRXZDXM",
    French: "jjj",
    zh: "导入选择的项目"
},
{
    en: "Add Item",
    code: "Button-TJXM",
    French: "jjj",
    zh: "添加项目"
},
];

export default {
    languageType
}