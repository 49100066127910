import axios from 'axios'
import { Message } from '@alifd/next';
import { routerRedux } from 'dva/router';
import store from '../index';
import { Loading, Button } from '@alifd/next';





// 设置请求公共参数，baseURL，请求头，token
axios.default.timeout = 1000;
// 设置post请求header头部
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';



let config = {
    // baseURL: 'https://api.elab-admin-test.ulabcare.com/',
    baseURL: 'https://api.supply.tuanpos.com/',
    // baseURL:'http://192.168.3.16:20101/',
    timeout: 30 * 1000,
};


const service = axios.create(config);
let LoadingD = document.createElement('div');
let div1 = document.createElement('div');
let span1 = document.createElement('span');
let span2 = document.createElement('span');
let span3 = document.createElement('span');
let span4 = document.createElement('span');
div1.appendChild(span1);
div1.appendChild(span2);
div1.appendChild(span3);
div1.appendChild(span4);
LoadingD.appendChild(div1);

LoadingD.setAttribute('class','loading')
div1.setAttribute('class','loadingBox')
document.getElementsByTagName("body")[0].appendChild(LoadingD);
LoadingD.setAttribute('style','display:none;')

service.interceptors.request.use(

  function (config) {
      LoadingD.setAttribute('style','display:block;')
      config.headers.common['token'] = localStorage.getItem("token") || '';
      config.headers.common['MenuId'] = localStorage.getItem("MenuId") || '';
      config.headers.common['language'] = localStorage.getItem("language")?localStorage.getItem("language")=="English"?"en":localStorage.getItem("language")=="Chinese"?"zh":localStorage.getItem("language"):"";
      config.headers.common['menuAppType'] = 1;
      localStorage.setItem("baserURL",config.baseURL)
      return config;
  },
  function (error) {
      LoadingD.setAttribute('style','display:none;')

      Message.error('请求异常')
      return Promise.reject(error);
  }
);

// 设置返回拦截器
service.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            LoadingD.setAttribute('style','display:none;')

            response.ContentType = "application/json";
            return Promise.resolve(response);
        } else {
         
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        setTimeout(function(){
            LoadingD.setAttribute('style','display:none;')
        },5000)
        if (error.response.status) {
            const { dispatch } = store;
            // console.log(error.response.status)
            switch (error.response.status) {
                // // 401: 未登录
                // // 未登录则跳转登录页面，并携带当前页面的路径
                // // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    clearAll();
                    dispatch(routerRedux.push('/'));
                    Message.error('未登录');
                    break;

                // // 403 token过期
                // // 登录过期对用户进行提示
                // // 清除本地token和清空vuex中token对象
                // // 跳转登录页面
                case 403:
                    Message.error('登录过期，请重新登录');
                    // 清除token
                    clearAll();
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    dispatch(routerRedux.push('/'));
                    break;

                // // 404请求不存在
                case 404:
                    Message.error('404请求不存在');
                    break;
                // case 500:
                    // Message.error("500接口,请联系管理员！");
                    // window.location.reload()
                    // break;
                // case 503:
                    // Message.error("503接口,请联系管理员！");
                    // window.location.reload()
                    // break;    
                // // 其他错误，直接抛出错误提示
                default:
                    Message.error(error.response.data.message);
            }
          
            return Promise.reject(error);
        }
    }
);

function clearAll() {
    localStorage.removeItem('token');
    localStorage.removeItem('menu_style');
    localStorage.removeItem('MenuId');
    localStorage.removeItem('baserURL');
    localStorage.removeItem('userMessage');
    localStorage.removeItem('username');
    localStorage.removeItem('btn');
    localStorage.removeItem('menuName');
}

// 封装一个get请求
export function get(url, params = {}){
    // 开启请求加载
    return new Promise((resolve, reject) =>{
        service.get(url, {
            params: params
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            // reject(err.data)
            console.log(err)
        })
    })
}

// 封装一个post请求
export default function post(url, params) {
    service.defaults.headers.authorization= `bearer ${localStorage.getItem("token")}`;
    service.defaults.headers.access_token= `${localStorage.getItem("token")}`;
    return new Promise((resolve, reject) => {
        service.post(url, params)
        .then(res => {
            if(res.data.code!==2000){
                const { dispatch } = store;
                Message.error(res.data.message)
                resolve(res.data.body);
                if(res.data.code===4002){
                    dispatch(routerRedux.push('/'));
                }
            }else{
                if(res.data.body){
                    resolve(res.data.body);
                }else{
                    resolve(res.data);
                }
            }
        })
        .catch(err =>{
            // reject(err.data);
            console.log(err)
        })
    });
}

